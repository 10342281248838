import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import { Link } from "react-router-dom"
import arrow from "../images/left-arrow.png"
import { motion as m } from "framer-motion"
import useFetchDocument from "../Componentslogic/Usefetchdocument"
import { GetonepickuporderHook } from "../Componentslogic/ordersLogic/getonepickuporder"

export const OneDeliveryorderdetails = () => {
  const { id } = useParams()
  const { document } = useFetchDocument("DeliveryOrders", id)
  const [handleReadyforPickupdetails, , handleDelete, , disable, paid] =
    GetonepickuporderHook(id, document)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const user = JSON.parse(localStorage.getItem("user"))
  const getAdjustedLink = () => {
    if (user.email === process.env.REACT_APP_ADMIN_EMAIL) {
      return "/adminallorders"
    } else {
      return "/Staffpage/DineIn"
    }
  }

  return (
    <m.div
      className="p-4 all-details"
      initial={{ opacity: 0, x: 15 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Confirm Delete </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete Order of{" "}
          <strong>{document?.userName}</strong> on table :{" "}
          <strong>{document?.orderNumber}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={(e) => handleDelete(e, document.id)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* // 1-Show order details */}

      <Link to={getAdjustedLink()}>
        <img
          style={{ height: "25px", width: "25px" }}
          src={arrow}
          alt="Back-arrow"
        ></img>
      </Link>

      <div>
        <m.h2
          className="TNumber  d-flex justify-content-end"
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          {document?.orderNumber || "No Table"}
        </m.h2>
        <m.h2
          className="Review-total mb-5"
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          Order details
        </m.h2>
      </div>

      <div className="Product-Card justify-content-center mb-3">
        {document ? (
          document.cartItems.map((i, index) => {
            return (
              <Card
                bg=""
                text="dark"
                style={{ width: "18rem" }}
                className="mb-2 mx-2 "
                key={index}
              >
                <Card.Header
                  style={{
                    backgroundColor: i.updatedItem ? "#2f5a76" : "",
                    color: i.updatedItem ? "white" : "black",
                  }}
                >
                  Category: {i.CategoryName || "Unknown"}
                </Card.Header>

                <Card.Body>
                  <Card.Title>Title: {i.name} </Card.Title>
                  <Card.Text>quantity: {i.CartTotalQuantity}</Card.Text>
                  <Card.Text>
                    price: {i.Price} x {i.CartTotalQuantity}
                  </Card.Text>
                </Card.Body>
              </Card>
            )
          })
        ) : (
          <h2>no data</h2>
        )}
      </div>
      {document?.Notes && (
        <div className="Notes-Card mb-3">
          <p>
            <strong>Notes:</strong>
          </p>
          <p>{document.Notes}</p>
        </div>
      )}
      <div className="d-flex justify-content-center">
        <button className=" btns-red" onClick={handleShow}>
          Delete Order
        </button>
      </div>
      <div className="horizontal-divider"></div>
      {/* // 2- Display User Info */}
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              plaintext
              readOnly
              defaultValue={document?.userName}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Email
          </Form.Label>
          <Col sm="10">
            <Form.Control
              plaintext
              readOnly
              defaultValue={document?.userEmail}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Total price:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              plaintext
              readOnly
              defaultValue={document?.orderAmount}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Ordered At:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              plaintext
              readOnly
              value={`${document?.orderDate || "Unknown"} at ${
                document?.orderTime || "Unknown"
              }`}
            />
          </Col>
        </Form.Group>
        {/* //Updated */}
        {document?.SeenAtDate ? (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Started Preparing At:
            </Form.Label>
            <Col sm="10">
              <Form.Control
                plaintext
                readOnly
                value={`${document?.SeenAtDate || ""} at ${
                  document?.SeenAtTime || ""
                }`}
              />
            </Col>
          </Form.Group>
        ) : null}
        {/* //Updated */}

        {document?.FinishedPreparingAt && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Done Preparing At:
            </Form.Label>
            <Col sm="10">
              <Form.Control
                plaintext
                readOnly
                value={new Date(
                  document.FinishedPreparingAt.seconds * 1000
                ).toLocaleString()}
              />
            </Col>
          </Form.Group>
        )}

        {document?.PickedBy ? (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Picked-By:
            </Form.Label>
            <Col sm="10">
              <Form.Control plaintext readOnly value={document.PickedBy} />
            </Col>
          </Form.Group>
        ) : null}

        {document?.pickedAt && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Picked At:{" "}
            </Form.Label>
            <Col sm="10">
              <Form.Control
                plaintext
                readOnly
                value={new Date(
                  document.pickedAt.seconds * 1000
                ).toLocaleString()}
              />
            </Col>
          </Form.Group>
        )}

        {document?.Deliveredby ? (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Delivered-by:
            </Form.Label>
            <Col sm="10">
              <Form.Control plaintext readOnly value={document.Deliveredby} />
            </Col>
          </Form.Group>
        ) : null}

        {document?.deliveredAt && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Delivered At:
            </Form.Label>
            <Col sm="10">
              <Form.Control
                plaintext
                readOnly
                value={new Date(
                  document.deliveredAt.seconds * 1000
                ).toLocaleString()}
              />
            </Col>
          </Form.Group>
        )}
      </Form>
    </m.div>
  )
}
