import React from "react"
import Adminreviewsconatiner from "../../Components/Admin/Adminreviewcontainer"
import { Container, Col } from "react-bootstrap"
import "../../Styles/Adminreviews.css"
import { SidemenuComponent } from "../../Components/Admin/Sidemenu"
const Adminreviewspage = () => {
  return (
    <Container fluid>
      <Col sm="2" xs="2" className="px-0">
        <SidemenuComponent />
      </Col>
      <Col sm="10" xs="10" className="px-0">
        <Adminreviewsconatiner />
      </Col>
    </Container>
  )
}
export default Adminreviewspage
