import Spinner from "react-bootstrap/Spinner"
import FilterSearchHook from "../Componentslogic/productsLogic/filter-searchHook"
import "../../Styles/Menustyle.css"
import { motion as m, AnimatePresence } from "framer-motion"
import UsefetchCollectionNonRealtime from "../Componentslogic/UsefetchcollectionNonrealtime"
import usefetchdocument from "../Componentslogic/Usefetchdocument"
import DOMPurify from "dompurify"
import {
  FILTER_BY_CATEGORY,
  FILTER_BY_SEARCH,
  hideNotification,
  Selectnotificationnote,
  SelectProductselected,
  SelectRecommendedProducts,
  SET_RECOMMENDED_PRODUCTS,
} from "../../redux/actions/FilterProducts"
import { AddproductsHook } from "../Componentslogic/cartLogic/AddpoductsHook"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"

import {
  Timestamp,
  updateDoc,
  doc,
  increment,
  getDoc,
  setDoc,
} from "firebase/firestore"
import { db } from "../../Firebase/config"

const Menucontainer = () => {
  const [handleaddtoCart, , NotesUpdated, handlechangeNotesupdated] =
    AddproductsHook()
  const dispatch = useDispatch()
  const showNotificationNote = useSelector(Selectnotificationnote)
  const selectedProduct = useSelector(SelectProductselected)
  const user = JSON.parse(localStorage.getItem("user"))
  const { document: Notification } = usefetchdocument(
    "Notification",
    "4Az1NF5LIxQWdsCwmfXb"
  )

  const NotificationSanitize = Notification
    ? Notification.Notification !== ""
      ? DOMPurify.sanitize(Notification.Notification)
      : ""
    : ""
  const {
    data: products,
    // eslint-disable-next-line
    loading: ProductsLoading,
    // eslint-disable-next-line
    error: ProductsError,
  } = UsefetchCollectionNonRealtime("Products")
  const {
    data: productsReccomend,
    // eslint-disable-next-line
    loading: productsReccomendlOADING,
    // eslint-disable-next-line
    error: productsReccomendlOADINGError,
  } = UsefetchCollectionNonRealtime("Recommendations")

  const {
    data: categories,
    loading: CategoriesLoading,
    // eslint-disable-next-line
    error: errorCategories,
  } = UsefetchCollectionNonRealtime("Categories")

  const [showNotification, setShowNotification] = useState(false)

  useEffect(() => {
    if (productsReccomend) {
      dispatch(SET_RECOMMENDED_PRODUCTS(productsReccomend))
    }
  }, [dispatch, productsReccomend])

  const ProductReccomends = useSelector(SelectRecommendedProducts)

  useEffect(() => {
    const updateRecommendation = () => {
      if (
        localStorage.getItem("orderID") === null &&
        ProductReccomends &&
        ProductReccomends.length > 0
      ) {
        setShowNotification(true)

        setTimeout(() => {
          setShowNotification(false)
        }, 6000)
      }
    }
    updateRecommendation()
  }, [ProductReccomends])

  const [searchword, handlechange, setSelectedCategory, selectedCategory] =
    FilterSearchHook()

  useEffect(() => {
    if (searchword !== "") {
      dispatch(FILTER_BY_SEARCH({ searchword, products }))
    }
  }, [dispatch, searchword])

  const filterByCategory = () => {
    if (products.length > 0 && categories.length > 0) {
      dispatch(
        FILTER_BY_CATEGORY({
          categories: categories[0],
          products,
        })
      )
      setSelectedCategory(categories[0]?.id)
    }
  }

  useEffect(() => {
    if (!CategoriesLoading) {
      filterByCategory()
    }
    // eslint-disable-next-line
  }, [CategoriesLoading, products, categories])

  const TotalInteractions = async () => {
    if (user) {
      await updateDoc(doc(db, "users", user.userID), {
        numberofinteractions: increment(1),
      })
    } else return
  }
  const handleCounterOfNewUsers = async () => {
    const counterRef = doc(db, "Counter", "Interactions")

    try {
      const docSnap = await getDoc(counterRef)
      if (!docSnap.exists()) {
        await setDoc(counterRef, {
          numberOfUserInteractions: 0,
          numberOfNonUserInteractions: 0,
          createdAt: Timestamp.now().toDate(),
        })
      }
      if (user) {
        await updateDoc(counterRef, {
          numberOfUserInteractions: increment(1),
        })
      } else {
        // Increment non-user interactions by 1
        await updateDoc(counterRef, {
          numberOfNonUserInteractions: increment(1),
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  // const handleCounterOfNewUsers = async () => {
  //   const counterRef = doc(db, "Counter", "Interactions")

  //   try {
  //     const docSnap = await getDoc(counterRef)
  //     if (!docSnap.exists()) {
  //       await setDoc(counterRef, {
  //         numberOfUserInteractions: 0,
  //         numberOfNonUserInteractions: 0,
  //         interactions: [],
  //       })
  //     }

  //     // Check if the user is authenticated
  //     const newInteraction = {
  //       type: user ? "user" : "non-user", // Track interaction type
  //       timestamp: Timestamp.now(), // Store interaction timestamp
  //     }

  //     // Update counters and store the interaction timestamp
  //     if (user) {
  //       await updateDoc(counterRef, {
  //         numberOfUserInteractions: increment(1),
  //         interactions: arrayUnion(newInteraction), // Add to interactions array
  //       })
  //     } else {
  //       await updateDoc(counterRef, {
  //         numberOfNonUserInteractions: increment(1),
  //         interactions: arrayUnion(newInteraction), // Add to interactions array
  //       })
  //     }

  //     console.log("Interaction count updated successfully.")
  //   } catch (error) {
  //     console.error("Failed to update the counter: ", error)
  //   }
  // }

  return (
    <div>
      <AnimatePresence>
        {showNotification && productsReccomend.length > 0 && (
          <m.div
            className="top-notification"
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            exit={{ y: -200 }}
            transition={{ duration: 0.5 }}
          >
            <div className="notification-content">
              <div className="text notification-wrapper ">
                <h4>New!</h4>
              </div>
              <div className=" desc-title">
                {Notification && Notification.Notification !== "" ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: NotificationSanitize,
                    }}
                  />
                ) : (
                  <p>
                    Explore the <b>Featured </b> category for our latest dishes!{" "}
                  </p>
                )}
              </div>
            </div>
          </m.div>
        )}
      </AnimatePresence>

      {/* Notes */}
      <AnimatePresence>
        {localStorage.getItem("orderID") !== null && showNotificationNote && (
          <m.div
            className="top-notification"
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            exit={{ y: -200 }}
            transition={{ duration: 0.5 }}
          >
            <div className="notification-content">
              <div className="text notification-wrapper ">
                <h4>Confirm Adding {selectedProduct.name}</h4>
              </div>
              <div className=" desc-title">
                <input
                  className="searchInput mb-3"
                  style={{ backgroundColor: "#FAF9F6", width: "100%" }}
                  value={NotesUpdated}
                  onChange={handlechangeNotesupdated}
                  type="text"
                  placeholder="Add note?"
                ></input>
              </div>
              {/* //updated */}
              <div className="d-flex align-items-center " style={{ gap: "2%" }}>
                <button
                  className="btns-Confirm-reco cart-button  "
                  onClick={() => {
                    handleaddtoCart(selectedProduct)
                    dispatch(hideNotification())
                  }}
                >
                  {" "}
                  Confirm{" "}
                </button>
                <button
                  className="btns-red-reco  "
                  onClick={() => dispatch(hideNotification())}
                >
                  {" "}
                  Cancel{" "}
                </button>
              </div>
            </div>
          </m.div>
        )}
      </AnimatePresence>

      <div className="searchBox mb-3 mt-2">
        <input
          className="searchInput-menu"
          value={searchword}
          onChange={handlechange}
          type="text"
          placeholder="Search...."
        ></input>
      </div>

      <m.div className="bar-items mb-4">
        {productsReccomend.length > 0 && (
          <m.div
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                FILTER_BY_CATEGORY({
                  categories: "",
                  products: productsReccomend,
                })
              )
              setSelectedCategory("Filtered")
              TotalInteractions()
              handleCounterOfNewUsers()
            }}
            className={` ${
              selectedCategory === "Filtered" ? "selected" : "New-text "
            }`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div>
              <m.p className=" justify-content-center pt-2">Featured</m.p>
            </div>
          </m.div>
        )}
        {categories ? (
          categories.map((i, index) => {
            return (
              <div key={index} className=" mx-2 ">
                <m.div
                  onClick={() => {
                    dispatch(
                      FILTER_BY_CATEGORY({
                        categories: i || categories[0],
                        products,
                      })
                    )
                    setSelectedCategory(i.id)
                  }}
                  className={` ${
                    selectedCategory === i.id ? "selected" : "New-text "
                  }`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.3 }}
                >
                  <div>
                    <div className="image-container">
                      <img
                        className="all-img"
                        loading="lazy"
                        src={i.image}
                        alt="cats"
                      ></img>
                    </div>

                    <m.p className=" justify-content-center pt-2">{i.name}</m.p>
                  </div>
                </m.div>
              </div>
            )
          })
        ) : (
          <Spinner animation="grow" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </m.div>
    </div>
  )
}

export default Menucontainer
