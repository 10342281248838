import React from "react"
import { Col, Row } from "react-bootstrap"
import { AddproductsHook } from "../Componentslogic/cartLogic/AddpoductsHook"
import { useDispatch } from "react-redux"
import { showNotification } from "../../redux/actions/FilterProducts"
export const Menucard = ({ products }) => {
  const [handleaddtoCart, pressed, ,] = AddproductsHook()
  const dispatch = useDispatch()

  return (
    <Row className=" Cards justify-content-between ">
      <Col sm="10" xs="12" className="mx-3">
        <h3 className=" title-Card">{products.name}</h3>

        <p className=" body-font">{products.description}</p>
      </Col>

      <Col
        sm="2"
        xs="11"
        className=" mx-3"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.9 }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mb-3 ">Price:{products.Price} EGP</h4>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          {localStorage.getItem("orderID") ? (
            pressed ? (
              <div className="cart-button btns-addtoorder">
                <div className="loader">
                  <li className="ball"></li>
                  <li className="ball"></li>
                  <li className="ball"></li>
                </div>
              </div>
            ) : (
              <button
                onClick={() => dispatch(showNotification(products))}
                className="cart-button btns-addtoorder"
              >
                <p style={{ margin: 0 }}>Add to Order</p>
              </button>
            )
          ) : pressed ? (
            <div className="cart-button btns-addtoorder">
              <div className="loader">
                <li className="ball"></li>
                <li className="ball"></li>
                <li className="ball"></li>
              </div>
            </div>
          ) : (
            <button
              onClick={() => handleaddtoCart(products)}
              className="cart-button btns-addtoorder"
            >
              <p style={{ margin: 0 }}>Add to cart</p>
            </button>
          )}
        </div>
      </Col>
    </Row>
  )
}
