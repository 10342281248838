import React from "react"
import star from "../images/star.png"
const StarRating = ({ rating }) => {
  const stars = []

  // Loop to create an array of JSX elements representing stars
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(
        <img className="star-img" key={i} src={star} alt="filled star" />
      )
    }
  }

  return <div className=" stars mx-3 ">{stars}</div>
}

export default StarRating
