import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Notify from "../Usenotifications"
import axios from "axios"
import {
  addDoc,
  collection,
  Timestamp,
  updateDoc,
  doc,
  getDocs,
  increment,
} from "firebase/firestore"
import { db } from "../../../Firebase/config"
import sound from "../../images/Sound.mp3"
import { v4 as uuidv4 } from "uuid"
import {
  PLACE_ORDER,
  selectCartAmountDiscounted,
  selectCartTotalAmount,
  CLEAR_CART,
} from "../../../redux/actions/CartSlice"
import { selectCartitems } from "../../../redux/actions/CartSlice"
import usefetchdocument from "../Usefetchdocument"
export const CreateorderHook = () => {
  const [tablenumber, setTablenumber] = useState(null)
  const [PeopleNumber, setPeopleNumber] = useState(0)
  const [phoneNumber, setPhoneNumber] = useState(0)
  const [Notes, setNotes] = useState("")
  const [pressedPlace, setPressedplace] = useState(false)
  const [closed, setclosed] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const audio = new Audio(sound)
  const user = JSON.parse(localStorage.getItem("user"))
  const cartTotalAmount = useSelector(selectCartTotalAmount)
  const cartdiscountedAmount = useSelector(selectCartAmountDiscounted)
  const cartItems = useSelector(selectCartitems)
  const [reservationTime, setReservationTime] = useState("")
  const [selectedDate, setSelectedDate] = useState(null)
  //Delivery

  const [selectedAddress, setSelectedAddress] = useState(null)
  const [buildingName, setBuildingName] = useState("")
  const [aptNo, setAptNo] = useState("")
  const [floor, setFloor] = useState("")
  const [street, setStreet] = useState("")
  const [directions, setDirections] = useState("")
  const [DeliveryphoneNumber, setDeliveryPhoneNumber] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const handleSelectAddress = (address) => {
    setSelectedAddress(address)
    const addressParts = address
      ? address.split(", ").map((part) => part.trim())
      : []

    // Use conditional checks to avoid errors
    setBuildingName(
      addressParts[0] ? addressParts[0].split(":")[1]?.trim() || "" : ""
    )
    setFloor(addressParts[1] ? addressParts[1].split(":")[1]?.trim() || "" : "")
    setAptNo(addressParts[2] ? addressParts[2].split(":")[1]?.trim() || "" : "")
    setStreet(
      addressParts[3] ? addressParts[3].split(":")[1]?.trim() || "" : ""
    )
    setDirections(
      addressParts[4] ? addressParts[4].split(":")[1]?.trim() || "" : ""
    )
    setDeliveryPhoneNumber(
      addressParts[5] ? addressParts[5].split(":")[1]?.trim() || "" : ""
    )
  }

  const handleTimeChange = (selectedOption) => {
    setReservationTime(selectedOption)
  }

  const handleDateChange = (selectedOption) => {
    setSelectedDate(selectedOption.value)
  }

  const { document: time } = usefetchdocument(
    "restaurantTimes",
    "umJzZMf1P9pxdQdNs871"
  )
  const [openingTime, setOpeningTime] = useState(
    localStorage.getItem("openingTime") || null
  )
  const [openHours, setOpenHours] = useState(
    localStorage.getItem("openHours") || null
  )

  useEffect(() => {
    if (time) {
      if (time.openingTime !== openingTime) {
        localStorage.setItem("openingTime", time.openingTime)
        setOpeningTime(time.openingTime) // Update state
      }
      if (time.openHours !== openHours) {
        localStorage.setItem("closingTime", time.openHours)
        setOpenHours(time.openHours)
      }
    }
  }, [time, openingTime, openHours])
  useEffect(() => {
    if (openingTime && openHours) {
      const checkTime = () => {
        const now = new Date()

        const [openingHour, openingMinutes] = openingTime.split(":").map(Number)
        const openingDate = new Date() // Today’s date at opening time
        openingDate.setHours(openingHour, openingMinutes, 0, 0)

        const closingDate = new Date(openingDate) // Closing time based on openHours
        closingDate.setHours(openingDate.getHours() + Number(openHours))

        const nowTime = now.getTime()
        const openingTimeInMs = openingDate.getTime()
        const closingTimeInMs = closingDate.getTime()

        if (nowTime >= openingTimeInMs && nowTime <= closingTimeInMs) {
          setclosed(false) // Restaurant is open
        } else {
          setclosed(true) // Restaurant is closed
        }
      }

      // Initial check when the component mounts
      checkTime()

      // Set up an interval to check every minute (or every desired interval)
      const interval = setInterval(() => {
        checkTime()
      }, 60000) // Check every minute

      return () => clearInterval(interval) // Cleanup interval on unmount
    }
  }, [openingTime, openHours])

  const saveReservationOrder = async () => {
    const today = new Date()
    const date = today.toDateString()
    const time = today.toLocaleTimeString()
    if (user !== null) {
      try {
        const docRef = await addDoc(collection(db, "Reservations"), {
          userID: user.userID,
          userName: user.name,
          userEmail: user.email,
          orderDate: date,
          orderTime: time,
          PhoneNumber: phoneNumber,
          PeopleNumber: PeopleNumber,
          Checked: false,
          createdAt: Timestamp.now().toDate(),
          Time: reservationTime,
          Date: selectedDate,
          isReserved: true,
        })
        Notify("Reservation Confirmed", "success")
        navigate("/PDR")
        audio.volume = 0.7
        audio.play()
      } catch (error) {
        console.log(error)
      }
    } else {
      Notify("Please enter required fields", "error")
    }
  }

  const saveDineinOrder = async () => {
    const today = new Date()
    const date = today.toDateString()
    const time = today.toLocaleTimeString()
    if (user !== null) {
      try {
        const docRef = await addDoc(collection(db, "Orders"), {
          userID: user.userID,
          userName: user.name,
          userEmail: user.email,
          orderDate: date,
          orderTime: time,
          orderAmount: cartdiscountedAmount
            ? cartdiscountedAmount
            : cartTotalAmount,
          orderStatus: "Order Placed...",
          cartItems,
          tablenumber: tablenumber.value,
          CheckRequest: false,
          Promodiscount: localStorage.getItem("promodiscount") || 0,
          Paid: false,
          Notes,
          Checked: false,
          createdAt: Timestamp.now().toDate(),
        })
        const orderID = docRef.id
        Notify("Order Placed", "success")
        navigate("/")
        localStorage.setItem("orderID", orderID)
        audio.volume = 0.7
        audio.play()
      } catch (error) {
        console.log(error)
      }
    } else {
      Notify("Please enter Name and Email", "error")
    }
  }

  //Comments: delivery-Orders
  const saveDeliveryOrder = async () => {
    const uniqueOrderId = uuidv4().slice(-4)
    const today = new Date()
    const date = today.toDateString()
    const time = today.toLocaleTimeString()
    if (user !== null) {
      try {
        const addresses = {
          buildingName,
          floor,
          aptNo,
          street,
          directions,
          DeliveryphoneNumber,
        }

        const querySnapshot = await getDocs(collection(db, "UserAddresses"))
        let isDuplicate = false

        querySnapshot.forEach((doc) => {
          const savedAddress = doc.data()
          if (
            savedAddress.userID === user.userID &&
            savedAddress.buildingName === buildingName &&
            savedAddress.floor === floor &&
            savedAddress.aptNo === aptNo &&
            savedAddress.street === street &&
            savedAddress.directions === directions
          ) {
            isDuplicate = true
          }
        })

        if (!isDuplicate) {
          await addDoc(collection(db, "UserAddresses"), {
            userID: user.userID,
            buildingName,
            floor,
            aptNo,
            street,
            directions,
            DeliveryphoneNumber,
            createdAt: Timestamp.now().toDate(),
          })
        }
        const docRef = await addDoc(collection(db, "DeliveryOrders"), {
          orderNumber: uniqueOrderId,
          userID: user.userID,
          userName: user.name,
          userEmail: user.email,
          orderDate: date,
          orderTime: time,
          orderAmount: cartdiscountedAmount
            ? cartdiscountedAmount
            : cartTotalAmount,
          cartItems,
          addresses,
          Checked: false,
          preparing: false,
          Donepreparing: false,
          ontheWay: false,
          delivered: false,
          Promodiscount: localStorage.getItem("promodiscount") || 0,
          Notes,
          createdAt: Timestamp.now().toDate(),
        })
        const orderID = docRef.id
        Notify("Order Placed", "success")
        navigate("/Status-Delivery")
        localStorage.setItem("DeliveryOrderID", orderID)
        audio.volume = 0.7
        audio.play()
      } catch (error) {
        console.log(error)
      }
    } else {
      Notify("Please enter Name and Email", "error")
    }
  }

  // Comments: Pickup Payment Code
  const savePickupOrder = async () => {
    const uniqueOrderId = uuidv4().slice(-3)
    const today = new Date()
    const date = today.toDateString()
    const time = today.toLocaleTimeString()
    if (user !== null) {
      try {
        const docRef = await addDoc(collection(db, "PickupOrders"), {
          userID: user.userID,
          userName: user.name,
          userEmail: user.email,
          orderNumber: uniqueOrderId,
          orderDate: date,
          orderTime: time,
          orderAmount: cartdiscountedAmount || cartTotalAmount,
          orderStatus: "Pending Payment",
          Paid: false,
          OrderPlaced: true,
          Picked: false,
          Readyforpickup: false,
          Promodiscount: localStorage.getItem("promodiscount") || 0,
          processing: true,
          cartItems,
          Notes,
          Checked: false,
          createdAt: Timestamp.now().toDate(),
        })
        const PickuporderID = docRef.id
        localStorage.setItem("pendingOrderId", uniqueOrderId)
        localStorage.removeItem("Totalprice")
        localStorage.removeItem("discounted_price")
        localStorage.removeItem("promodiscount")
        localStorage.setItem("PickuporderID", PickuporderID)

        navigate("/status")
      } catch (error) {

        Notify("Failed to place order. Please try again.", "error")
      }
    } else {
      Notify("Please enter Name and Email", "error")
    }
  }

  const handleChangetable = (selectedOption) => {
    setTablenumber(selectedOption)
  }
  const handleChangePeople = (e) => {
    setPeopleNumber(e.target.value)
  }

  const handleChangePhone = (e) => {
    setPhoneNumber(e.target.value)
  }

  const handlechangeNotes = (e) => {
    setNotes(e.target.value)
  }

  //Delivery

  const handlechangeBuildingName = (e) => {
    setBuildingName(e.target.value)
  }

  const handlechangeAptNo = (e) => {
    setAptNo(e.target.value)
  }

  const handlechangeFloor = (e) => {
    setFloor(e.target.value)
  }

  const handlechangeStreet = (e) => {
    setStreet(e.target.value)
  }

  const handlechangeadditonalDirections = (e) => {
    setDirections(e.target.value)
  }

  const handleDeliveryPhonenumber = (e) => {
    setDeliveryPhoneNumber(e.target.value)
  }

  const TotalordersCountDinein = async () => {
    await updateDoc(doc(db, "users", user.userID), {
      numberOfDineinorders: increment(1),
    })
  }

  const TotalordersCountPickup = async () => {
    await updateDoc(doc(db, "users", user.userID), {
      numberOfPickuporders: increment(1),
    })
  }

  const TotalordersCountDelivery = async () => {
    await updateDoc(doc(db, "users", user.userID), {
      numberOfDeliveries: increment(1),
    })
  }

  const handlePlaceorder = async (e) => {
    e.preventDefault()
    if (localStorage.getItem("orderType") === "Reservation") {
      setPressedplace(true)
      await saveReservationOrder()
      dispatch(PLACE_ORDER())
      setPressedplace(false)
    }

    if (localStorage.getItem("orderType") === "Pickup") {
      setPressedplace(true)
      await savePickupOrder()
      //updated
      TotalordersCountPickup()
      setPressedplace(false)
    }

    if (localStorage.getItem("orderType") === "DineIn") {
      if (!closed) {
        if (tablenumber !== null && tablenumber.value > 0) {
          setPressedplace(true)
          await saveDineinOrder()
          TotalordersCountDinein()
          dispatch(PLACE_ORDER())
          dispatch(CLEAR_CART())
          setPressedplace(false)
        } else {
          Notify("please enter table number", "error")
        }
      } else {
        Notify(
          "We are closed now but we will be waiting for you tommorrow ",
          "error"
        )
      }
    }

    if (localStorage.getItem("orderType") === "Delivery") {
      if (!closed) {
        if (
          selectedAddress ||
          (buildingName && aptNo && floor && street && DeliveryphoneNumber)
        ) {
          setPressedplace(true)
          await saveDeliveryOrder()
          dispatch(PLACE_ORDER())
          TotalordersCountDelivery()
          setPressedplace(false)
        } else {
          Notify("please fill all the details", "error")
        }
      } else {
        Notify(
          "We are closed now but we will be waiting for you tommorrow ",
          "error"
        )
      }
    }
  }
  return [
    handlePlaceorder,
    tablenumber,
    handleChangetable,
    Notes,
    handlechangeNotes,
    pressedPlace,
    handleTimeChange,
    reservationTime,
    handleDateChange,
    selectedDate,
    handleChangePeople,
    handleChangePhone,
    phoneNumber,
    handlechangeBuildingName,
    buildingName,
    handlechangeAptNo,
    aptNo,
    handlechangeFloor,
    floor,
    handlechangeStreet,
    street,
    handlechangeadditonalDirections,
    directions,
    handleDeliveryPhonenumber,
    DeliveryphoneNumber,
    selectedAddress,
    setSelectedAddress,
    handleSelectAddress,
  ]
}
