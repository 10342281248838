import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  FilterProducts: [],
  FilterTables: [],
  FilterProductsAdmin: [],
  FilterOrdernumbers: [],
  FilterOrdernumbersDelivery: [],
  RecommendedProducts: [],
  showNotificationNote: false,
  selectedProduct: null,
}

const FilterProducts = createSlice({
  name: "filter",
  initialState,
  reducers: {
    FILTER_BY_SEARCH(state, action) {
      const { searchword, products } = action.payload
      let tempProducts
      tempProducts = products.filter((product) =>
        product.name.toLowerCase().includes(searchword.toLowerCase())
      )
      state.FilterProducts = tempProducts
    },

    FILTER_BY_CATEGORY(state, action) {
      const { categories, products } = action.payload
      const tempProducts = products.filter(
        (i) => categories.id === i.CategoryId
      )
      state.FilterProducts = tempProducts
    },

    FILTER_BY_SEARCH_TABLES(state, action) {
      const { tableSearch, orderData } = action.payload
      let tempTablenumbers = orderData
      state.FilterTables = tempTablenumbers
    },

    FILTER_BY_ORDER_NUMBERS(state, action) {
      const { OrderSearch, PickorderData } = action.payload
      let tempOrdernumbers = PickorderData
      state.FilterOrdernumbers = tempOrdernumbers
    },

    FILTER_BY_ORDER_NUMBERS_DELIVERY(state, action) {
      const { OrderSearch, DeliveryorderData } = action.payload
      let tempOrdernumbers = DeliveryorderData
      state.FilterOrdernumbersDelivery = tempOrdernumbers
    },

    FILTER_BY_SEARCH_ADMIN(state, action) {
      const { searchword, data } = action.payload
      let tempProducts
      if (searchword === "") {
        tempProducts = data
      }
      tempProducts = data.filter((product) =>
        product.name.toLowerCase().includes(searchword.toLowerCase())
      )

      if (tempProducts.length === 0) {
        tempProducts = data // Reset to all products
      }

      state.FilterProductsAdmin = tempProducts
    },

    SET_RECOMMENDED_PRODUCTS: (state, action) => {
      state.RecommendedProducts = action.payload
    },
    showNotification: (state, action) => {
      state.showNotificationNote = true
      state.selectedProduct = action.payload
    },
    hideNotification: (state) => {
      state.showNotificationNote = false
    },
  },
})

export const {
  FILTER_BY_SEARCH,
  FILTER_BY_CATEGORY,
  FILTER_BY_SEARCH_TABLES,
  FILTER_BY_SEARCH_ADMIN,
  FILTER_BY_ORDER_NUMBERS,
  FILTER_BY_ORDER_NUMBERS_DELIVERY,
  SET_RECOMMENDED_PRODUCTS,
  showNotification,
  hideNotification,
} = FilterProducts.actions
export const SelectFilteredproducts = (state) => state.filter.FilterProducts
export const SelectFilterednumbers = (state) => state.filter.FilterOrdernumbers
export const SelectFilteredTables = (state) => state.filter.FilterTables
export const SelectFilteredAdmin = (state) => state.filter.FilterProductsAdmin
export const Selectnotificationnote = (state) =>
  state.filter.showNotificationNote
export const SelectProductselected = (state) => state.filter.selectedProduct
export const SelectFilterOrdernumbersDelivery = (state) =>
  state.filter.FilterOrdernumbersDelivery
export const SelectRecommendedProducts = (state) =>
  state.filter.RecommendedProducts
export default FilterProducts.reducer
