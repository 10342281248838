import React from "react"
import { Navigate, Outlet } from "react-router-dom"
export const ProtectedAdminpage = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  if (user !== null) {
    if (user.email === process.env.REACT_APP_ADMIN_EMAIL) {
      let authuser = true
      return authuser ? <Outlet /> : null
    } else {
      return <Navigate to="/Login" />
    }
  } else {
    return <Navigate to="/Login" />
  }
}
