import { useEffect, useState } from "react"
import {
  updateDoc,
  Timestamp,
  doc,
  deleteDoc,
  increment,
} from "firebase/firestore"
import { db } from "../../../Firebase/config"
import Notify from "../Usenotifications"
import { useNavigate } from "react-router-dom"
export const GetoneDeliveryorderHook = (id, document) => {
  const [disable, setDisable] = useState(false)
  const [paid, setPaid] = useState("Not Ready")
  const navigate = useNavigate()

  const user = JSON.parse(localStorage.getItem("user"))
  const driverInfo = JSON.parse(localStorage.getItem("driverInfo"))

  const TotalordersCountDelivered = async () => {
    if (driverInfo !== null) {
      await updateDoc(doc(db, "Drivers", driverInfo.DriverID), {
        totalDeliveries: increment(1),
      })
    }
  }

  const handleDelivered = async (orderid) => {
    await updateDoc(doc(db, "DeliveryOrders", orderid), {
      delivered: true,
      deliveredAt: Timestamp.now().toDate(),
      Deliveredby: driverInfo.name || "Unknown",
    })
    TotalordersCountDelivered()
    Notify("Order Delivered", "success")
  }

  const handleOntheway = async (orderid) => {
    await updateDoc(doc(db, "DeliveryOrders", orderid), {
      ontheWay: true,
      pickedAt: Timestamp.now().toDate(),
      PickedBy: driverInfo.name || "Unknown",
      driverNumber: driverInfo.phone || "Not available",
    })

    Notify("Order Picked Up", "success")
  }

  const handleDelete = async (e, orderid) => {
    e.preventDefault()
    if (user.email && user.email === process.env.REACT_APP_ADMIN_EMAIL) {
      await deleteDoc(doc(db, "DeliveryOrders", orderid))
      Notify("Order deleted", "success")
      setTimeout(() => {
        navigate("/adminallorders")
      }, 1500)
    } else {
      Notify("Only Admins can delete Orders ", "error")
    }
  }
  //Updated
  const handleCheckDelivery = async (orderId) => {
    const today = new Date()
    const date = today.toDateString()
    const time = today.toLocaleTimeString()
    try {
      if (orderId) {
        await updateDoc(doc(db, "DeliveryOrders", orderId), {
          Checked: true,
          preparing: true,
          SeenAtDate: date,
          SeenAtTime: time,
        })
      }
    } catch (error) {
      console.error("Error updating item in Firestore:", error)
    }
  }

  const handleDonepreparing = async (orderId) => {
    const today = new Date()
    const date = today.toDateString()
    const time = today.toLocaleTimeString()
    try {
      if (orderId) {
        await updateDoc(doc(db, "DeliveryOrders", orderId), {
          Donepreparing: true,
          FinishedPreparingAt: Timestamp.now().toDate(),
        })
      }
    } catch (error) {
      console.error("Error updating item in Firestore:", error)
    }
  }

  //Updated
  useEffect(() => {
    if (document && document.ReadyAt) {
      setPaid("Ready")
      setDisable(true)
    }
  }, [document])

  return [
    handleDonepreparing,
    handleDelete,
    disable,
    paid,
    handleOntheway,
    handleCheckDelivery,
    handleDelivered,
  ]
}
