import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import StarRating from "./Starrating"
import { deleteDoc, doc, getDoc } from "firebase/firestore"
import { db } from "../../Firebase/config"
import "../../Styles/ReviewsPage.css"
import { Link, useNavigate } from "react-router-dom"
import Notify from "../Componentslogic/Usenotifications"

export const Adminreviewscard = ({ reviews }) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const navigate = useNavigate()
  const handleDelete = async () => {
    setShow(false)
    try {
      await deleteDoc(doc(db, "Reviews", reviews.id))
    } catch (error) {
      console.error("Error deleting review:", error)
    }
  }

  const handleViewDetails = async () => {
    const { orderType, orderID } = reviews
    try {
      const collectionName = orderType === "pickup" ? "PickupOrders" : "Orders"
      console.log(collectionName)
      const orderDoc = await getDoc(doc(db, collectionName, orderID))

      if (orderDoc.exists()) {
        if (orderType === "pickup") {
          navigate(`/adminPickuporderdetails/${orderID}`)
        } else {
          console.log("hello")
          navigate(`/adminorderdetails/${orderID}`)
        }
      } else {
        Notify("No Order was Found", "error")
      }
    } catch (error) {
      Notify("Error fetching order details:", error)
    }
  }

  return (
    <div>
      <div className="Review-Card  mt-4">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Confirm Delete </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete review of {reviews.useremail}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="circle-name-reviews ">
          <p>
            <b> {reviews.userName} </b>
          </p>
        </div>
        <div className="rating-box">
          <p className="text">Service Rating</p>
          <StarRating rating={reviews.ratingService} />
        </div>
        <div className="rating-box">
          <p className="text">Food Rating</p>
          <StarRating rating={reviews.ratingFood} />
        </div>

        <div className="description-box">
          <p>{reviews.text || ""}</p>
        </div>

        <div>
          <div className="email-box">
            <p>{reviews.useremail || "No email provided"}</p>
          </div>
          <div className="email-box">
            <button onClick={handleViewDetails} className="btns-Admin mb-3">
              {" "}
              View Review details
            </button>
          </div>

          <div className="d-flex align-items-center justify-content-center ">
            <img
              onClick={handleShow}
              className="del-img"
              src="delete.png"
              alt="deleteicon"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
