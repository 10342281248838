import { Navigate, Outlet } from "react-router-dom"
export const ProtectedOrderType = () => {
  const OrderType = localStorage.getItem("orderType")
  if (OrderType !== null) {
    let authuser = true
    return authuser ? <Outlet /> : null
  } else {
    return <Navigate to="/" />
  }
}
