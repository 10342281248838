import { Link } from "react-router-dom"
import { LoginHook } from "../Componentslogic/authLogic/LoginHook"
import "../../Styles/authstyles.css"
import { motion as m } from "framer-motion"

function Loginform() {
  const [name, password, onchangeName, onchangePassword, onSubmit, pressed] =
    LoginHook()
  return (
    <div className="form-holder ">
      <div className="wrapper">
        <div>
          <label>
            <m.div className="flip-card__inner">
              <div className="flip-card__front">
                <m.div
                  className="title"
                  initial={{ opacity: 0, x: 15 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1, delay: 0.3 }}
                >
                  Log in
                </m.div>
                <m.form
                  className="flip-card__form"
                  action=""
                  initial={{ opacity: 0, x: 15 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  <input
                    style={{ touchAction: "manipulation" }}
                    value={name}
                    onChange={onchangeName}
                    className="flip-card__input"
                    name="email"
                    placeholder="Email"
                    type="email"
                  />
                  <input
                    style={{ touchAction: "manipulation" }}
                    value={password}
                    onChange={onchangePassword}
                    className="flip-card__input"
                    name="password"
                    placeholder="Password"
                    type="password"
                  />
                  <Link to="/forgotpassword" style={{ textDecoration: "none" }}>
                    <p>
                      <strong>Forgot Password</strong>
                    </p>
                  </Link>
                  <m.div
                    className="register"
                    initial={{ opacity: 0, x: 15 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, delay: 0.7 }}
                  >
                    {pressed ? (
                      <div className="flip-card__btn ">
                        <div class="loader ">
                          <li class="ball "></li>
                          <li class="ball "></li>
                          <li class="ball "></li>
                        </div>
                      </div>
                    ) : (
                      <button
                        onClick={(e) => onSubmit(e)}
                        className="flip-card__btn "
                        initial={{ opacity: 0, x: 15 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1, delay: 0.7 }}
                      >
                        <span className="bodytext">Login</span>
                      </button>
                    )}
                  </m.div>
                </m.form>
              </div>
            </m.div>
          </label>
        </div>
      </div>
    </div>
  )
}

export default Loginform
