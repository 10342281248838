import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import Logo from "../images/Logoshape-3.png"
import "../../Styles/Homestyle.css"
import { motion as m } from "framer-motion"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Timestamp, doc, updateDoc } from "firebase/firestore"
import { db } from "../../Firebase/config"
import { CLEAR_CART } from "../../redux/actions/CartSlice"
import { collection, query, where, getDocs } from "firebase/firestore"
import { FaFacebook, FaInstagram } from "react-icons/fa"
export const DineinHomeComponent = () => {
  const [pressedcheck, setpressedcheck] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const orderID = localStorage.getItem("orderID")
  const PickuporderID = localStorage.getItem("PickuporderID")
  const DeliveryOrderID = localStorage.getItem("DeliveryOrderID")
  const user = JSON.parse(localStorage.getItem("user"))
  const userID = user?.userID
  const [orders, setOrders] = useState([])
  const [Deliveryorders, setDeliveryOrders] = useState([])
  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.has("table")) {
      params.delete("table")
      navigate(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        { replace: true }
      )
    }
  }, [location, navigate])

  const handleCheck = async () => {
    setpressedcheck(true)

    await updateDoc(doc(db, "Orders", orderID), {
      CheckRequest: true,
      CheckRequestedAt: Timestamp.now().toDate(),
      Paid: false,
    })
    await dispatch(CLEAR_CART())
    localStorage.removeItem("orderType")
    navigate(`/completedorder/?type=dinein&&orderID=${orderID}`)
    setpressedcheck(false)

    const userData = localStorage.getItem("user")

    localStorage.clear()

    if (userData !== null) {
      localStorage.setItem("user", userData)
    }

    localStorage.setItem("ratePass", "true")
  }
  const generateToken = () => {
    return `${Math.random().toString(36).substring(2)}${Date.now()}`
  }

  const getExpirationTime = () => {
    const now = new Date()
    now.setMinutes(now.getMinutes() + 1) // 1-minute expiration
    return now.getTime()
  }

  const createLinkWithToken = () => {
    const isQRScanned = localStorage.getItem("isQRScanned") === "true"
    if (isQRScanned) {
      localStorage.setItem("orderType", "DineIn")
      return `/Menu`
    } else {
      return navigate("/Qrscan")
    }
  }

  return (
    <Row className="background">
      <Col>
        <div className="d-flex justify-content-center ">
          <m.div
            className="d-flex justify-content-center align-items-center circlebg-dineIn "
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.3 }}
            exit={{ opacity: 1 }}
          >
            <img className="images" alt="Logo" src={Logo}></img>
          </m.div>
        </div>

        <m.div
          className="d-flex justify-content-center "
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.7 }}
          exit={{ opacity: 1 }}
        >
          <div>
            {localStorage.getItem("orderID") ? (
              <div className="d-flex flex-column align-items-center ">
                <div className="d-flex flex-column">
                  {pressedcheck ? (
                    <div className="startbtns px-3 mt-3">
                      <div class="loader">
                        <li class="ball"></li>
                        <li class="ball"></li>
                        <li class="ball"></li>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => handleCheck()}
                      className="startbtns px-3 mt-3"
                    >
                      <span className="buttonText">
                        Request Check for{" "}
                        {localStorage.getItem("discounted_price") === null
                          ? localStorage.getItem("Totalprice")
                          : localStorage.getItem("discounted_price")}{" "}
                        EGP
                      </span>
                    </button>
                  )}

                  <Link to="/Menu" style={{ textDecoration: "none" }}>
                    <button className="startbtns px-3 mt-3">
                      <span className="buttonText">Add items to order?</span>
                    </button>
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <button
                  className="startbtns"
                  onClick={() => {
                    const link = createLinkWithToken()

                    if (link) {
                      navigate(link)
                    }
                  }}
                >
                  <span className="buttonText p-3">Start Ordering</span>
                </button>
              </div>
            )}
            <div className="d-flex flex-column mt-5">
              <p className="social-text ">
                <b>Visit us for Delivery, Pickup & Reservations!</b>
              </p>
              <div className="social-icons-container  ">
                <a
                  href="https://www.facebook.com/profile.php?id=61552698836883&mibextid=LQQJ4d"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-wrapper"
                >
                  <FaFacebook className="social-icon facebook-icon" />
                </a>
                <a
                  href="https://www.instagram.com/katalyst.eg?igsh=MTA3aWsweWFzd2w1bw=="
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-wrapper"
                >
                  <FaInstagram className="social-icon instagram-icon" />
                </a>
              </div>
            </div>
          </div>
        </m.div>
      </Col>
    </Row>
  )
}
