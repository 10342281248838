import React from "react"
import { Container, Col } from "react-bootstrap"
import "../../Styles/Adminreviews.css"
import { SidemenuComponent } from "../../Components/Admin/Sidemenu"
import TablesLayout from "../../Components/Admin/AdminReservation"
const AdminReservation = () => {
  return (
    <Container fluid className="px-0">
      <Col sm="2" xs="2" className="px-0">
        <SidemenuComponent />
      </Col>
      <Col sm="10" xs="12" className="px-0">
        <TablesLayout />
      </Col>
    </Container>
  )
}
export default AdminReservation
