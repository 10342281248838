import React from "react"
import { Container, Col } from "react-bootstrap"
import "../../Styles/Adminreviews.css"
import { SidemenuComponent } from "../../Components/Admin/Sidemenu"
import AllDrivers from "../../Components/Admin/AllDrivers"
const AdminallDriverspage = () => {
  return (
    <Container fluid className="px-0">
      <Col sm="2" xs="2" className="px-0">
        <SidemenuComponent />
      </Col>
      <Col sm="10" xs="10" className="px-0">
        <AllDrivers />
      </Col>
    </Container>
  )
}
export default AdminallDriverspage
