import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  startAfter,
  Timestamp,
} from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../Firebase/config"

const UsefetchcollectionTime = (collection_name) => {
  const [data, setData] = useState([]) // Holds the fetched data
  const [lastDoc, setLastDoc] = useState(null) // Tracks the last document for pagination
  const [loading, setLoading] = useState(false) // Loading state
  const [hasMore, setHasMore] = useState(true) // Tracks if more data is available
  const pageSize = 10 // Number of documents per page

  // Fetch paginated data with real-time updates
  const getCollection = (loadMore = false) => {
    setLoading(true)

    try {
      const now = new Date()
      const startOfToday = new Date(now)
      startOfToday.setHours(now.getHours() - 15, now.getMinutes(), 0, 0)

      const docRef = collection(db, collection_name)
      let q

      if (loadMore && lastDoc) {
        // Load more: Fetch documents after the last document
        q = query(
          docRef,
          where("createdAt", ">=", Timestamp.fromDate(startOfToday)),
          orderBy("createdAt", "desc"),
          startAfter(lastDoc),
          limit(pageSize)
        )
      } else {
        // Initial load: Fetch the first set of documents
        q = query(
          docRef,
          where("createdAt", ">=", Timestamp.fromDate(startOfToday)),
          orderBy("createdAt", "desc"),
          limit(pageSize)
        )
      }

      // Use a real-time listener to track changes
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))

        setData((prevData) =>
          loadMore ? [...prevData, ...fetchedData] : fetchedData
        ) // Append or set data

        setLastDoc(snapshot.docs[snapshot.docs.length - 1])
        setHasMore(snapshot.docs.length === pageSize)
        setLoading(false) // Stop loading
      })

      return unsubscribe // Unsubscribe for cleanup
    } catch (error) {
      console.error("Error fetching orders: ", error)
      setLoading(false)
    }
  }

  const loadMore = () => {
    if (hasMore) {
      getCollection(true)
    }
  }

  useEffect(() => {
    const unsubscribe = getCollection() // Initial fetch with real-time updates

    // Cleanup listener on unmount
    return () => unsubscribe()
  }, [collection_name])

  return { data, loadMore, loading, hasMore }
}

export default UsefetchcollectionTime
