import React from "react"
import { Container, Col } from "react-bootstrap"
import "../../Styles/Adminreviews.css"
import StaffComponent from "../../Components/Admin/StaffPage"
import { AllPickupOrdersStaff } from "../../Components/Admin/Allpickupordersstaff"
const StaffPickUpPage = () => {
  return (
    <Container fluid className="px-0">
      <Col sm="12" xs="12" className="px-0">
        <StaffComponent />
      </Col>
      <Col sm="12" xs="12" className="px-0">
        <AllPickupOrdersStaff />
      </Col>
    </Container>
  )
}
export default StaffPickUpPage
