import React from "react"
import Thankyoulayout from "../../Components/Thankyou/thankyoulayout"
import { Container } from "react-bootstrap"
import Ratingcomponent from "../../Components/Thankyou/Starrate"
import "../../Styles/Starstyle.css"
const Thankyoupage = () => {
  return (
    <div>
      <Container fluid className="review-container">
        <div className="review-card">
          <Thankyoulayout />
          <Ratingcomponent />
        </div>
      </Container>
    </div>
  )
}
export default Thankyoupage
