import React from "react"
import { Button, Row, Col } from "react-bootstrap"
import { motion as m } from "framer-motion"
import { Link } from "react-router-dom"
const Menuheader = () => {
  return (
    <m.div>
      <Row className="d-flex justify-content-between align-items-center mb-3">
        <Col xs="8" className="my-2 ">
          <h1 className="Head">Menu</h1>
        </Col>
        {localStorage.getItem("user") &&
        localStorage.getItem("orderID") === null ? (
          <Col xs="3" className="d-flex ">
            <div
              className="text d-flex justify-content-end"
              onClick={() => {
                localStorage.removeItem("user")
                window.location.reload(false)
              }}
            >
              <button className="btns-red"> Logout</button>
            </div>
          </Col>
        ) : null}

        <Col className="checkout-container " xs="4">
          {localStorage.getItem("orderID") === null ? null : (
            <Link to="/">
              <Button>Done</Button>
            </Link>
          )}
        </Col>
      </Row>
    </m.div>
  )
}
export default Menuheader
