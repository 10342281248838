import React from "react"
import { Container } from "react-bootstrap"
import { DineinHomeComponent } from "../../Components/Home/DineInHome"
import { HomeComponentPDR } from "../../Components/Home/PDR"
const HomepagePDRpage = () => {
  return (
    <Container fluid>
      <HomeComponentPDR />
    </Container>
  )
}
export default HomepagePDRpage
