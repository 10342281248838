import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GetallordersHook } from "../Componentslogic/ordersLogic/GetallordersHook"
import { Link, useNavigate } from "react-router-dom"
import { motion as m } from "framer-motion"
import { GetDineinorderHook } from "../Componentslogic/ordersLogic/GetsoneorderHook"
import Spinner from "react-bootstrap/Spinner"
import {
  FILTER_BY_SEARCH_TABLES,
  SelectFilteredTables,
} from "../../redux/actions/FilterProducts"
import "../../Styles/ALLorders.css"
import Tick from "../images/Tick.svg"
import UsefetchCollectionAdmin from "../Componentslogic/Usefetchcollection"
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "../../Firebase/config"
export const Allorders = () => {
    const navigate = useNavigate()
  const {
    data: orderData,
    loading,
    // eslint-disable-next-line
    currentPage,
    nextPage,
    hasMore,
  } = UsefetchCollectionAdmin("Orders")
  const filteredTables = useSelector(SelectFilteredTables)

  const [handlechange, tableSearch] = GetallordersHook()

  const [handlePaid, , , handleSeen, , , ,] = GetDineinorderHook()

  const dispatch = useDispatch()

  const [searchData, setSearchData] = useState([])
  const timeoutRef = useRef(null)

const user = JSON.parse(localStorage.getItem("user"))

  useEffect(() => {
    if (user.email !== process.env.REACT_APP_ADMIN_EMAIL) {
      localStorage.removeItem("user")
      navigate("/Login")
    }
  }, [])

  useEffect(() => {
    const fetchSearchData = async () => {
      if (tableSearch !== "") {
        try {
          const docRef = collection(db, "Orders")
          const q = query(
            docRef,
            where("tablenumber", "==", Number(tableSearch))
          )
          const querySnapshot = await getDocs(q)
          const fetchedData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          setSearchData(fetchedData)
        } catch (error) {
          console.error("Error fetching search data", error)
        }
      } else {
        setSearchData([])
      }
    }
    timeoutRef.current = setTimeout(() => {
      fetchSearchData(tableSearch)
    }, 200)

    // Cleanup timeout on component unmount or re-render
    return () => clearTimeout(timeoutRef.current)
  }, [tableSearch])

  useEffect(() => {
    try {
      if (searchData.length > 0) {
        dispatch(
          FILTER_BY_SEARCH_TABLES({ tableSearch, orderData: searchData })
        )
      } else {
        dispatch(FILTER_BY_SEARCH_TABLES({ tableSearch: "", orderData }))
      }
    } catch (error) {
      console.error("Error filtering tables", error)
    }
  }, [searchData, tableSearch, orderData, dispatch])

  return (
    <div>
      <div className="header-section">
        <m.h2
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          All orders : {orderData.length}
        </m.h2>
        <m.div
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <input
            className="searchInput"
            type="text"
            placeholder="Search...."
            value={tableSearch}
            onChange={(e) => handlechange(e)}
          ></input>
        </m.div>
      </div>

      <div className="Review-Container_orders">
        {filteredTables.length > 0 ? (
          filteredTables.map((item, index) => {
            return (
              <m.div
                className="Review-Card-F "
                key={index}
                initial={{ opacity: 0, x: 15 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <div className="mx-3">
                  <h1 className="table-number">
                    Table number : {item.tablenumber} {""}{" "}
                    {!item.Checked && (
                      <span
                        className="badge bg-primary "
                        style={{ cursor: "pointer" }}
                      >
                        New
                      </span>
                    )}
                  </h1>

                  <p>
                    {!item.Paid && item.CheckRequest && (
                      <m.div
                        initial={{ opacity: 0, x: 15 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                      >
                        <div className="Badge-cont">
                          <p
                            className="status-badge-checkReq  m-0"
                            onClick={() => {
                              handlePaid(item.id)
                              setTimeout(() => {
                                window.location.reload(false)
                              }, 1500)
                            }}
                          >
                            <strong>Check Requested</strong>
                          </p>
                        </div>
                      </m.div>
                    )}
                  </p>
                  <p>
                    {!item.Paid && !item.CheckRequest && item.updated && (
                      <m.div
                        initial={{ opacity: 0, x: 15 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                      >
                        <div className="Badge-cont">
                          <p className="status-badge-OrderUpdate m-0">
                            <strong>Order Updated</strong>
                          </p>
                        </div>
                      </m.div>
                    )}
                  </p>
                  <p>
                    {item.Paid && (
                      <div>
                        <div className="Badge-cont">
                          <p className="status-badge-Paid m-0">
                            <strong>Paid</strong>
                          </p>
                        </div>
                      </div>
                    )}
                  </p>

                  <div>
                    <div className="cart-items-container">
                      <p>
                        <b>Order Items:</b>
                      </p>

                      {item.cartItems.map((Cartitem, index) => (
                        <div key={index} className="cart-item-card">
                          <div className="item-header d-flex align-items-center ">
                            <p className="item-name">{Cartitem.name}</p>
                            <p className="item-quantity mx-2">
                              <b> Qty: {Cartitem.CartTotalQuantity}</b>
                            </p>

                            {Cartitem.updatedItem && (
                              <div
                                onClick={() => handleSeen(Cartitem.id, item)}
                              >
                                <img src={Tick} className="icon" alt="tick" />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div>
                        {item.Notes && (
                          <div>
                            <div className="Horizontal-divider"></div>
                            <p>
                              <b>Notes:</b> {item.Notes}
                            </p>
                          </div>
                        )}
                        <p>
                          {item.Promodiscount > 0 && (
                            <m.div
                              initial={{ opacity: 0, x: 15 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ duration: 0.5, delay: 0.3 }}
                            >
                              <div className="Horizontal-divider"></div>
                              <div className="Badge-cont">
                                <p
                                  className="status-badge-checkReq  m-0"
                                  style={{
                                    backgroundColor: "#009688",
                                    pointerEvents: "none",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <strong>
                                    discount: {item.Promodiscount} %
                                  </strong>
                                </p>
                              </div>
                            </m.div>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <Link
                    to={`/adminorderdetails/${item.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button className="btns-Admin mt-3">
                      View order details
                    </button>
                  </Link>{" "}
                  {item.CheckRequest || item.Paid ? (
                    <p className="mt-3">
                      <b>Total: {item.orderAmount}</b>
                    </p>
                  ) : null}
                </div>
              </m.div>
            )
          })
        ) : (
          <h2 style={{ color: "black" }}>no orders availables</h2>
        )}

        {loading && (
          <Spinner animation="grow" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}

        {hasMore && !loading && tableSearch === "" && (
          <div className="d-flex justify-content-center align-items-center">
            <button class="btn-Load" onClick={() => nextPage()}>
              Load More
            </button>{" "}
          </div>
        )}
      </div>
    </div>
  )
}
