import React from "react"
import Menuheader from "../../Components/Menu/Menuheader"
import { Container } from "react-bootstrap"
import Menucontainer from "../../Components/Menu/Menucontainer"
import { Menuproducts } from "../../Components/Menu/Menuproducts"

const Menupage = () => {
  return (
    <div className="main-Background">
      <Menuheader />
      <Container>
        <Menucontainer />
      </Container>
      <Menuproducts />
    </div>
  )
}
export default Menupage
