import Homepage from "./Pages/Homepage/Homepage"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Menupage from "./Pages/MenuPage/Menupage"
import { CheckoutPage } from "./Pages/Checkoutpage/Checkout"
import Thankyoupage from "./Pages/Thankyoupage/Thankyoupage"
import { Loginpage } from "./Pages/Auth/Loginpage"
import Adminaddcategory from "./Pages/Admin/Addcategorgypage"
import Adminaddproduct from "./Pages/Admin/Addproductpage"
import { Toaster } from "react-hot-toast"
import AdminDeleteorEdit from "./Pages/Admin/allproductspage"
import Admineditproductpage from "./Pages/Admin/Editproductpage"
import Adminreviewspage from "./Pages/Admin/Allreviewspage"
import Adminaddcouponpage from "./Pages/Admin/Addcouponpage"
import { OrderreviewPage } from "./Pages/Cartpage/Cartpage"
import Adminallorderspage from "./Pages/Admin/Allorders"
import AdminoneorderdetailsPage from "./Pages/Admin/Oneorderdetailspage"
import { ProtectedRoutepage } from "./Protected routes/ProtectedRoutepage"
import { ProtectedAdminpage } from "./Protected routes/ProtectedAdmin"
import Adminalluserspage from "./Pages/Admin/Allusers"
import AdminallPickuporderspage from "./Pages/Admin/AllpickupOrders"
import AdminonePickuporderdetailsPage from "./Pages/Admin/OnePickuporderdetail"
import AdminaddTimepage from "./Pages/Admin/Adminsetimepage"
import AdminOverview from "./Pages/Admin/AdminOverviewpage"
import StaffDineinPage from "./Pages/Staff/DineINpage"
import StaffPickUpPage from "./Pages/Staff/Pickuppage"
import { ProtectedStaffpage } from "./Protected routes/ProtectedRouteStaff"
import AdminReservation from "./Pages/Admin/ReservationPage"
import StaffReservationPage from "./Pages/Staff/Reservations"
import StaffLayout from "./Protected routes/StaffLayout"
import PickuporderstatusPage from "./Pages/Thankyoupage/Pickuporderstatuspage"
import { ProtectedAdminandStaffpage } from "./Protected routes/ProtectedAdminandStaffpage"
import { ProtectedOrderType } from "./Protected routes/ProtectedOrdertype"
import QrScan from "./Components/Home/QrScan"
import DeliveryPage from "./Components/Admin/AdminDelivery"
import AdminAllDeliveres from "./Pages/Admin/AllDeliveries"
import DeliveryOrdersStaff from "./Pages/Staff/AlldeliveryordersStaff"
import DeliveryorderstatusPage from "./Pages/Thankyoupage/DeliveryStatusPage"
import DriverPage from "./Components/Admin/Driver"
import AdminoneDeliveryorderdetailsPage from "./Pages/Admin/AdminoneDeliveryorderdetailsPage"
import Signuppage from "./Pages/Admin/DriverSignuppage"
import AdminallDriverspage from "./Pages/Admin/AllDrivers"
import LoginDriverform from "./Components/Auth/DriverLform"
import { ProtectedDriverpage } from "./Protected routes/ProtectedRouteDriver"
import HomepageDineIn from "./Pages/Homepage/HomePageDinein"
import HomepagePDRpage from "./Pages/Homepage/HomePagePDR"
import AdminaddNotificationpage from "./Pages/Admin/SetNotification"

function App() {
  return (
    <div>
      <Router>
        <Routes>
          {/* // Comments: everyone can access */}
          {/* <Route path="/" element={<Homepage />} /> */}
          <Route path="/" element={<HomepageDineIn />} />
          <Route path="/PDR" element={<HomepagePDRpage />} />
          <Route path="/Qrscan" element={<QrScan />} />
          <Route path="/Status" element={<PickuporderstatusPage />} />
          <Route
            path="/Status-Delivery"
            element={<DeliveryorderstatusPage />}
          />
          <Route path="/Login" element={<Loginpage />} />
          <Route path="/LoginDrivers" element={<LoginDriverform />} />
          <Route element={<ProtectedOrderType />}>
            <Route path="/Menu" element={<Menupage />} />
            <Route path="/Orderreview" element={<OrderreviewPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
          </Route>
          {/* //Comments: Only users can access */}
          <Route element={<ProtectedRoutepage />}>
            <Route path="/completedorder" element={<Thankyoupage />} />
          </Route>
          {/* //Comments: Admin and staff can access */}
          <Route element={<ProtectedAdminandStaffpage />}>
            <Route
              path="/adminorderdetails/:id"
              element={<AdminoneorderdetailsPage />}
            />
            <Route
              path="/adminPickuporderdetails/:id"
              element={<AdminonePickuporderdetailsPage />}
            />

            <Route
              path="/adminDeliveryorderdetails/:id"
              element={<AdminoneDeliveryorderdetailsPage />}
            />
          </Route>

          {/* //Comments: only admins can access */}
          <Route element={<ProtectedAdminpage />}>
            <Route path="/adminaddcategory" element={<Adminaddcategory />} />
            <Route path="/adminaddproduct" element={<Adminaddproduct />} />
            <Route path="/adminmanagement" element={<AdminDeleteorEdit />} />
            <Route path="/adminNotify" element={<AdminaddNotificationpage />} />
            <Route path="/DriverSignUp" element={<Signuppage />} />
            <Route path="/AllDrivers" element={<AdminallDriverspage />} />

            <Route
              path="/admineditproduct/:id"
              element={<Admineditproductpage />}
            />
            <Route path="/adminaddcoupon" element={<Adminaddcouponpage />} />
            <Route path="/adminSettime" element={<AdminaddTimepage />} />
            <Route path="/adminallorders" element={<Adminallorderspage />} />
            <Route
              path="/adminallpickuporders"
              element={<AdminallPickuporderspage />}
            />
            <Route path="/adminOverview" element={<AdminOverview />} />
            <Route path="/adminallusers" element={<Adminalluserspage />} />
            <Route path="/allreviews" element={<Adminreviewspage />} />
            <Route path="/Reservation" element={<AdminReservation />} />

            <Route path="/Delivery-Orders" element={<AdminAllDeliveres />} />
          </Route>
          <Route element={<ProtectedStaffpage />}>
            <Route
              path="/StaffPage/*"
              element={
                <StaffLayout>
                  <Routes>
                    <Route path="DineIn" element={<StaffDineinPage />} />
                    <Route path="PickUp" element={<StaffPickUpPage />} />
                    <Route path="Delivery" element={<DeliveryOrdersStaff />} />
                    <Route
                      path="StaffReservationPage"
                      element={<StaffReservationPage />}
                    />
                  </Routes>
                </StaffLayout>
              }
            />
          </Route>
          <Route element={<ProtectedDriverpage />}>
            <Route path="/Driver" element={<DriverPage />} />
          </Route>
        </Routes>
      </Router>
      <Toaster />
    </div>
  )
}

export default App
