import React from "react"
import { Form } from "react-bootstrap"
import { Rating } from "react-simple-star-rating"
import { RatingHook } from "../Componentslogic/thankyouLogic/ratingHook"
import { Link, useLocation } from "react-router-dom"
import { motion as m } from "framer-motion"
const Ratingcomponent = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const type = searchParams.get("type")
  const [text, handleRating, handleText, handleRatingFood, onSubmitrating] =
    RatingHook()
  const getNavigationPath = () => {
    const searchParams = new URLSearchParams(location.search)
    const type = searchParams.get("type")

    return type === "dinein" ? "/" : "/PDR"
  }
  return (
    <m.div
      className="my-3"
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5, delay: 0.9 }}
    >
      <div>
        <h6 className=" text">How Was Our Service?</h6>
        <Rating onClick={handleRating} />
      </div>

      <div>
        <h6 className=" text mt-4 mb-2">How Did You Enjoy Your Meal?</h6>
        <Rating onClick={handleRatingFood} />
      </div>

      <Form className="mt-3">
        <m.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 1.1 }}
        >
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label className="title-Experience">
              Leave Feedback?
            </Form.Label>
            <Form.Control
              value={text}
              onChange={handleText}
              as="textarea"
              rows={3}
            />
            <div className="d-flex justify-content-center my-2">
              <button
                onClick={onSubmitrating}
                className="btns-addtoorder  my-2 p-4"
              >
                Submit
              </button>
            </div>
            <Link to={getNavigationPath()} style={{ textDecoration: "none" }}>
              <div className="d-flex justify-content-center mt-3 skip">
                <p>Skip</p>
              </div>
            </Link>
          </Form.Group>
        </m.div>
      </Form>
    </m.div>
  )
}
export default Ratingcomponent
