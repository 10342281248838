import { useState } from "react"
import { collection, addDoc, Timestamp } from "firebase/firestore"
import { db } from "../../../Firebase/config"
import Notify from "../Usenotifications"
const CreateproductHook = () => {
  const OnchangeName = (e) => {
    setName(e.target.value)
  }
  const OnchangeDescription = (e) => {
    setDescription(e.target.value)
  }
  const OnchangeCatid = (e) => {
    const [catId, catName] = e.target.value.split("|")
    setCategoryName(catName)
    setCategoryId(catId)
  }
  const Onchangepricebefore = (e) => {
    setPricebefore(e.target.value)
  }
  const Onchangepriceafter = (e) => {
    setPriceafter(e.target.value)
  }
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [catId, setCategoryId] = useState("")
  const [catName, setCategoryName] = useState("")
  const [priceBefore, setPricebefore] = useState("")
  const [priceAfter, setPriceafter] = useState("")
  const handleSubmitproduct = async (e) => {
    e.preventDefault()

    if (name && description && priceBefore && catId && catName) {
      try {
        await addDoc(collection(db, "Products"), {
          name: name,
          description: description,
          Price: Number(priceBefore),
          CategoryId: catId,
          CategoryName: catName,
          createdAt: Timestamp.now().toDate(),
        })
        Notify("Product added succesfully", "success")
        setName("")
        setDescription("")
        setCategoryId("")
        setCategoryName("")
        setPricebefore("")
        setPriceafter("")
      } catch {
        Notify("unable to Add Product", "error")
      }
    } else {
      Notify("Please fill all necessary fields", "error")
    }
  }
  return [
    name,
    description,
    priceBefore,
    priceAfter,
    handleSubmitproduct,
    OnchangeName,
    OnchangeDescription,
    OnchangeCatid,
    Onchangepricebefore,
    Onchangepriceafter,
    catId,
    catName,
  ]
}
export default CreateproductHook
