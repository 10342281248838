import React from "react"
import * as XLSX from "xlsx"
import { saveAs } from "file-saver"
import { Adminreviewscard } from "./Adminseereviews"
import { motion as m } from "framer-motion"
import Notify from "../Componentslogic/Usenotifications"
import UsefetchCollectionAdmin from "../Componentslogic/Usefetchcollection"
import Spinner from "react-bootstrap/Spinner"
const Adminreviewsconatiner = () => {
  const {
    data: reviews,
    loading,
    currentPage,
    nextPage,
    previousPage,
    hasMore,
  } = UsefetchCollectionAdmin("Reviews")
  const handleExtract = async () => {
    if (reviews.length > 0) {
      const reviewsData = reviews.map((review, index) => ({
        id: index + 1,
        "Client-Name": review.userName,
        "Client-Email": review.useremail,
        Comment: review.text,
        "Service-Rating": review.ratingService,
        "Food-Rating": review.ratingFood,
        Date: new Date(review.createdAt.seconds * 1000).toLocaleString(),
      }))

      // Create a worksheet and workbook
      const worksheet = XLSX.utils.json_to_sheet(reviewsData)
      worksheet["!cols"] = [
        { wch: 10 }, // Width for the 'id' column
        { wch: 20 }, // Width for the 'clientName' column
        { wch: 40 }, // Width for the 'clientEmail' column
        { wch: 40 }, // Width for the 'comment' column
        { wch: 15 }, // Width for the 'ratingService' column
        { wch: 15 }, // Width for the 'ratingFood' column
        { wch: 30 }, // Width for the 'date' column
      ]

      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reviews")

      // Convert the workbook to a binary string and create a Blob object
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      })
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" })

      // Use file-saver to save the file
      saveAs(blob, "reviews.xlsx")
      Notify("Extracted", "success")
    } else {
      Notify("error occured", "error")
    }
  }

  return (
    <div>
      {reviews.length ? (
        <div className="header-section mb-5">
          <m.h2
            className=" title "
            initial={{ opacity: 0, x: 15 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            Total Reviews: {reviews.length}
          </m.h2>
          <div onClick={() => handleExtract()}>
            <p className="extract">
              {" "}
              <b>Extract to Excel </b>{" "}
            </p>
          </div>
        </div>
      ) : null}
      <div className="Review-Container_Reviews">
        {reviews ? (
          reviews.map((review, index) => {
            return <Adminreviewscard key={index} reviews={review} />
          })
        ) : (
          <h3>No Reviews yet</h3>
        )}
        {loading && (
          <Spinner animation="grow" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}

        {hasMore && !loading && (
          <div className="d-flex justify-content-center align-items-center">
            <button class="btn-Load" onClick={() => nextPage()}>
              Load More
            </button>{" "}
          </div>
        )}
      </div>
    </div>
  )
}

export default Adminreviewsconatiner
