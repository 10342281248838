import React, { useState, useEffect } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import {
  doc,
  deleteDoc,
  addDoc,
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore"
import { db } from "../../Firebase/config"
import Notify from "../Componentslogic/Usenotifications"
import Modal from "react-bootstrap/Modal"
import { Link } from "react-router-dom"
import UsefetchCollection from "../Componentslogic/Usefetchcollection"
import useFetchDocument from "../Componentslogic/Usefetchdocument"

export const Adminmenucard = ({ products }) => {
  const [categoryName, setCategoryName] = useState(null) // State to hold the category name
  const { data } = UsefetchCollection("Categories")
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const dispatch = useDispatch()
  const [isRecommended, setIsRecommended] = useState(false)

  const handleDelete = async () => {
    setShow(false)
    await deleteDoc(doc(db, "Products", products.id))
    Notify("Product deleted", "success")

    setInterval(() => {
      window.location.reload(false)
    }, 1500)
  }

  const handleRemoveRecommendation = async () => {
    try {
      const q = query(
        collection(db, "Recommendations"),
        where("productId", "==", products.id)
      )
      const querySnapshot = await getDocs(q)
      const docId = querySnapshot.docs[0].id

      await deleteDoc(doc(db, "Recommendations", docId))
      setIsRecommended(false)
      Notify(`${products.name} removed from recommendations!`, "success")
    } catch (error) {
      console.error("Error removing recommendation: ", error)
      alert("Failed to remove recommendation. Please try again.")
    }
  }

  useEffect(() => {
    const checkRecommendation = async () => {
      try {
        const q = query(
          collection(db, "Recommendations"),
          where("productId", "==", products.id)
        )
        const querySnapshot = await getDocs(q)
        setIsRecommended(!querySnapshot.empty)
      } catch (error) {
        console.error("Error checking recommendations: ", error)
      }
    }

    checkRecommendation()
  }, [products.id])
  const handleAddRecommendation = async () => {
    try {
      await addDoc(collection(db, "Recommendations"), {
        name: products.name,
        description: products.description,
        Price: products.Price,
        category: products.CategoryName,
        productId: products.id,
        createdAt: Timestamp.now().toDate(),
      })
      Notify(`${products.name} added to recommendations!`, "success")
      setIsRecommended(true)
    } catch (error) {
      console.error("Error adding recommendation: ", error)
      alert("Failed to add recommendation. Please try again.")
    }
  }

  return (
    <div className="Review-Card-F mb-5">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Confirm Delete </Modal.Title>
        </Modal.Header>
        <Modal.Body>are you sure you want to delete {products.name}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Row>
        <Col sm="11">
          <h3 className="Product-title">{products.name}</h3>

          <p>
            <strong>
              {products.CategoryName ? products.CategoryName : null}
            </strong>
          </p>
          <p className="mb-3">{products.description}</p>
        </Col>

        <Col sm="12">
          <h4>Price:{products.Price} EGP</h4>

          <div className="bottom-part-Edit mt-4">
            <Link
              to={`/admineditproduct/${products.id}`}
              style={{ textDecoration: "none" }}
            >
              <button className=" btns-Admin ">Edit</button>
            </Link>

            {!isRecommended ? (
              <button
                className="btns-red-reco-add"
                onClick={() => handleAddRecommendation()}
              >
                Add as a Recommendation
              </button>
            ) : (
              <button
                className="btns-red-reco"
                onClick={() => handleRemoveRecommendation()}
              >
                Remove Recommendation
              </button>
            )}
          </div>
          <div className="d-flex justify-content-end mt-5 ">
            <img
              onClick={handleShow}
              className="nxtimg"
              src="delete.png"
              alt="deleteicon"
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}
