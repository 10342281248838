import React from "react"
import Form from "react-bootstrap/Form"
import CreateproductHook from "../Componentslogic/productsLogic/CreateproductHook"
import { motion as m } from "framer-motion"
import UsefetchCollection from "../Componentslogic/Usefetchcollection"

const Addproduct = () => {
  const { data } = UsefetchCollection("Categories")
  const [
    name,
    description,
    priceBefore,
    ,
    handleSubmitproduct,
    OnchangeName,
    OnchangeDescription,
    OnchangeCatid,
    Onchangepricebefore,
    ,
    catId,
    catName,
  ] = CreateproductHook()
  return (
    <div className="form-holder-admin ">
      <m.div
        className="wrapper "
        initial={{ opacity: 0, x: 15 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <div>
          <label>
            <div className="flip-card__inner">
              <div className="flip-card__front">
                <div className="title">Add Product</div>

                <form className="flip-card__form" action="">
                  <input
                    onChange={OnchangeName}
                    value={name}
                    className="input-Prod px-4"
                    name="Name"
                    placeholder="Add Product name"
                    type="text"
                  />
                  <textarea
                    onChange={OnchangeDescription}
                    value={description}
                    className="input-Prod  px-4"
                    name="Name"
                    placeholder="Add Product description"
                    type="text"
                    rows="5"
                  />
                  <Form.Select
                    onChange={OnchangeCatid}
                    value={catId ? `${catId}|${catName}` : ""}
                    aria-label="Default select example"
                    className="input-Prod my-2"
                  >
                    <option>Select category please</option>
                    {data
                      ? data.map((i, index) => (
                          <option key={index} value={`${i.id}|${i.name}`}>
                            {i.name}
                          </option>
                        ))
                      : null}
                  </Form.Select>
                  <input
                    onChange={Onchangepricebefore}
                    value={priceBefore}
                    className="input-Prod mt-3 px-4"
                    name="Name"
                    placeholder="Add Product Price"
                    type="number"
                  />

                  <div className="register">
                    <button
                      onClick={handleSubmitproduct}
                      className="btns-Admin"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </label>
        </div>
      </m.div>
    </div>
  )
}
export default Addproduct
