import React, { useState, useEffect } from "react"
import "../../Styles/Reservation.css"
import { doc, updateDoc, deleteDoc, increment } from "firebase/firestore"
import { db } from "../../Firebase/config"
import UsefetchcollectionNonRealtime from "../Componentslogic/UsefetchcollectionNonrealtime"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import Tick from "../images/Done.svg"
import Notify from "../Componentslogic/Usenotifications"

const TablesLayoutStaff = () => {
  const {
    data: reservationOrders,
    loading,
    error,
  } = UsefetchcollectionNonRealtime("Reservations")

  console.log(reservationOrders)
  const today = dayjs().format("YYYY-MM-DD")
  const [selectedDate, setSelectedDate] = useState("today")

  // Get tomorrow's date by adding 1 day to today's date
  const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD")
  const filteredReservations = reservationOrders.filter((order) => {
    if (selectedDate === "today") {
      return order.Date === today
    } else if (selectedDate === "tomorrow") {
      return order.Date === tomorrow
    }
    return false
  })

  const toggleReservation = async (order) => {
    try {
      if (order) {
        const orderDocRef = doc(db, "Reservations", order.id)

        await updateDoc(orderDocRef, {
          isReserved: false,
        })
        Notify("Order Confirmed", "success")

        setTimeout(() => {
          window.location.reload(false)
        }, 1500)

        TotalordersReserved(order.userID)
      }
    } catch (error) {
      console.error("Error updating reservation:", error)
    }
  }
  const TotalordersReserved = async (userID) => {
    await updateDoc(doc(db, "users", userID), {
      numberOfReservations: increment(1),
    })
  }
  const handleDelete = async (docId) => {
    try {
      // Reference to the document to delete
      const docRef = doc(db, "Reservations", docId)

      // Delete the document
      await deleteDoc(docRef)
      Notify("Reservation deleted successfully", "success")
      setTimeout(() => {
        window.location.reload(false)
      }, 1500)
    } catch (error) {
      console.error("Error deleting document: ", error)
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-center  mt-4">
        <div class="radio-inputs">
          <label class="radio">
            <input
              type="radio"
              name="reservationDate"
              value="today"
              checked={selectedDate === "today"}
              onChange={(e) => setSelectedDate(e.target.value)}
            />

            <span class="name">Today</span>
          </label>

          <label class="radio">
            <input
              type="radio"
              name="reservationDate"
              value="tomorrow"
              checked={selectedDate === "tomorrow"}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
            <span class="name">Tommorrow</span>
          </label>
        </div>
      </div>
      <div className="Review-Container_orders_Staff">
        {filteredReservations.length > 0 ? (
          filteredReservations.map((item, index) => (
            <div
              className="Review-Card-F-Resrve"
              key={index}
              initial={{ opacity: 0, x: 15 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <div className="mx-3">
                <div className="d-flex">
                  <h1 className="title-reserve  ">Time: {item.Time.label}</h1>
                  {!item.isReserved && (
                    <img className=" icon mx-2" src={Tick} alt="tick" />
                  )}
                </div>

                <div className="cart-items-container">
                  <>
                    <p>
                      <b>Name:</b> {item.userName}
                    </p>
                    <p>
                      <b>Email:</b> {item.userEmail}
                    </p>

                    <p>
                      <b>Order Reserved Date:</b> {item.Date}
                    </p>

                    <p>
                      <b>Number of people:</b> {item.PeopleNumber}
                    </p>

                    <p>
                      <b>Ordered At:</b>{" "}
                      {`${item.orderDate} at ${item.orderTime}`}
                    </p>
                    {item.isReserved ? (
                      <div
                        className="d-flex justify-content-center mt-4 mb-4"
                        style={{ gap: "10%" }}
                      >
                        <button
                          className="btns "
                          onClick={() => toggleReservation(item)}
                        >
                          {" "}
                          Confirmed{" "}
                        </button>
                        <button
                          className="btns-red"
                          onClick={() => handleDelete(item.id)}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                      </div>
                    ) : (
                      <button
                        className="btns-red"
                        onClick={() => handleDelete(item.id)}
                      >
                        {" "}
                        Delete{" "}
                      </button>
                    )}
                  </>

                  {item.PhoneNumber && (
                    <div>
                      <div className="Horizontal-divider"></div>
                      <p>
                        <b>Phone Number:</b> {item.PhoneNumber}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No orders available.</p>
        )}
      </div>
    </div>
  )
}
export default TablesLayoutStaff
// <div className="table-container">
//   {!isReserved ? (
//     <>
//       <img src={Table} alt={`Table ${number}`} />
//       <p className="table-number">Table {number}</p>
//     </>
//   ) : (
//     <ReservedSign
//       number={number}
//       time={time}
//       id={orderId}
//       toggleReservation={toggleReservation}
//     />
//   )}
// </div>

{
  /* // Reserved sign component
const ReservedSign = ({ number, time, id, toggleReservation }) => {
  return (
    <div className="reserved-sign mt-5">
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <p className="table-number-reserved">
            Table {number} - {time}
          </p>
          <img src={Table3_Reserved} alt={`Reserved Table ${number}`} />
          <Link
            to={`/StaffPage/adminReservationOrder/${id}`}
            style={{ textDecoration: "none" }}
          >
            <button className="view-details-btn">View Details</button>
          </Link>
        </div>
        <div onClick={toggleReservation}>
          <img
            className="mx-2"
            style={{ height: "30px", width: "30px", cursor: "pointer" }}
            src={Tick}
            alt="tick"
          />
        </div>
      </div>
    </div>
  )
} */
}

{
  /* const TablesLayoutStaff = () => {
  const allTables = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  const {
    data: reservationOrders,
    loading,
    error,
  } = UsefetchcollectionNonRealtime("Reservations")
  const [selectedDate, setSelectedDate] = useState("today")

  // Get today's date in "YYYY-MM-DD" format
  const today = dayjs().format("YYYY-MM-DD")

  // Get tomorrow's date by adding 1 day to today's date
  const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD")
  const filteredReservations = reservationOrders.filter((order) => {
    if (selectedDate === "today") {
      return order.Date === today
    } else if (selectedDate === "tomorrow") {
      return order.Date === tomorrow
    }
    return false
  })

  return (
    <div>
   

      <div className="layout-Staff">
        {allTables.map((tableNumber) => {
          // Check if the current table is in the reservationOrders
          const reservedOrder = filteredReservations.find(
            (order) => parseInt(order.tablenumber) === Number(tableNumber)
          )

          // If the table is reserved, set the status to true; otherwise, false
          const isReserved =
            reservedOrder && reservedOrder.isReserved ? true : false
          const orderId = reservedOrder ? reservedOrder.id : null
          const time =
            reservedOrder && reservedOrder.Time
              ? reservedOrder.Time.label
              : null

          return (
            <div className="row" key={tableNumber}>
              <Table_reservation
                time={time}
                number={tableNumber}
                initialReserved={isReserved}
                orderId={orderId}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
} */
}
