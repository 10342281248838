import React, { useEffect } from "react"
import { GetusercartHook } from "../Componentslogic/cartLogic/GetusercartHook"
import { Link } from "react-router-dom"
import "../../Styles/Cartstyle.css"
import backArrow from "../images/back.svg"
import del from "../images/del.svg"
import { motion as m } from "framer-motion"
import { useDispatch, useSelector } from "react-redux"
import {
  CALCULATE_TOTAL,
  selectCartAmountDiscounted,
  selectCartitems,
  selectCartTotalAmount,
} from "../../redux/actions/CartSlice"
import Pluslogo from "../images/PLUS.svg"
import Minuslogo from "../images/Minus.svg"

function Ordereview() {
  const cartData = useSelector(selectCartitems)
  const cartTotal = useSelector(selectCartTotalAmount)
  const cartTotaldis = useSelector(selectCartAmountDiscounted)

  const [
    handleClearcart,
    pressedClear,
    handleDeleteitem,
    handlepromo,
    onchangepromo,
    promo,
    handleQuantityAdd,
    handleQuantityDecrease,
  ] = GetusercartHook()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(CALCULATE_TOTAL())
  }, [dispatch, cartData, promo])

  return (
    <div className="container-main">
      <div className="head container-cart  ">
        <m.div
          className="Head mb-2"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <div className="d-flex align-items-center ">
            <Link to="/Menu" style={{ textDecoration: "none" }}>
              <img className="icon-TIT " src={backArrow} alt="go-back"></img>
            </Link>
          </div>
          <div className="TIT">Cart</div>
        </m.div>

        {cartData.length > 0 ? (
          <m.div
            sm="4"
            className="buttons mb-2 "
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            {pressedClear ? (
              <div className="btns-red ">
                <div class="loader">
                  <li class="ball"></li>
                  <li class="ball"></li>
                  <li class="ball"></li>
                </div>
              </div>
            ) : (
              <button onClick={() => handleClearcart()} className="btns-red ">
                Clear Cart
              </button>
            )}
          </m.div>
        ) : null}
        <m.div
          className="mb-4"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.7 }}
        >
          {Array.isArray(cartData) && cartData.length > 0 ? (
            cartData.map((item, index) => {
              return (
                <div key={index} className=" Cards-cart d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <div className="mx-3">
                      <h2 className=" title-Card">{item.name}</h2>
                    </div>

                    <div className=" mx-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-3">
                          Price:{item.Price * item.CartTotalQuantity} EGP
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="options">
                    <div className="d-flex justify-content-center align-items-center  ">
                      <div className="d-flex ">
                        <img
                          src={Minuslogo}
                          className="px-2"
                          alt="Plus-icon"
                          onClick={() => handleQuantityDecrease(item)}
                        />

                        <p className="text p-0 m-0">{item.CartTotalQuantity}</p>

                        <img
                          src={Pluslogo}
                          alt="Plus-icon"
                          className="px-2"
                          onClick={() => handleQuantityAdd(item)}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end ">
                      <img
                        src={del}
                        alt="delete-icon"
                        onClick={() => handleDeleteitem(item)}
                      ></img>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <div>
              <Link to="/Menu" style={{ textDecoration: "none" }}>
                <div className="d-flex justify-content-center mt-3 ">
                  <p>Back to menu</p>
                </div>
              </Link>
            </div>
          )}
        </m.div>
      </div>
      <m.div
        className="bottom"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.9 }}
      >
        <div className="Pricing">
          <p>Cart total</p>

          <p className="d-flex">
            Total Price:
            {cartTotaldis > 0 && cartTotaldis < cartTotal ? (
              <div className="d-flex">
                <p
                  className="mx-3"
                  style={{
                    textDecoration: "line-through",
                    textDecorationColor: "red",
                    color: "#f2f0ef",
                  }}
                >
                  {cartTotal}{" "}
                </p>
                <p> {cartTotaldis}</p>
              </div>
            ) : (
              cartTotal
            )}
          </p>
        </div>

        <div class="input-group">
          <input
            type="text"
            className="input"
            placeholder="Have a promocode?"
            autocomplete="off"
            value={promo}
            onChange={onchangepromo}
          />
          <button onClick={() => handlepromo(promo)} class="button--submit">
            APPLY
          </button>
        </div>

        {cartData.length > 0 ? (
          <div>
            <Link to={`/checkout`} style={{ textDecoration: "none" }}>
              <button className="btns-checkout">Go to Checkout</button>
            </Link>
          </div>
        ) : null}
      </m.div>
    </div>
  )
}

export default Ordereview
