import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isLoggedin: false,
  email: null,
  username: null,
  uid: null,
}

const authActions = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_ACTIVE_USER(state, action) {
      const { email, username, uid } = action.payload
      state.isLoggedin = true
      state.email = email
      state.username = username
      state.uid = uid
    },
  },
})

export const { SET_ACTIVE_USER } = authActions.actions
export const selectIsLoggedIn = (state) => state.usersReducer.isLoggedIn
export const selectEmail = (state) => state.usersReducer.email
export const selectUserName = (state) => state.usersReducer.username
export const selectUserID = (state) => state.usersReducer.uid

export default authActions.reducer
