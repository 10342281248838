import React from "react"
import { Button, Card } from "react-bootstrap"
import UsefetchCollection from "../Componentslogic/Usefetchcollection"
import { useState } from "react"
import { setDoc, doc } from "firebase/firestore"
import { db } from "../../Firebase/config"
import Notify from "../Componentslogic/Usenotifications"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
export const SetNotification = () => {
  const [closingTime, setClosingTime] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!closingTime) {
      Notify("Please fill in Notification", "error")
      return
    }
    try {
      setDoc(doc(db, "Notification", "4Az1NF5LIxQWdsCwmfXb"), {
        Notification: closingTime,
      })

      Notify("Notification Set successfully!", "success")
      setClosingTime("")
    } catch (error) {
      console.error("Error saving times:", error)
    }
  }

  const handleDelete = async (e) => {
    e.preventDefault()
    try {
      setDoc(doc(db, "Notification", "4Az1NF5LIxQWdsCwmfXb"), {
        Notification: "",
      })

      Notify("Notification Removed successfully!", "success")
      setClosingTime("")
    } catch (error) {
      console.error("Error saving times:", error)
    }
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="wrapper mb-3  ">
          <div>
            <label>
              <div className="flip-card__inner">
                <div className="title mb-5 mt-5">Set Notification</div>

                <p className="m-0 p-0">
                  <b>Add notification </b>
                </p>
                <ReactQuill
                  value={closingTime}
                  onChange={setClosingTime}
                  className="mb-2 mt-3"
                  placeholder="What is in your mind!"
                />
                <div className="register">
                  <button
                    className="btns-Admin mt-5"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Set Notification
                  </button>

                  <button
                    className="btns-red mt-4"
                    onClick={(e) => handleDelete(e)}
                  >
                    Remove existing Notification
                  </button>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}
