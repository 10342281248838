import React from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { InputGroup } from "react-bootstrap"
import EditproductHook from "../Componentslogic/productsLogic/EditproductHook"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import { motion as m } from "framer-motion"
import useFetchDocument from "../Componentslogic/Usefetchdocument"
import UsefetchCollectionNonrealtime from "../Componentslogic/UsefetchcollectionNonrealtime"
import arrow from "../images/left-arrow.png"
export const Editproduct = () => {
  const { data } = UsefetchCollectionNonrealtime("Categories")
  const { id } = useParams()
  const [
    name,
    description,
    priceBefore,
    priceAfter,
    handleSubmitproduct,
    OnchangeName,
    OnchangeDescription,
    OnchangeCatid,
    Onchangepricebefore,
    Onchangepriceafter,
    catId,
    catName,
  ] = EditproductHook(id)
  return (
    <div className="form-holder-admin ">
      <m.div
        className="wrapper "
        initial={{ opacity: 0, x: 15 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <div>
          <label>
            <div className="flip-card__inner">
              <div className="flip-card__front">
                <div className="title mb-3 ">
                  <h2 className=" d-flex justify-content-center align-items-center">
                    Edit Product
                  </h2>
                </div>

                <form className="flip-card__form" action="">
                  <input
                    onChange={OnchangeName}
                    value={name}
                    className="input-bg mt-3 px-4"
                    name="Name"
                    placeholder="Add Product name"
                    type="text"
                  />
                  <textarea
                    onChange={OnchangeDescription}
                    value={description}
                    className="input-bg mt-3 px-4"
                    name="Name"
                    placeholder="Add Product description"
                    type="text"
                    rows="5"
                  />
                  <Form.Select
                    value={catId ? `${catId}|${catName}` : ""}
                    onChange={OnchangeCatid}
                    aria-label="Default select example"
                    className="my-4"
                    aria-label="Default select example"
                    className="my-2"
                  >
                    <option>Select category please</option>
                    {data
                      ? data.map((i, index) => (
                          <option key={index} value={`${i.id}|${i.name}`}>
                            {i.name}
                          </option>
                        ))
                      : null}
                  </Form.Select>
                  <input
                    onChange={Onchangepricebefore}
                    value={priceBefore}
                    className="input-bg mt-3 px-4"
                    name="Name"
                    placeholder="Add Product Price"
                    type="number"
                  />

                  <div className="register">
                    <button
                      onClick={handleSubmitproduct}
                      className="btns-Admin "
                    >
                      Update
                    </button>
                  </div>
                  <Link
                    to="/adminmanagement"
                    style={{ textDecoration: "none" }}
                  >
                    <button className=" btns-Admin ">
                      <span>
                        <strong>Back</strong>
                      </span>
                    </button>
                  </Link>
                </form>
              </div>
            </div>
          </label>
        </div>
      </m.div>
    </div>
  )
}
