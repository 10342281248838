import React from "react"
import { Navigate, Outlet } from "react-router-dom"
export const ProtectedRoutepage = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  if (user !== null) {
    let authuser = true
    return authuser ? <Outlet /> : null
  } else {
    return <Navigate to="/" />
  }
}
