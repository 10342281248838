import React from "react"
import rating from "../images/rating.png"
import { motion as m } from "framer-motion"
const Thankyoulayout = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  return (
    <div className="mb-4">
      <m.div
        className="rate-cont"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <img className="rate-img mb-3" alt="Rate" src={rating}></img>
      </m.div>
      <m.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.7 }}
      >
        <h2 className="title-Menu">
          Thank You {user ? (user.name ? user.name : "") : null}!
        </h2>
      </m.div>
    </div>
  )
}
export default Thankyoulayout
