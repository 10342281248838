import { useState } from "react"
const FilterSearchHook = () => {
  const [searchword, setSearchword] = useState("")
  const [selectedCategory, setSelectedCategory] = useState(null)

  const handlechange = (e) => {
    setSearchword(e.target.value)
  }

  return [searchword, handlechange, setSelectedCategory, selectedCategory]
}
export default FilterSearchHook
