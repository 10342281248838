import React, { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import { motion as m } from "framer-motion"
import { useDispatch, useSelector } from "react-redux"
import { db } from "../../Firebase/config"
import UsefetchcollectionTime from "../Componentslogic/Usefetchcollectiontime"
import Spinner from "react-bootstrap/Spinner"
import {
  FILTER_BY_ORDER_NUMBERS,
  FILTER_BY_ORDER_NUMBERS_DELIVERY,
  SelectFilterednumbers,
  SelectFilterOrdernumbersDelivery,
} from "../../redux/actions/FilterProducts"
import {
  SET_NEW_DELIVERY_COUNT,
  SET_NEW_PICKUP_ORDERS,
} from "../../redux/actions/OrdersStaffslice"
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore"
import { GetoneDeliveryorderHook } from "../Componentslogic/ordersLogic/Getonedeliveryorder"
export const AllDeliveryOrdersStaff = () => {
  const {
    data: DeliveryorderData,
    loadMore,
    loading,
    hasMore,
  } = UsefetchcollectionTime("DeliveryOrders")
  const [OrderSearch, setOrdersearch] = useState("")
  const FilteredOrders = useSelector(SelectFilterOrdernumbersDelivery)

  const [
    handleDonepreparing,
    handleDelete,
    disable,
    paid,
    handleOntheway,
    handleCheckDelivery,
    handleDelivered,
  ] = GetoneDeliveryorderHook()
  const dispatch = useDispatch()

  const [ordersWithNotes, setOrdersWithNotes] = useState([])

  const [searchData, setSearchData] = useState([])

  const [userNotes, setUserNotes] = useState({})
  const timeoutRef = useRef(null)

  const fetchUserNotes = async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId)
      const userDoc = await getDoc(userDocRef)
      if (userDoc.exists()) {
        return userDoc.data().AdminNote
      } else {
        return
      }
    } catch (error) {
      console.error("Error fetching user notes:", error)
      return "Error loading notes"
    }
  }

  const fetchOrdersWithNotes = async () => {
    try {
      const enhancedOrders = await Promise.all(
        DeliveryorderData.map(async (order) => {
          const notes = await fetchUserNotes(order.userID)
          return { ...order, userNotes: notes }
        })
      )
      setOrdersWithNotes(enhancedOrders)
    } catch (error) {
      console.error("Error fetching orders with notes:", error)
    }
  }

  useEffect(() => {
    if (DeliveryorderData.length > 0) {
      fetchOrdersWithNotes()
    }
  }, [DeliveryorderData])

  useEffect(() => {
    const fetchSearchData = async () => {
      if (OrderSearch !== "") {
        try {
          const docRef = collection(db, "DeliveryOrders")
          const q = query(docRef, where("orderNumber", "==", OrderSearch))
          const querySnapshot = await getDocs(q)
          const fetchedData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          setSearchData(fetchedData)
        } catch (error) {
          console.error("Error fetching search data", error)
        }
      } else {
        setSearchData([])
      }
    }

    fetchSearchData()
  }, [OrderSearch])

  useEffect(() => {
    try {
      if (searchData.length > 0) {
        dispatch(
          FILTER_BY_ORDER_NUMBERS_DELIVERY({
            OrderSearch,
            DeliveryorderData: searchData,
          })
        )
      } else {
        dispatch(
          FILTER_BY_ORDER_NUMBERS_DELIVERY({
            OrderSearch: "",
            DeliveryorderData: ordersWithNotes,
          })
        )
      }
    } catch (error) {
      console.error("Error filtering tables", error)
    }
  }, [searchData, OrderSearch, DeliveryorderData, dispatch, ordersWithNotes])

  const newDeliveryorders = DeliveryorderData.filter(
    (order) => !order.Checked
  ).length
  useEffect(() => {
    dispatch(SET_NEW_DELIVERY_COUNT(newDeliveryorders))
  }, [newDeliveryorders, DeliveryorderData, dispatch])

  return (
    <div>
      <div className="header-section_Staff">
        <m.h2
          className=" title"
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          All orders : {DeliveryorderData.length}
        </m.h2>
        <m.div
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <input
            className="searchInput"
            type="text"
            placeholder="Search...."
            value={OrderSearch}
            onChange={(e) => setOrdersearch(e.target.value)}
          ></input>
        </m.div>
      </div>
      <div className="Review-Container_orders_Staff ">
        {FilteredOrders ? (
          FilteredOrders.map((item, index) => {
            return (
              <m.div
                className="Review-Card-F "
                key={index}
                initial={{ opacity: 0, x: 15 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <div className="mx-3">
                  <h1 className="table-number">
                    Order-Number : {item.orderNumber}
                    {!item.Checked && (
                      <span
                        className="badge bg-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCheckDelivery(item.id)}
                      >
                        New
                      </span>
                    )}
                  </h1>

                  <p>
                    {item.Checked && !item.Donepreparing && (
                      <m.div
                        className="d-flex justify-content-between"
                        initial={{ opacity: 0, x: 15 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                      >
                        <div className="Badge-cont">
                          <p
                            className="status-badge-checkReq m-0"
                            onClick={() => handleDonepreparing(item.id)}
                          >
                            <strong>Preparing</strong>
                          </p>
                        </div>
                      </m.div>
                    )}
                  </p>

                  <p>
                    {item.Donepreparing &&
                      !item.ontheWay &&
                      !item.delivered && (
                        <div className="Badge-cont">
                          <p className="status-badge-Paid m-0">
                            <strong>Done preparing</strong>
                          </p>
                        </div>
                      )}
                  </p>

                  <p>
                    {item.ontheWay && !item.delivered && (
                      <div className="Badge-cont">
                        <p className="status-badge-Paid m-0">
                          <strong>On the way</strong>
                        </p>
                      </div>
                    )}
                  </p>

                  <p>
                    {item.delivered && (
                      <div className="Badge-cont">
                        <p className="status-badge-Paid m-0">
                          <strong>Delivered</strong>
                        </p>
                      </div>
                    )}
                  </p>

                  <div>
                    <div className="cart-items-container">
                      <p>
                        <b>Order Items:</b>
                      </p>
                      {item.cartItems.map((Cartitem, index) => (
                        <div key={index} className="cart-item-card">
                          <div className="item-header d-flex align-items-center ">
                            <p className="item-name">{Cartitem.name}</p>
                            <p className="item-quantity mx-2">
                              <b> Qty: {Cartitem.CartTotalQuantity}</b>
                            </p>
                          </div>
                        </div>
                      ))}

                      <div>
                        {item.Notes && (
                          <div>
                            <div className="Horizontal-divider"></div>
                            <p>
                              <b>Notes:</b> {item.Notes}
                            </p>
                          </div>
                        )}

                        {item.userNotes && (
                          <div className="notes-section">
                            <div className="Horizontal-divider"></div>
                            <p>
                              <b>Admin Notes:</b> {item.userNotes}
                            </p>
                          </div>
                        )}
                        <p>
                          {item.Promodiscount > 0 && (
                            <m.div
                              initial={{ opacity: 0, x: 15 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ duration: 0.5, delay: 0.3 }}
                            >
                              <div className="Horizontal-divider"></div>
                              <div className="Badge-cont">
                                <p
                                  className="status-badge-checkReq  m-0"
                                  style={{
                                    backgroundColor: "#009688",
                                    pointerEvents: "none",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <strong>
                                    discount: {item.Promodiscount} %
                                  </strong>
                                </p>
                              </div>
                            </m.div>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <Link
                    to={`/adminDeliveryorderdetails/${item.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button className="btns-Admin">View order details</button>
                  </Link>
                  {item.preparing || item.Donepreparing ? (
                    <p className="mt-3">
                      <b>Total: {item.orderAmount}</b>
                    </p>
                  ) : null}
                </div>
              </m.div>
            )
          })
        ) : (
          <h2 style={{ color: "black" }}>no orders availables</h2>
        )}

        {loading && (
          <Spinner animation="grow" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}

        {hasMore && !loading && (
          <div className="d-flex justify-content-center align-items-center">
            <button class="btn-Load" onClick={() => loadMore()}>
              Load More
            </button>{" "}
          </div>
        )}
      </div>
    </div>
  )
}
