import React from "react"
import { Container, Col } from "react-bootstrap"
import DeliveryPage from "../../Components/Admin/AdminDelivery"
import "../../Styles/Adminreviews.css"
import { SidemenuComponent } from "../../Components/Admin/Sidemenu"
const AdminAllDeliveres = () => {
  return (
    <Container fluid className="px-0">
      <Col sm="2" xs="2" className="px-0">
        <SidemenuComponent />
      </Col>
      <Col sm="10" xs="12" className="px-0">
        <DeliveryPage />
      </Col>
    </Container>
  )
}
export default AdminAllDeliveres
