import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import Notify from "../Usenotifications"
import { collection, addDoc, Timestamp } from "firebase/firestore"
import { db } from "../../../Firebase/config"
export const RatingHook = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  const userEmail = user.email
  const userName = user.name
  const navigate = useNavigate()
  const [ratingService, setRatingservice] = useState("")
  const [ratingFood, setRatingFood] = useState("")
  const [text, setText] = useState("")
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const type = queryParams.get("type")
  const orderID = queryParams.get("orderID")

  const handleRating = (rate: number) => {
    setRatingservice(rate)
  }
  const handleRatingFood = (rate: number) => {
    setRatingFood(rate)
  }

  const handleText = (e) => {
    setText(e.target.value)
  }

  const onSubmitrating = async (e) => {
    e.preventDefault()
    if (localStorage.getItem("ratePass") !== null) {
      if (ratingService === "" || ratingFood === "") {
        Notify("Please enter a valid rating", "error")
      } else {
        try {
          await addDoc(collection(db, "Reviews"), {
            ratingService: ratingService,
            ratingFood: ratingFood,
            text: text,
            useremail: userEmail,
            userName: userName,
            orderType: type,
            orderID: orderID,
            createdAt: Timestamp.now().toDate(),
          })
          Notify("Thanks for your review", "success")
          localStorage.removeItem("ratePass")
          if (type === "dinein") {
            navigate("/")
          } else {
            navigate("/PDR")
          }
        } catch (error) {
          Notify("Error occured", "error")
        }
      }
    } else {
      Notify("You need to have an order to add review", "error")
    }
  }

  return [text, handleRating, handleText, handleRatingFood, onSubmitrating]
}
