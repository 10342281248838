import React, { useState } from "react"
import "../../Styles/DeliveryPage.css"
import UsefetchCollectionAdmin from "../Componentslogic/Usefetchcollection"
import { NavLink, Link } from "react-router-dom"
const DeliveryPage = () => {
  const {
    data: deliveryData,
    loading,
    // eslint-disable-next-line
    currentPage,
    nextPage,
    hasMore,
  } = UsefetchCollectionAdmin("DeliveryOrders")

  const [selectedFilter, setSelectedFilter] = useState("All")

  const getStatus = (order) => {
    if (order.delivered) return { text: "Delivered", className: "Delivered" }
    if (order.ontheWay) return { text: "On the Way", className: "fulfilled" }
    if (order.Donepreparing)
      return { text: "Done Preparing", className: "Done" }
    if (order.preparing) return { text: "Preparing", className: "unfulfilled" }
    return { text: "Placed", className: "pending" }
  }

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter)
  }

  const filteredData = deliveryData.filter((order) => {
    const { text } = getStatus(order)
    if (selectedFilter === "All") return true
    return text === selectedFilter
  })

  return (
    <div className="DeliveryContainer">
      <div className="dashboard-container">
        <main className="main-content">
          <header className="header">
            <input type="text" placeholder="Search..." />
          </header>

          <section className="summary-cards">
            <div
              className={`card ${
                selectedFilter === "All" ? "active-filter" : ""
              }`}
              onClick={() => handleFilterClick("All")}
            >
              All Orders
            </div>
            <div
              className={`card ${
                selectedFilter === "Placed" ? "active-filter" : ""
              }`}
              onClick={() => handleFilterClick("Placed")}
            >
              Placed
            </div>
            <div
              className={`card ${
                selectedFilter === "Preparing" ? "active-filter" : ""
              }`}
              onClick={() => handleFilterClick("Preparing")}
            >
              Preparing
            </div>
            <div
              className={`card ${
                selectedFilter === "Done Preparing" ? "active-filter" : ""
              }`}
              onClick={() => handleFilterClick("Done Preparing")}
            >
              Done Preparing
            </div>
            <div
              className={`card ${
                selectedFilter === "On the Way" ? "active-filter" : ""
              }`}
              onClick={() => handleFilterClick("On the Way")}
            >
              On the way
            </div>
            <div
              className={`card ${
                selectedFilter === "Delivered" ? "active-filter" : ""
              }`}
              onClick={() => handleFilterClick("Delivered")}
            >
              Delivered
            </div>
          </section>

          <section className="orders-table mb-3">
            <table>
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Customer</th>
                  <th>Total</th>
                  <th>Delivery</th>
                  <th>Address</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((order, index) => {
                  const { text, className } = getStatus(order)
                  return (
                    <tr key={index}>
                      <td>{order.orderNumber}</td>
                      <td>{`${order.orderDate} at ${order.orderTime}`}</td>
                      <td>{order.userName}</td>
                      <td>{order.orderAmount} EGP</td>
                      <td>
                        <span className={`status ${className}`}>{text}</span>
                      </td>
                      <td>{`${order.street} - ${order.buildingName}`}</td>
                      <td>
                        <Link to={`/adminDeliveryorderdetails/${order.id}`}>
                          <button className="btns-Admin">View</button>
                        </Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </section>
        </main>
      </div>
    </div>
  )
}

export default DeliveryPage
