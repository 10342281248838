import { useEffect, useState } from "react"
import { updateDoc, Timestamp, doc, deleteDoc } from "firebase/firestore"
import { db } from "../../../Firebase/config"
import Notify from "../Usenotifications"
import axios from "axios"
import { useNavigate } from "react-router-dom"
export const GetonepickuporderHook = (id, document) => {
  const [disable, setDisable] = useState(false)
  const [paid, setPaid] = useState("Not Ready")
  const navigate = useNavigate()

  const user = JSON.parse(localStorage.getItem("user"))

  const sendEmailNotification = async (ordernumber, userEmail, userName) => {
    try {
      const apiUrl = process.env.REACT_APP_FIREBASE_FUNCTION_LINK

      const emailData = {
        email: userEmail,
        orderId: ordernumber,
        userName: userName,
      }

      const response = await axios.post(apiUrl, emailData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      if (response.status === 200) {
        Notify("Customer Notified", "success")
      } else {
        console.error("Failed to send email:", response.data)
      }
    } catch (error) {
      console.error("Error sending email:", error)
    }
  }

  const handleReadyforPickup = async (
    orderid,
    userEmail,
    ordernumber,
    userName
  ) => {
    await updateDoc(doc(db, "PickupOrders", orderid), {
      Readyforpickup: true,
      processing: false,
      ReadyAt: Timestamp.now().toDate(),
    })
    await sendEmailNotification(ordernumber, userEmail, userName)
  }

  const handleReadyforPickupdetails = async (
    e,
    orderid,
    userEmail,
    ordernumber,
    userName
  ) => {
    if (e.target.value === "Ready")
      await updateDoc(doc(db, "PickupOrders", orderid), {
        Readyforpickup: true,
        processing: false,
        ReadyAt: Timestamp.now().toDate(),
      })
    await sendEmailNotification(ordernumber, userEmail, userName)

    setTimeout(() => {
      window.location.reload(false)
    }, 1000)
  }

  const handlePicked = async (orderid) => {
    await updateDoc(doc(db, "PickupOrders", orderid), {
      Picked: true,
      pickedAt: Timestamp.now().toDate(),
    })

    Notify("Order Picked Up", "success")
  }

  const handleDelete = async (e, orderid) => {
    e.preventDefault()
    if (user.email && user.email == process.env.REACT_APP_ADMIN_EMAIL) {
      await deleteDoc(doc(db, "PickupOrders", orderid))
      Notify("Order deleted", "success")
      setTimeout(() => {
        navigate("/adminallorders")
      }, 1500)
    } else {
      Notify("Only Admins can delete Orders ", "error")
    }
  }
  //Updated
  const handleCheckPickup = async (orderId) => {
    const today = new Date()
    const date = today.toDateString()
    const time = today.toLocaleTimeString()
    try {
      if (orderId) {
        await updateDoc(doc(db, "PickupOrders", orderId), {
          Checked: true,
          SeenAtDate: date,
          SeenAtTime: time,
        })
      }
    } catch (error) {
      console.error("Error updating item in Firestore:", error)
    }
  }
  //Updated
  useEffect(() => {
    if (document && document.ReadyAt) {
      setPaid("Ready")
      setDisable(true)
    }
  }, [document])

  return [
    handleReadyforPickupdetails,
    handleReadyforPickup,
    handleDelete,
    handlePicked,
    disable,
    paid,
    handleCheckPickup,
  ]
}
