import React from "react"
import { Button, Card } from "react-bootstrap"
import UsefetchCollection from "../Componentslogic/Usefetchcollection"
import { useState } from "react"
import { setDoc, doc, collection } from "firebase/firestore"
import { db } from "../../Firebase/config"
import Notify from "../Componentslogic/Usenotifications"
export const AddTime = () => {
  const [openingTime, setOpeningTime] = useState("")
  const [closingTime, setClosingTime] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!openingTime || !closingTime) {
      Notify("Please fill in both opening and closing times", "error")
      return
    }
    try {
      setDoc(doc(db, "restaurantTimes", "umJzZMf1P9pxdQdNs871"), {
        openingTime: openingTime,
        openHours: closingTime,
      })

      Notify("Times saved successfully!", "success")
    } catch (error) {
      console.error("Error saving times:", error)
    }
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="wrapper mb-3  ">
          <div>
            <label>
              <div className="flip-card__inner">
                <div className="title mb-3 mt-5">Add Time</div>
                <form className="flip-card__form">
                  <p className="m-0 p-0">
                    <b> Opening time </b>
                  </p>

                  <input
                    className="input-Cat px-2"
                    placeholder="Add Opening Time"
                    type="time"
                    onChange={(e) => setOpeningTime(e.target.value)}
                    value={openingTime}
                  />
                  <p className="m-0 p-0">
                    <b> Working Hours </b>
                  </p>
                  <input
                    onChange={(e) => setClosingTime(e.target.value)}
                    value={closingTime}
                    className="input-Cat  px-2"
                    placeholder="Add number of Hours"
                    type="number"
                  />

                  <div className="register">
                    <button
                      className="btns-Admin"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Set Time
                    </button>
                  </div>
                </form>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}
