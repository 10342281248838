import React from "react";
import { Container } from "react-bootstrap";
import { Editproduct } from "../../Components/Admin/Editproduct";
const Admineditproductpage = () => {
  return (
    <Container>
      <Editproduct />
    </Container>
  );
};
export default Admineditproductpage;
