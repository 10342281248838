import { useEffect, useState } from "react"
import { auth } from "../../../Firebase/config"
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import Notify from "../Usenotifications"

export const LoginHook = () => {
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [pressed, setpressed] = useState(false)

  const onchangeName = (e) => {
    setName(e.target.value)
  }
  const onchangePassword = (e) => {
    setPassword(e.target.value)
  }
  const onSubmit = async (e) => {
    e.preventDefault()

    setpressed(true)
    signInWithEmailAndPassword(auth, name, password)
      .then((userCredential) => {
        const user = userCredential.user

        Notify("Logging you in", "success")
        if (
          user.email === process.env.REACT_APP_ADMIN_EMAIL ||
          user.email === process.env.REACT_APP_STAFF_EMAIL
        ) {
          if (user.email === process.env.REACT_APP_ADMIN_EMAIL) {
            setTimeout(() => {
              navigate("/adminallorders")
            }, 1500)
          } else {
            console.log(user.email)
            console.log(process.env.REACT_APP_ADMIN_EMAIL)
            console.log(user.email === process.env.REACT_APP_ADMIN_EMAIL)
            setTimeout(() => {
              navigate("/StaffPage/DineIn")
            }, 1500)
          }
        } else {
          setTimeout(() => {
            navigate("/Menu")
          }, 1500)
        }
      })
      .catch((error) => {
        Notify("Incorrect email or password ", "error")
      })
    setpressed(false)
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        localStorage.setItem("user", JSON.stringify(user))
      } else {
        localStorage.removeItem("user")
      }
    })
  }, [])

  return [name, password, onchangeName, onchangePassword, onSubmit, pressed]
}
