import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { SidemenuComponent } from "../../Components/Admin/Sidemenu"
import { AddTime } from "../../Components/Admin/Adminset-time"
const AdminaddTimepage = () => {
  return (
    <Container fluid>
      <Row>
        <Col sm="2" xs="2" className="px-0">
          <SidemenuComponent />
        </Col>

        <Col sm="10" xs="12" className="px-0">
          <AddTime />
        </Col>
      </Row>
    </Container>
  )
}
export default AdminaddTimepage
