import React, { useState, useEffect } from "react"
import "../../Styles/PickupStatus.css"
import { Link } from "react-router-dom"
import { jsPDF } from "jspdf"
import logo from "../images/Katalyst.png"
import Back from "../images/back.svg"
import { collection, query, where, getDocs } from "firebase/firestore"
import { db } from "../../Firebase/config"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}
const OrderStatusPage = () => {
  const generatePDF = (Order) => {
    const doc = new jsPDF()
    const pageWidth = doc.internal.pageSize.width

    // Load your logo and position it at the top of the PDF
    const img = new Image()
    img.src = logo // Use the path to your logo image

    // Add image at the top center
    doc.addImage(img, "PNG", (pageWidth - 40) / 2, 10, 40, 40) // Centered horizontally

    // Set font size for Order ID, Name, and Email
    doc.setFontSize(16)

    // Adjusted Y-coordinates to reduce vertical space
    const textYStart = 55 // Start position for the first text element

    // Order ID centered
    doc.text(
      `Order-ID: ${Order?.orderNumber || "N/A"}`,
      pageWidth / 2,
      textYStart,
      { align: "center" }
    )

    // Name centered
    doc.text(
      `Name: ${Order?.userName || "N/A"}`,
      pageWidth / 2,
      textYStart + 20,
      { align: "center" }
    )

    // Email centered
    doc.text(
      `Email: ${Order?.userEmail || "N/A"}`,
      pageWidth / 2,
      textYStart + 40,
      { align: "center" }
    )

    // Horizontal divider
    doc.setLineWidth(0.5)
    doc.line(20, textYStart + 50, pageWidth - 20, textYStart + 50) // Line below Order details

    // Items section with smaller font
    doc.setFontSize(14)
    doc.text("Items:", pageWidth / 2, textYStart + 60, { align: "center" })

    // List the items in the order
    if (Array.isArray(Order?.cartItems) && Order.cartItems.length > 0) {
      Order.cartItems.forEach((item, index) => {
        doc.text(
          `${item.name} - ${item.Price} x ${item.CartTotalQuantity} EGP`,
          pageWidth / 2,
          textYStart + 70 + index * 10,
          { align: "center" }
        )
      })
    } else {
      doc.text("No items available", pageWidth / 2, textYStart + 70, {
        align: "center",
      })
    }

    // Notes section below items
    if (Order?.Notes) {
      doc.setLineWidth(0.2)
      doc.line(
        20,
        textYStart + 90 + Order.cartItems.length * 10,
        pageWidth - 20,
        textYStart + 90 + Order.cartItems.length * 10
      ) // Divider
      doc.text(
        `Notes: ${Order?.Notes}`,
        pageWidth / 2,
        textYStart + 100 + Order.cartItems.length * 10,
        { align: "center" }
      )
    }

    // Add order date and price at the bottom
    doc.setFontSize(16)
    const bottomY =
      textYStart +
      120 +
      (Order?.cartItems?.length ? Order.cartItems.length * 10 : 0)
    doc.text(`Order Date: ${Order?.orderDate || "N/A"}`, 30, bottomY)
    doc.text(
      `Price: ${Order?.orderAmount || "N/A"} EGP`,
      pageWidth - 80,
      bottomY
    )

    // Save the PDF
    doc.save(`Order_${Order?.orderNumber}.pdf`)
  }

  const user = JSON.parse(localStorage.getItem("user"))
  const userID = user?.userID
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true)
      try {
        const ordersRef = collection(db, "PickupOrders")
        const q = query(ordersRef, where("userID", "==", userID))

        const querySnapshot = await getDocs(q)
        const allOrders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))

        // Filter orders that are not ready for pickup
        const filteredOrders = allOrders.filter(
          (order) => order.Picked === false
        )
        setOrders(filteredOrders)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
    fetchOrders()
  }, [userID])

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <h3>Loading orders...</h3>
      </div>
    )
  }

  if (orders.length === 0) {
    localStorage.removeItem("PickuporderID")
    return (
      <div>
        <div>
          <h3>No orders found</h3>
          <p>You have no pending Pick-up orders .</p>
          <Link to="/PDR" style={{ textDecoration: "none" }}>
            <button className="startbtns px-3 mt-3">
              <span className="buttonText">Back</span>
            </button>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="d-flex align-items-center ">
        <Link to="/PDR" style={{ textDecoration: "none" }}>
          <img className="icon-TIT " src={Back} alt="go-back"></img>
        </Link>
      </div>

      <Slider {...sliderSettings}>
        {orders.map((Order) => (
          <>
            <div className="d-flex justify-content-center">
              <p
                className="extract px-3 mt-3 "
                onClick={() => generatePDF(Order)}
              >
                Download PDF
              </p>
            </div>
            <div className="order-status-page" key={Order.id}>
              <div className="order-status-card">
                <div className="order-header">
                  <div className="restaurant-info">
                    <div>
                      <div className="order-price ">
                        <h3>Order ID: {Order?.orderNumber}</h3>
                        <div>
                          <h4>{Order?.orderAmount} EGP</h4>
                        </div>
                      </div>

                      <ul className="cart-items">
                        {Order?.cartItems.map((item, index) => (
                          <li key={index} className="cart-item">
                            <span>{item.name}</span>
                            <span>
                              {item.Price} x {item.CartTotalQuantity} EGP
                            </span>
                          </li>
                        ))}
                      </ul>
                      {Order?.Notes && (
                        <div>
                          <div className="Horizontal-divider"></div>
                          <p>
                            <b>Notes:</b> {Order.Notes}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="order-progress">
                  <div className="progress-bar-container">
                    <div className="progress-bar">
                      <div
                        className={`progress ${
                          Order?.Readyforpickup === true ? "progress-ready" : ""
                        }`}
                      ></div>
                    </div>
                    <div className="progress-states">
                      <p>Order Placed</p>
                      <p>Ready for Pickup</p>
                    </div>
                  </div>
                </div>

                <div className="user-info">
                  {Order ? (
                    Order.Readyforpickup ? (
                      <div>
                        {" "}
                        <h4> {Order?.userName}!! Your Order is Ready </h4>
                        <p className="mt-3">
                          {" "}
                          <strong>Order Date: </strong> {Order.orderDate}
                        </p>
                      </div>
                    ) : (
                      <div>
                        {" "}
                        <h4>{Order?.userName}</h4>
                        <p>
                          We will send you an email at{" "}
                          <strong> {Order?.userEmail} </strong> once order is
                          ready{" "}
                        </p>{" "}
                        <p className="mt-3">
                          {" "}
                          <strong>Order Date: </strong> {Order.orderDate}
                        </p>
                        <p className="mt-3">
                          {" "}
                          <strong>Order Time: </strong> {Order.orderTime}
                        </p>
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </>
        ))}
      </Slider>
    </>
  )
}

export default OrderStatusPage
