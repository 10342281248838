import { useState } from "react"
import { useDispatch } from "react-redux"
import { collection, query, where, getDocs } from "firebase/firestore"
import { db } from "../../../Firebase/config"
import Notify from "../Usenotifications"
import {
  ADD_TO_CART,
  APPLY_PROMO_CODE,
  CLEAR_CART,
  DECREASE_CART_QUANTITY,
  REMOVE_FROM_CART,
} from "../../../redux/actions/CartSlice"

export const GetusercartHook = () => {
  const convertToDate = (dateString) => {
    const [year, month, day] = dateString.split("-")
    return new Date(year, month - 1, day)
  }
  const [promo, setPromo] = useState("")
  const [pressedClear, setpressedClear] = useState("")
  const dispatch = useDispatch()

  const handleClearcart = () => {
    setpressedClear(true)

    try {
      dispatch(CLEAR_CART())
      Notify("Cart cleared", "success")
      setpressedClear(false)
    } catch {
      Notify("error occurred", "error")
    }

    localStorage.removeItem("Cartproducts")
    localStorage.removeItem("discounted_price")
  }
  const handleDeleteitem = async (item) => {
    await dispatch(REMOVE_FROM_CART(item))
  }

  const handlepromo = async (promo) => {
    if (promo !== "") {
      try {
        const collectionRef = collection(db, "Promocodes")
        const q = query(collectionRef, where("name", "==", promo))
        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0].data()
          const currentDate = new Date()
          const expireDate = convertToDate(docData.expire)
          if (expireDate > currentDate) {
            dispatch(APPLY_PROMO_CODE(docData.discount))
            Notify("Promocode applied", "success")
          } else {
            Notify("Promocode is expired", "error")
          }
        } else {
          Notify("Promocode is invalid", "error")
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      Notify("Please enter discount code", "error")
    }
  }

  const onchangepromo = (e) => {
    setPromo(e.target.value)
  }

  const handleQuantityAdd = async (item) => {
    await dispatch(ADD_TO_CART(item))
  }

  const handleQuantityDecrease = async (item) => {
    await dispatch(DECREASE_CART_QUANTITY(item))
  }

  return [
    handleClearcart,
    pressedClear,
    handleDeleteitem,
    handlepromo,
    onchangepromo,
    promo,
    handleQuantityAdd,
    handleQuantityDecrease,
  ]
}
