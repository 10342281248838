import { useEffect, useState } from "react"
import { Timestamp, doc, updateDoc, deleteDoc } from "firebase/firestore"
import { db } from "../../../Firebase/config"
import Notify from "../Usenotifications"
import { useNavigate } from "react-router-dom"
export const GetDineinorderHook = (id, document) => {
  const [disable, setDisable] = useState(false)
  const [paid, setPaid] = useState("Not Paid")
  const navigate = useNavigate()

  const user = JSON.parse(localStorage.getItem("user"))

  const handlePaid = async (orderId) => {
    if (orderId) {
      await updateDoc(doc(db, "Orders", orderId), {
        Paid: true,
        paidAT: Timestamp.now().toDate(),
      })

      Notify("Order is Paid", "success")
    }
  }

  const handlePaiddetails = async (e, orderid) => {
    if (e.target.value === "Paid") {
      console.log("done")
      await updateDoc(doc(db, "Orders", orderid), {
        Paid: true,
        paidAT: Timestamp.now().toDate(),
      })
    }
    setTimeout(() => {
      window.location.reload(false)
    }, 1000)
  }

  const handleDelete = async (e, orderid) => {
    e.preventDefault()
    if (user.email && user.email == process.env.REACT_APP_ADMIN_EMAIL) {
      await deleteDoc(doc(db, "Orders", orderid))
      Notify("Order deleted", "success")
      setTimeout(() => {
        navigate("/adminallorders")
      }, 1500)
    } else {
      Notify("Only Admins can delete Orders ", "error")
    }
  }

  const handleSeen = async (productId, order) => {
    try {
      if (!order) {
        console.log("Order document not found!")
        return
      }

      // Clone cartItems deeply to ensure immutability
      let updatedCartItems = order.cartItems.map((item) => ({ ...item }))

      // Separate updated and non-updated items
      const updatedItemIndex = updatedCartItems.findIndex(
        (item) => item.id === productId && item.updatedItem
      )

      if (updatedItemIndex >= 0) {
        const updatedItem = updatedCartItems[updatedItemIndex]

        // Remove the updated item from the array
        updatedCartItems.splice(updatedItemIndex, 1)

        // Check if the item already exists in the non-updated list
        const nonUpdatedItemIndex = updatedCartItems.findIndex(
          (item) => item.id === productId && !item.updatedItem
        )

        if (nonUpdatedItemIndex >= 0) {
          // If it exists, increase the quantity
          updatedCartItems[nonUpdatedItemIndex] = {
            ...updatedCartItems[nonUpdatedItemIndex],
            CartTotalQuantity:
              updatedCartItems[nonUpdatedItemIndex].CartTotalQuantity +
              updatedItem.CartTotalQuantity,
          }
        } else {
          // If it doesn't exist, add it as a new non-updated item
          updatedCartItems.push({ ...updatedItem, updatedItem: false })
        }
      }

      // Update Firestore with the modified cartItems array
      await updateDoc(doc(db, "Orders", order.id), {
        updated: false,
        cartItems: updatedCartItems, // Update the cartItems field
      })
    } catch (error) {
      console.error("Error updating item in Firestore:", error)
    }
  }
  //Updated
  const handleCheck = async (orderId) => {
    const today = new Date()
    const date = today.toDateString()
    const time = today.toLocaleTimeString()
    try {
      if (orderId) {
        await updateDoc(doc(db, "Orders", orderId), {
          Checked: true,
          SeenAtDate: date,
          SeenAtTime: time,
        })
      }
    } catch (error) {
      console.error("Error updating item in Firestore:", error)
    }
  }
  //Updated
  useEffect(() => {
    if (document && document.Paid) {
      setPaid("Paid")
      setDisable(true)
    }
  }, [document])

  return [
    handlePaid,
    handlePaiddetails,
    handleDelete,
    handleSeen,
    handleCheck,
    paid,
    disable,
  ]
}
