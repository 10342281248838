import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GetallordersHook } from "../Componentslogic/ordersLogic/GetallordersHook"
import { Link, useNavigate } from "react-router-dom"
import { motion as m } from "framer-motion"
import { GetDineinorderHook } from "../Componentslogic/ordersLogic/GetsoneorderHook"
import {
  FILTER_BY_SEARCH_TABLES,
  SelectFilteredTables,
} from "../../redux/actions/FilterProducts"
import "../../Styles/ALLorders.css"
import Tick from "../images/Tick.svg"
import UsefetchcollectionTime from "../Componentslogic/Usefetchcollectiontime"
import Spinner from "react-bootstrap/Spinner"

import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore"
import { db } from "../../Firebase/config"
import { SET_NEW_DINE_IN_ORDERS } from "../../redux/actions/OrdersStaffslice"
export const AllordersStaff = () => {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem("user"))
  useEffect(() => {
    if (user.email !== process.env.REACT_APP_ADMIN_EMAIL || user.email !== process.env.REACT_APP_STAFF_EMAIL) {
      localStorage.removeItem("user")
      navigate("/Login")
    }
  }, [])
  
  const {
    data: orderData,
    loadMore,
    loading,
    hasMore,
  } = UsefetchcollectionTime("Orders")
  const filteredTables = useSelector(SelectFilteredTables)
  const [handlechange, tableSearch] = GetallordersHook()
  const [ordersWithNotes, setOrdersWithNotes] = useState([])
  const [handlePaid, , , handleSeen, handleCheck, ,] = GetDineinorderHook()

  const [searchData, setSearchData] = useState([])
  const [userNotes, setUserNotes] = useState({})
  const [isEnabled, setIsEnabled] = useState(false)
  const timeoutRef = useRef(null)
  const dispatch = useDispatch()

  const fetchUserNotes = async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId)
      const userDoc = await getDoc(userDocRef)
      if (userDoc.exists()) {
        return userDoc.data().AdminNote
      } else {
        return
      }
    } catch (error) {
      console.error("Error fetching user notes:", error)
      return "Error loading notes"
    }
  }

  const fetchOrdersWithNotes = async () => {
    try {
      const enhancedOrders = await Promise.all(
        orderData.map(async (order) => {
          const notes = await fetchUserNotes(order.userID)
          return { ...order, userNotes: notes }
        })
      )
      setOrdersWithNotes(enhancedOrders)
    } catch (error) {
      console.error("Error fetching orders with notes:", error)
    }
  }
  useEffect(() => {
    if (orderData.length > 0) {
      fetchOrdersWithNotes()
    }
  }, [orderData])

  useEffect(() => {
    const fetchSearchData = async () => {
      if (tableSearch !== "") {
        try {
          const docRef = collection(db, "Orders")
          const q = query(
            docRef,
            where("tablenumber", "==", Number(tableSearch))
          )
          const querySnapshot = await getDocs(q)
          const fetchedData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          setSearchData(fetchedData)
        } catch (error) {
          console.error("Error fetching search data", error)
        }
      } else {
        setSearchData([])
      }
    }
    timeoutRef.current = setTimeout(() => {
      fetchSearchData(tableSearch)
    }, 200)

    // Cleanup timeout on component unmount or re-render
    return () => clearTimeout(timeoutRef.current)
  }, [tableSearch])

  useEffect(() => {
    try {
      if (searchData.length > 0) {
        dispatch(
          FILTER_BY_SEARCH_TABLES({ tableSearch, orderData: searchData })
        )
      } else {
        if (ordersWithNotes.length > 0) {
          dispatch(
            FILTER_BY_SEARCH_TABLES({
              tableSearch: "",
              orderData: ordersWithNotes,
            })
          )
        }
      }
    } catch (error) {
      console.error("Error filtering tables", error)
    }
  }, [searchData, tableSearch, orderData, dispatch, ordersWithNotes])

  const newDineInOrders = orderData.filter((order) => !order.Checked).length
  useEffect(() => {
    dispatch(SET_NEW_DINE_IN_ORDERS(newDineInOrders))
  }, [newDineInOrders, orderData, dispatch])

  return (
    <div>
      <div className="header-section_Staff">
        <m.h2
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          All orders : {orderData.length}
        </m.h2>
        <m.div
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <input
            className="searchInput"
            type="text"
            placeholder="Search...."
            value={tableSearch}
            onChange={(e) => handlechange(e)}
          ></input>
        </m.div>
      </div>

      <div className="Review-Container_orders_Staff">
        {filteredTables.length > 0 ? (
          filteredTables.map((item, index) => {
            return (
              <m.div
                className="Review-Card-F "
                key={index}
                initial={{ opacity: 0, x: 15 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <div className="mx-3">
                  <h1 className="table-number">
                    Table number : {item.tablenumber} {""}{" "}
                    {!item.Checked && (
                      <span
                        className="badge bg-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCheck(item.id)}
                      >
                        New
                      </span>
                    )}
                  </h1>

                  <p>
                    {!item.Paid && item.CheckRequest && (
                      <m.div
                        initial={{ opacity: 0, x: 15 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                      >
                        <div className="Badge-cont">
                          <p
                            className="status-badge-checkReq  m-0"
                            onClick={() => handlePaid(item.id)}
                          >
                            <strong>Check Requested</strong>
                          </p>
                        </div>
                      </m.div>
                    )}
                  </p>
                  <p>
                    {!item.Paid && !item.CheckRequest && item.updated && (
                      <m.div
                        initial={{ opacity: 0, x: 15 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                      >
                        <div className="Badge-cont">
                          <p className="status-badge-OrderUpdate m-0">
                            <strong>Order Updated</strong>
                          </p>
                        </div>
                      </m.div>
                    )}
                  </p>
                  <p>
                    {item.Paid && (
                      <div>
                        <div className="Badge-cont">
                          <p className="status-badge-Paid m-0">
                            <strong>Paid</strong>
                          </p>
                        </div>
                      </div>
                    )}
                  </p>

                  <div>
                    <div className="cart-items-container">
                      {item.cartItems.some(
                        (cartItem) => cartItem.updatedItem
                      ) && (
                        <div className="updated-items-section">
                          <p>
                            <b>Updated Items </b>
                          </p>
                          {item.cartItems
                            .filter((cartItem) => cartItem.updatedItem)
                            .map((updatedItem, index) => (
                              <div
                                key={`updated-${index}`}
                                className="cart-item-card"
                              >
                                <div className="item-header d-flex align-items-center">
                                  <p className="item-name">
                                    {updatedItem.name}
                                  </p>
                                  <p className="item-quantity mx-2">
                                    <b>Qty: {updatedItem.CartTotalQuantity}</b>
                                  </p>

                                  {updatedItem.updatedNotes ? (
                                    <p className="mx-2  note-text">
                                      {" "}
                                      <b>Note: </b>
                                      {updatedItem.updatedNotes}{" "}
                                    </p>
                                  ) : null}

                                  <div
                                    onClick={() =>
                                      handleSeen(updatedItem.id, item)
                                    }
                                  >
                                    <img
                                      src={Tick}
                                      className="icon"
                                      alt="tick"
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          <div className="Horizontal-divider"></div>
                        </div>
                      )}

                      <p>
                        <b>Order Items:</b>
                      </p>

                      {item.cartItems
                        .filter((cartItem) => !cartItem.updatedItem)
                        .map((nonUpdatedItem, index) => (
                          <div key={index} className="cart-item-card">
                            <div className="item-header d-flex align-items-center ">
                              <p className="item-name">{nonUpdatedItem.name}</p>
                              <p className="item-quantity mx-2">
                                <b> Qty: {nonUpdatedItem.CartTotalQuantity}</b>
                              </p>
                            </div>
                          </div>
                        ))}

                      {item.Notes && (
                        <div className="notes-section">
                          <div className="Horizontal-divider"></div>
                          <p>
                            <b>Notes:</b> {item.Notes}
                          </p>
                        </div>
                      )}

                      {item.userNotes && (
                        <div className="notes-section">
                          <div className="Horizontal-divider"></div>
                          <p>
                            <b>Admin Notes:</b> {item.userNotes}
                          </p>
                        </div>
                      )}

                      <p>
                        {item.Promodiscount > 0 && (
                          <m.div
                            initial={{ opacity: 0, x: 15 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                          >
                            <div className="Horizontal-divider"></div>
                            <div className="Badge-cont">
                              <p
                                className="status-badge-checkReq  m-0"
                                style={{
                                  backgroundColor: "#009688",
                                  pointerEvents: "none",
                                  borderRadius: "5px",
                                }}
                              >
                                <strong>
                                  discount: {item.Promodiscount} %
                                </strong>
                              </p>
                            </div>
                          </m.div>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <Link
                    to={`/adminorderdetails/${item.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button className="btns-Admin mt-3">
                      View order details
                    </button>
                  </Link>
                  {item.CheckRequest || item.Paid ? (
                    <p className="mt-3">
                      <b>Total: {item.orderAmount}</b>
                    </p>
                  ) : null}
                </div>
              </m.div>
            )
          })
        ) : (
          <h2 style={{ color: "black" }}>no orders availables</h2>
        )}
        {loading && (
          <Spinner animation="grow" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}

        {hasMore && !loading && (
          <div className="d-flex justify-content-center align-items-center">
            <button class="btn-Load" onClick={() => loadMore()}>
              Load More
            </button>{" "}
          </div>
        )}
      </div>
    </div>
  )
}
