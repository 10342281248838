import React, { useState, useEffect } from "react"
import "../../Styles/Reservation.css"
import { doc, updateDoc, deleteDoc, increment } from "firebase/firestore"
import { db } from "../../Firebase/config"
import UsefetchcollectionTime from "../Componentslogic/Usefetchcollectiontime"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import Tick from "../images/Done.svg"
import Notify from "../Componentslogic/Usenotifications"
import {
  FILTER_BY_ORDER_NUMBERS,
  FILTER_BY_ORDER_NUMBERS_DELIVERY,
  SelectFilterednumbers,
  SelectFilterOrdernumbersDelivery,
} from "../../redux/actions/FilterProducts"
import { collection, query, where, onSnapshot } from "firebase/firestore"
import { useDispatch, useSelector } from "react-redux"
import { GetoneDeliveryorderHook } from "../Componentslogic/ordersLogic/Getonedeliveryorder"

const DriverPage = () => {
  const dispatch = useDispatch()
  const {
    data: recentOrders,
    loadMore,
    loading,
    hasMore,
  } = UsefetchcollectionTime("DeliveryOrders")

  const DeliveryorderData = useSelector(SelectFilterOrdernumbersDelivery)
  const [
    handleDonepreparing,
    handleDelete,
    disable,
    paid,
    handleOntheway,
    handleCheckDelivery,
    handleDelivered,
  ] = GetoneDeliveryorderHook()

  useEffect(() => {
    if (recentOrders.length > 0) {
      const donePreparingOrders = recentOrders.filter(
        (order) => order.Donepreparing === true
      )

      // Dispatch the filtered data to Redux
      dispatch(
        FILTER_BY_ORDER_NUMBERS_DELIVERY({
          OrderSearch: "",
          DeliveryorderData: donePreparingOrders,
        })
      )
    }
  }, [recentOrders, dispatch])

  if (loading) {
    return <p>Loading...</p>
  }

  return (
    <div>
      <h1 className="d-flex justify-content-center mt-3">Delivery Orders</h1>
      <div className="Review-Container_orders_Staff ">
        {DeliveryorderData.length > 0 ? (
          DeliveryorderData.map((item, index) => (
            <div className="card" style={{ width: "100%" }} key={index}>
              <div className="mx-3">
                <div className="d-flex justify-content-center align-items-center">
                  <h1>Order ID: {item.orderNumber}</h1>
                  {item.delivered && (
                    <img className="icon mx-2" src={Tick} alt="tick" />
                  )}
                </div>

                <div className="cart-items-container">
                  <>
                    <p>
                      <b>Name:</b> {item.userName}
                    </p>
                    <p>
                      <b>Phone:</b> {item.addresses?.DeliveryphoneNumber}
                    </p>
                    <p>
                      <b>Street:</b> {item.addresses?.street}
                    </p>
                    <div className=" d-flex justify-content-between ">
                      <p style={{ textAlign: "center" }}>
                        <b>Building Name:</b>
                        <br />
                        {item.addresses?.buildingName}
                      </p>
                      <p style={{ textAlign: "center" }}>
                        <b>Apt:</b> <br /> {item.addresses?.aptNo}
                      </p>

                      <p style={{ textAlign: "center" }}>
                        <b>Floor:</b> <br />
                        {item.addresses?.floor}
                      </p>
                    </div>
                  </>

                  {item.preparing && item.Donepreparing && !item.ontheWay ? (
                    <div
                      className="d-flex justify-content-center flex-column mt-4 mb-4"
                      style={{ gap: "10%" }}
                    >
                      <button
                        className="btns-Admin mb-4"
                        onClick={() => handleOntheway(item.id)}
                      >
                        Confirm
                      </button>
                    </div>
                  ) : (
                    !item.delivered && (
                      <div
                        className="d-flex justify-content-center flex-column mt-4 mb-4"
                        style={{ gap: "10%" }}
                      >
                        <button
                          className="btns mb-4"
                          style={{ backgroundColor: "#4caf50" }}
                          onClick={() => handleDelivered(item.id)}
                        >
                          Delivered
                        </button>
                      </div>
                    )
                  )}
                  <div
                    className="d-flex justify-content-center flex-column mt-4 mb-4"
                    style={{ gap: "10%" }}
                  >
                    <Link to={`/adminDeliveryorderdetails/${item.id}`}>
                      <button className="btns-Admin ">View details</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No orders available.</p>
        )}
      </div>
    </div>
  )
}

export default DriverPage
