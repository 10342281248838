import {
  collection,
  onSnapshot,
  orderBy,
  query,
  limit,
  getDocs,
  startAfter,
} from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../Firebase/config"
const UsefetchCollectionAdmin = (collection_name) => {
  const [data, setData] = useState([]) // Holds the fetched data for the current page
  const [lastDoc, setLastDoc] = useState(null) // Tracks the last document fetched for pagination
  const [loading, setLoading] = useState(false) // Loading state
  const [currentPage, setCurrentPage] = useState(1) // Track the current page
  const [hasMore, setHasMore] = useState(true) // Tracks if more data is available
  const pageSize = 10
  // Fetch data for the current page
  const getCollectionForPage = async (page) => {
    setLoading(true)
    try {
      const docRef = collection(db, collection_name)
      let q

      if (page === 1) {
        // First page query
        q = query(docRef, orderBy("createdAt", "desc"), limit(pageSize))
      } else {
        // For subsequent pages, start after the last fetched document
        if (lastDoc) {
          q = query(
            docRef,
            orderBy("createdAt", "desc"),
            startAfter(lastDoc),
            limit(pageSize)
          )
        } else {
          // No more documents to fetch
          setHasMore(false)
          return
        }
      }

      const snapshot = await getDocs(q)
      const fetchedData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      if (page === 1) {
        setData(fetchedData) // Set data for the first page
      } else {
        setData((prevData) => [...prevData, ...fetchedData]) // Append new data for other pages
      }

      setLastDoc(snapshot.docs[snapshot.docs.length - 1]) // Update the last document for pagination
      setHasMore(snapshot.docs.length === pageSize) // If the fetched docs are less than page size, no more data
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCollectionForPage(currentPage) // Fetch data when the page changes
  }, [currentPage])

  // Go to the next page
  const nextPage = () => {
    if (hasMore) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  // Go to the previous page
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1)
      setLastDoc(null) // Reset last document to refetch previous data
    }
  }

  return {
    data,
    loading,
    currentPage,
    nextPage,
    previousPage,
    hasMore,
  }
}

export default UsefetchCollectionAdmin
