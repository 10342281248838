import React from "react"
import { Container, Col } from "react-bootstrap"
import "../../Styles/Adminreviews.css"
import StaffComponent from "../../Components/Admin/StaffPage"
import TablesLayoutStaff from "../../Components/Admin/StaffReservation"
const StaffReservationPage = () => {
  return (
    <Container fluid className="px-0">
      <Col sm="12" xs="12" className="px-0">
        <StaffComponent />
      </Col>
      <Col sm="12" xs="12" className="px-0">
        <TablesLayoutStaff />
      </Col>
    </Container>
  )
}
export default StaffReservationPage
