import { combineReducers } from "redux"
import authReducer from "../actions/authActions"
import filterReducer from "../actions/FilterProducts"
import CartReducer from "../actions/CartSlice"
import OrdersReducer from "../actions/OrdersStaffslice"
export default combineReducers({
  usersReducer: authReducer,
  cart: CartReducer,
  filter: filterReducer,
  dineInOrders: OrdersReducer,
})
