import { useState } from "react"

export const GetallordersHook = () => {
  const [tableSearch, setTablesearch] = useState("")

  const handlechange = (e) => {
    setTablesearch(e.target.value)
  }

  return [handlechange, tableSearch]
}
