import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { db } from "../Firebase/config" // Ensure Firebase config is correct
import { collection, query, where, onSnapshot } from "firebase/firestore"
import { SET_NEW_DINE_IN_ORDERS } from "../redux/actions/OrdersStaffslice"
import sound from "../Components/images/Sound.mp3"

const StaffDineINListener = ({ audioRef, isSoundEnabled }) => {
  const previousOrderCountRef = useRef(0)
  const dispatch = useDispatch()

  useEffect(() => {
    const q = query(collection(db, "Orders"), where("Checked", "==", false))

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newOrdersCount = snapshot.docs.length

      dispatch(SET_NEW_DINE_IN_ORDERS(newOrdersCount))
      if (isSoundEnabled && newOrdersCount > previousOrderCountRef.current) {
        audioRef.current.play().catch((error) => {
          console.error("Audio playback failed:", error)
        })
      }
      previousOrderCountRef.current = newOrdersCount
    })

    // Cleanup listener on component unmount
    return () => unsubscribe()
  }, [dispatch, isSoundEnabled, audioRef])

  return null
}

export default StaffDineINListener
