import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Adminmenucard } from "./Adminmenucard"
import { motion as m } from "framer-motion"
import UsefetchCollectionNonRealtime from "../Componentslogic/UsefetchcollectionNonrealtime"
import {
  FILTER_BY_SEARCH_ADMIN,
  SelectFilteredAdmin,
} from "../../redux/actions/FilterProducts"

const Adminmenucontainer = () => {
  const { data, loading, error } = UsefetchCollectionNonRealtime("Products")
  const [searchword, setSearchword] = useState("")
  const filteredProducts = useSelector(SelectFilteredAdmin)
  const dispatch = useDispatch()

  const handlechange = (e) => {
    setSearchword(e.target.value)
  }

  useEffect(() => {
    if (data) {
      dispatch(FILTER_BY_SEARCH_ADMIN({ searchword, data }))
    }
  }, [searchword, data, dispatch])
  return (
    <m.div
      initial={{ opacity: 0, x: 15 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}
    >
      {filteredProducts ? (
        filteredProducts.length !== 0 ? (
          <m.div className="header-section_Management mb-5">
            <m.h2
              className=" title  "
              initial={{ opacity: 0, x: 15 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              Products Total: {filteredProducts.length}
            </m.h2>
            <input
              className="searchInput"
              value={searchword}
              onChange={handlechange}
              type="text"
              placeholder="Search...."
            ></input>
          </m.div>
        ) : null
      ) : null}
      <div className="Review-Container_orders_Management">
        {filteredProducts ? (
          filteredProducts ? (
            filteredProducts.map((product, index) => (
              <Adminmenucard key={index} products={product} />
            ))
          ) : (
            <h3>No data to view</h3>
          )
        ) : null}
      </div>
    </m.div>
  )
}

export default Adminmenucontainer
