import React, { useState, useRef, useEffect } from "react"
import { NavLink, Link } from "react-router-dom"
import users from "../images/Logout.svg"
import Logo from "../images/Logo_new.svg"
import menu from "../images/Lottie-anim/menu.svg"
import { signOut } from "firebase/auth"
import { auth } from "../../Firebase/config"
import { useNavigate } from "react-router-dom"
import Notify from "../Componentslogic/Usenotifications"
import Lottie from "lottie-react"
import menuAnimationData from "../images/Lottie-anim/menuV2.json"
import "../../Styles/SideMenuStyles.css"
export const SidemenuComponent = () => {
  const [Active, Setactive] = useState(false)
  const navigate = useNavigate()
  const lottieRef = useRef(null)
  const Logout = () => {
    signOut(auth)
      .then(() => {
        Notify("Signed out", "success")
        navigate("/login")
        localStorage.removeItem("user")
      })
      .catch((error) => {
        // An error happened.
      })
  }
  const [isOpen, setIsOpen] = useState(false)

  const [isInventoryOpen, setIsInventoryOpen] = useState(true)

  const [isOrdersOpen, setOrdersOpen] = useState(true)

  const [EngagementsOpen, setEngagementsOpen] = useState(true)

  const [DriversOpen, setDriversOpen] = useState(true)

  const [direction, setDirection] = useState(1)

  const toggleInventory = () => {
    setIsInventoryOpen(!isInventoryOpen)
    localStorage.setItem("isInventoryOpen", isInventoryOpen)
  }

  const toggleOrders = () => {
    setOrdersOpen(!isOrdersOpen)
    localStorage.setItem("Ordersopen", isOrdersOpen)
  }

  const toggleEngagements = () => {
    setEngagementsOpen(!EngagementsOpen)
    localStorage.setItem("EngagementsOpen", EngagementsOpen)
  }

  const toggleDrivers = () => {
    setDriversOpen(!DriversOpen)
    localStorage.setItem("Driversopen", DriversOpen)
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen)
    setDirection(direction === 1 ? -1 : 1)
    if (lottieRef.current) {
      lottieRef.current.setDirection(direction)
      lottieRef.current.setSpeed(4)
      lottieRef.current.play()
    }
  }

  const activeLink = ({ isActive }) => (isActive ? "Active" : "")

  useEffect(() => {
    // Reset the animation to start at the "hamburger" position when the component first mounts
    if (lottieRef.current) {
      lottieRef.current.goToAndStop(0, true) // Make sure it starts from the first frame
    }
  }, [])

  return (
    <>
      <div onClick={toggleMenu} className="hamburger-icon">
        <Lottie
          lottieRef={lottieRef}
          animationData={menuAnimationData}
          loop={false}
          style={{ width: 50, height: 50, cursor: "pointer" }}
        />
      </div>
      <div className={`sidemenu ${isOpen ? "open" : ""}`}>
        <>
          <div className="d-flex justify-content-center">
            <img className="logo mb-3" alt="Katalyst" src={Logo} />
          </div>

          <div className="section-title">MENU</div>

          <NavLink
            to="/adminOverview"
            style={{ textDecoration: "none", color: "black" }}
            className={activeLink}
          >
            <p> Overview </p>
          </NavLink>
          <div onClick={toggleOrders}>
            <Link style={{ textDecoration: "none", color: "black" }}>
              <p>Orders </p>
            </Link>
          </div>

          {localStorage.getItem("Ordersopen") === "true" && (
            <div className="submenu">
              <NavLink
                to="/adminallorders"
                style={{ textDecoration: "none", color: "black" }}
                className={activeLink}
              >
                <p> Dine-In orders</p>
              </NavLink>

              <NavLink
                to="/adminallpickuporders"
                style={{ textDecoration: "none", color: "black" }}
                className={activeLink}
              >
                <p> Pick-up orders</p>
              </NavLink>

              <NavLink
                to="/Delivery-Orders"
                style={{ textDecoration: "none", color: "black" }}
                className={activeLink}
              >
                <p>Delivery Orders</p>
              </NavLink>
            </div>
          )}
          <div onClick={toggleEngagements}>
            <Link style={{ textDecoration: "none", color: "black" }}>
              <p>Engagements </p>
            </Link>
          </div>
          {localStorage.getItem("EngagementsOpen") === "true" && (
            <div className="submenu">
              <NavLink
                to="/allreviews"
                style={{ textDecoration: "none", color: "black" }}
                className={activeLink}
              >
                <p> Reviews</p>
              </NavLink>

              <NavLink
                to="/adminaddcoupon"
                style={{ textDecoration: "none", color: "black" }}
                className={activeLink}
              >
                <p>Promocodes</p>
              </NavLink>
            </div>
          )}
          <div onClick={toggleInventory}>
            <Link style={{ textDecoration: "none", color: "black" }}>
              <p>Menu Management </p>
            </Link>
          </div>

          {localStorage.getItem("isInventoryOpen") === "true" && (
            <div className="submenu">
              <NavLink className={activeLink} to="/adminaddproduct">
                {" "}
                <p>Add product </p>
              </NavLink>
              <NavLink className={activeLink} to="/adminaddcategory">
                {" "}
                <p>Add category </p>
              </NavLink>
              <NavLink className={activeLink} to="/adminmanagement">
                {" "}
                <p> All Products</p>
              </NavLink>
              <NavLink className={activeLink} to="/adminNotify">
                {" "}
                <p>Notification</p>
              </NavLink>
            </div>
          )}

          <NavLink
            to="/adminallusers"
            style={{ textDecoration: "none", color: "black" }}
            className={activeLink}
          >
            <p> All Users</p>
          </NavLink>

          <div onClick={toggleDrivers}>
            <Link style={{ textDecoration: "none", color: "black" }}>
              <p>Drivers </p>
            </Link>
          </div>

          {localStorage.getItem("Driversopen") === "true" && (
            <div className="submenu">
              <NavLink
                to="/AllDrivers"
                style={{ textDecoration: "none", color: "black" }}
                className={activeLink}
              >
                <p> Drivers </p>
              </NavLink>

              <NavLink
                to="/DriverSignUp"
                style={{ textDecoration: "none", color: "black" }}
                className={activeLink}
              >
                <p>Add Driver</p>
              </NavLink>
            </div>
          )}

          <NavLink
            to="/adminSettime"
            style={{ textDecoration: "none", color: "black" }}
            className={activeLink}
          >
            <div className="">
              <div className="">
                <p>Set-Time</p>
              </div>
            </div>
          </NavLink>

          <NavLink
            to="/Reservation"
            style={{ textDecoration: "none", color: "black" }}
            className={activeLink}
          >
            <div className="">
              <div className="">
                <p>Reservations</p>
              </div>
            </div>
          </NavLink>

          <div className="logout-section">
            <div className="d-flex align-items-center" onClick={() => Logout()}>
              <img className="icon mb-3" src={users} alt="Manage" />
              <p className=" btns-Admin " style={{ color: "white" }}>
                <b>Logout</b>
              </p>
            </div>
          </div>
        </>
      </div>
    </>
  )
}
;<div className="d-flex justify-content-center">
  <img className="logo mb-3" alt="Katalyst" src={Logo} />
</div>
