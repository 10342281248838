import React from "react"
import { Container } from "react-bootstrap"
import "../../Styles/Starstyle.css"
import OrderStatusPage from "../../Components/Thankyou/StatusofPickupOrder"
import DeliveryStatusPage from "../../Components/Thankyou/StatusofDelivery"
const DeliveryorderstatusPage = () => {
  return (
    <div>
      <Container fluid className="review-container">
        <div className="cont-status">
          <DeliveryStatusPage />
        </div>
      </Container>
    </div>
  )
}
export default DeliveryorderstatusPage
