import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import Logo from "../images/Logoshape-3.png"
import "../../Styles/Homestyle.css"
import { motion as m } from "framer-motion"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Timestamp, doc, updateDoc } from "firebase/firestore"
import { db } from "../../Firebase/config"
import { CLEAR_CART } from "../../redux/actions/CartSlice"
import { collection, query, where, getDocs } from "firebase/firestore"
import { FaFacebook, FaInstagram } from "react-icons/fa"
export const HomeComponentPDR = () => {
  const [pressedcheck, setpressedcheck] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const orderID = localStorage.getItem("orderID")
  const PickuporderID = localStorage.getItem("PickuporderID")
  const DeliveryOrderID = localStorage.getItem("DeliveryOrderID")
  const user = JSON.parse(localStorage.getItem("user"))
  const userID = user?.userID
  const [orders, setOrders] = useState([])
  const [Deliveryorders, setDeliveryOrders] = useState([])
  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.has("table")) {
      params.delete("table")
      navigate(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        { replace: true }
      )
    }
  }, [location, navigate])

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersRef = collection(db, "PickupOrders")
        const q = query(ordersRef, where("userID", "==", userID))

        const querySnapshot = await getDocs(q)
        const allOrders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        const filteredOrders = allOrders.filter(
          (order) => order.Picked === false
        )
        setOrders(filteredOrders)
        if (filteredOrders.length === 0) {
          localStorage.removeItem("PickuporderID")
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchOrders()
  }, [])

  useEffect(() => {
    const fetchDeliveryOrders = async () => {
      try {
        const ordersRef = collection(db, "DeliveryOrders")
        const q = query(ordersRef, where("userID", "==", userID))

        const querySnapshot = await getDocs(q)
        const allOrders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        const filteredOrders = allOrders.filter(
          (order) => order.delivered === false
        )
        setDeliveryOrders(filteredOrders)
        if (filteredOrders.length === 0) {
          localStorage.removeItem("DeliveryOrderID")
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchDeliveryOrders()
  }, [])

  return (
    <Row className="background" style={{ overflowY: "auto" }}>
      <Col>
        <div className="d-flex justify-content-center ">
          <m.div
            className="d-flex justify-content-center align-items-center circlebg-PDR"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: 0.3 }}
            exit={{ opacity: 1 }}
          >
            <img className="images" alt="Logo" src={Logo}></img>
          </m.div>
        </div>
        <m.div
          className="d-flex justify-content-center "
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.9, delay: 0.5 }}
          exit={{ opacity: 1 }}
        ></m.div>
        <m.div
          className="d-flex justify-content-center "
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.7 }}
          exit={{ opacity: 1 }}
        >
          <div>
            {localStorage.getItem("PickuporderID") &&
            localStorage.getItem("orderType") === "Pickup" ? (
              <div className="d-flex flex-column align-items-center ">
                <div className="d-flex flex-column">
                  <Link to="/status" style={{ textDecoration: "none" }}>
                    <button className="startbtns px-3 mt-3">
                      <span className="buttonText">Check Pickup Status</span>
                    </button>
                  </Link>
                  <Link
                    to={`/completedorder/?type=pickup&&orderID=${PickuporderID}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      className="startbtns px-3 mt-3"
                      onClick={async () => {
                        localStorage.setItem("ratePass", "true")
                        localStorage.removeItem("orderType")

                        await dispatch(CLEAR_CART())
                      }}
                    >
                      <span className="buttonText">Start a new Order?</span>
                    </button>
                  </Link>
                </div>
              </div>
            ) : localStorage.getItem("DeliveryOrderID") &&
              localStorage.getItem("orderType") === "Delivery" ? (
              <div className="d-flex flex-column align-items-center ">
                <div className="d-flex flex-column">
                  <Link
                    to="/status-delivery"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="startbtns px-3 mt-3">
                      <span className="buttonText">Check Delivery Status</span>
                    </button>
                  </Link>
                  <Link
                    to={`/completedorder/?type=Delivery&&orderID=${DeliveryOrderID}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      className="startbtns px-3 mt-3"
                      onClick={async () => {
                        localStorage.setItem("ratePass", "true")
                        localStorage.removeItem("orderType")

                        await dispatch(CLEAR_CART())
                      }}
                    >
                      <span className="buttonText">Start a new Order?</span>
                    </button>
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <Link to="/Menu" style={{ textDecoration: "none" }}>
                  <button
                    className="startbtns-Extra"
                    onClick={() => localStorage.setItem("orderType", "Pickup")}
                  >
                    <span className="buttonText p-3">Pickup?</span>
                  </button>
                </Link>

                {orders?.length > 0 && (
                  <Link to="/status" style={{ textDecoration: "none" }}>
                    <button className="startbtns">
                      <span className="buttonText p-3">
                        You have {orders.length} Pickup orders
                      </span>
                    </button>
                  </Link>
                )}

                <Link to="/Menu" style={{ textDecoration: "none" }}>
                  <button
                    className="startbtns"
                    onClick={() =>
                      localStorage.setItem("orderType", "Delivery")
                    }
                  >
                    <span className="buttonText p-3">Delivery?</span>
                  </button>
                </Link>
                {Deliveryorders?.length > 0 && (
                  <Link
                    to="/status-delivery"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="startbtns">
                      <span className="buttonText p-3">
                        You have {Deliveryorders.length} Delivery orders
                      </span>
                    </button>
                  </Link>
                )}

                <Link to="/checkout" style={{ textDecoration: "none" }}>
                  <button
                    className="startbtns"
                    onClick={() =>
                      localStorage.setItem("orderType", "Reservation")
                    }
                  >
                    <span className="buttonText p-3">Reserve?</span>
                  </button>
                </Link>
              </div>
            )}
            <div className="d-flex flex-column mt-3">
              <p className="social-text ">
                <b>Follow Us for Updates & Offers! </b>
              </p>
              <div className="social-icons-container  ">
                <a
                  href="https://www.facebook.com/profile.php?id=61552698836883&mibextid=LQQJ4d"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-wrapper"
                >
                  <FaFacebook className="social-icon facebook-icon" />
                </a>
                <a
                  href="https://www.instagram.com/katalyst.eg?igsh=MTA3aWsweWFzd2w1bw=="
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-wrapper"
                >
                  <FaInstagram className="social-icon instagram-icon" />
                </a>
              </div>
            </div>
          </div>
        </m.div>
        <m.div
          className=" d-flex justify-content-center mt-4"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.9, delay: 1 }}
        ></m.div>
      </Col>
    </Row>
  )
}
