import React from "react"
import { Container } from "react-bootstrap"
import { OneDeliveryorderdetails } from "../../Components/Admin/oneDeliveryOrder"
const AdminoneDeliveryorderdetailsPage = () => {
  return (
    <Container fluid>
      <OneDeliveryorderdetails />
    </Container>
  )
}
export default AdminoneDeliveryorderdetailsPage
