import { useEffect, useState } from "react"
import Notify from "../Usenotifications"
import avatar from "../../images/avatar.png"
import { storage } from "../../../Firebase/config"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { collection, addDoc, Timestamp } from "firebase/firestore"
import { db } from "../../../Firebase/config"

export const CreatecategoryHook = () => {
  const [name, setName] = useState(" ")
  const [img, setImg] = useState(avatar)
  const [loading, setLoading] = useState()
  const [isPress, setPress] = useState(false)

  const inputName = (e) => {
    setName(e.target.value)
  }
  const onImageChange = (event) => {
    const file = event.target.files[0]
    if (!file) {
      Notify("No file selected. Please choose a file.", "error")
      console.error("No file selected.")
      return
    }
    const storageRef = ref(storage, `Katalyst/${Date.now()}${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)
    setLoading(true)
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // You can track progress here if needed
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log(`Upload is ${progress}% done`)
      },
      (error) => {
        // Handle upload errors
        console.error("Upload failed:", error)
        Notify(error.message || "An error occurred", "error")
      },
      () => {
        // Upload completed successfully
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            console.log("File available at:", downloadURL)
            setImg(downloadURL)
            Notify("Uploaded successfully", "success")
          })
          .catch((error) => {
            console.error("Error getting download URL:", error)
            Notify(error.message || "Failed to get download URL", "error")
          })
          .finally(() => {
            setLoading(false)
          })
      }
    )
  }

  const handlesubmit = async (e) => {
    e.preventDefault()

    if (name !== "") {
      console.log(name)
      try {
        const docRef = await addDoc(collection(db, "Categories"), {
          name: name,
          image: img,
          createdAt: Timestamp.now().toDate(),
        })
        Notify("Category Created succesfully", "success")
      } catch {
        Notify("unable to Add Category", "error")
      }

      setName("")
      setImg(avatar)
    }
  }

  return [
    name,
    isPress,
    loading,
    handlesubmit,
    inputName,
    img,
    onImageChange,
    loading,
  ]
}
export default CreatecategoryHook
