import { useEffect, useState } from "react"
import { auth } from "../../../Firebase/config"
import { collection, query, where, getDocs } from "firebase/firestore"
import { db } from "../../../Firebase/config"
import { useNavigate } from "react-router-dom"
import Notify from "../Usenotifications"

export const LoginDriversHook = () => {
  const navigate = useNavigate()
  const [phone, setPhonenumber] = useState("")
  const [password, setPassword] = useState("")
  const [pressed, setpressed] = useState(false)

  const onchangePhone = (e) => {
    setPhonenumber(e.target.value)
  }
  const onchangePassword = (e) => {
    setPassword(e.target.value)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setpressed(true)

    try {
      // Query Firestore for a driver with the matching phone number
      const q = query(collection(db, "Drivers"), where("phone", "==", phone))
      const querySnapshot = await getDocs(q)

      if (!querySnapshot.empty) {
        // Check if the password matches
        const driverData = querySnapshot.docs[0].data()
        if (driverData.password === password) {
          const driverInfo = {
            name: driverData.name,
            phone: driverData.phone,
            userRole: driverData.userRole,
            DriverID: driverData.DriverID,
          }
          localStorage.setItem("driverInfo", JSON.stringify(driverInfo))
          Notify("Login successful", "success")
          //   localStorage.setItem("Driver", JSON.stringify(driverData))
          navigate("/Driver")
        } else {
          Notify("Invalid phone number or password", "error")
        }
      } else {
        Notify("Invalid phone number or password", "error")
      }
    } catch (error) {
      Notify(`Error logging in: ${error.message}`, "error")
    } finally {
      setpressed(false)
    }
  }

  return [phone, password, onchangePhone, onchangePassword, onSubmit, pressed]
}
