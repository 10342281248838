import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Addproduct from "../../Components/Admin/Addproduct"
import { SidemenuComponent } from "../../Components/Admin/Sidemenu"
const Adminaddproduct = () => {
  return (
    <Container fluid>
      <Row>
        <Col sm="2" xs="2" className="px-0">
          <SidemenuComponent />
        </Col>
        <Col sm="10" xs="12" className="px-0">
          <Addproduct />
        </Col>
      </Row>
    </Container>
  )
}
export default Adminaddproduct
