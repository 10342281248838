import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  cartItems: localStorage.getItem("Cartproducts")
    ? JSON.parse(localStorage.getItem("Cartproducts"))
    : [],
  CartTotalQuantity: 0,
  CartTotalAmount: localStorage.getItem("Totalprice")
    ? JSON.parse(localStorage.getItem("Totalprice"))
    : 0,
  CartTotalAmountdiscount: localStorage.getItem("discounted_price")
    ? JSON.parse(localStorage.getItem("discounted_price"))
    : 0,
  promodiscount: 0,
}

const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    ADD_TO_CART(state, action) {
      //check if product is already in the cart
      const productIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      )
      if (productIndex >= 0) {
        // Item already exists in the cart
        // Increase the cartQuantity
        state.cartItems[productIndex].CartTotalQuantity += 1
      } else {
        // Item doesn't exists in the cart
        // Add item to the cart
        const tempProduct = { ...action.payload, CartTotalQuantity: 1 }
        state.cartItems.push(tempProduct)
      }
      localStorage.setItem("Cartproducts", JSON.stringify(state.cartItems))
      CartSlice.caseReducers.CALCULATE_TOTAL(state)
    },

    DECREASE_CART_QUANTITY(state, action) {
      const productIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      )
      if (state.cartItems[productIndex].CartTotalQuantity > 1) {
        // decrease the cartQuantity
        state.cartItems[productIndex].CartTotalQuantity -= 1
      } else if (state.cartItems[productIndex].CartTotalQuantity === 1) {
        const newCartItem = state.cartItems.filter(
          (item) => item.id !== action.payload.id
        )
        state.cartItems = newCartItem
      }
      localStorage.setItem("Cartproducts", JSON.stringify(state.cartItems))
      CartSlice.caseReducers.CALCULATE_TOTAL(state)
    },

    REMOVE_FROM_CART(state, action) {
      const newCartItem = state.cartItems.filter(
        (item) => item.id !== action.payload.id
      )
      state.cartItems = newCartItem
      localStorage.setItem("Cartproducts", JSON.stringify(state.cartItems))
      CartSlice.caseReducers.CALCULATE_TOTAL(state)
    },

    CLEAR_CART(state, action) {
      state.cartItems = []
      localStorage.setItem("Cartproducts", JSON.stringify(state.cartItems))
    },

    CALCULATE_TOTAL(state, action) {
      const array = []
      state.cartItems.map((item) => {
        const { Price, CartTotalQuantity } = item

        const cartItemAmount = Price * CartTotalQuantity
        return array.push(cartItemAmount)
      })

      const totalAmount = array.reduce((a, b) => {
        return a + b
      }, 0)
      state.CartTotalAmount = totalAmount

      if (state.promodiscount > 0) {
        state.CartTotalAmountdiscount =
          totalAmount - (totalAmount * state.promodiscount) / 100
        localStorage.setItem(
          "discounted_price",
          JSON.stringify(state.CartTotalAmountdiscount)
        )
      } else {
        state.CartTotalAmountdiscount = totalAmount
        localStorage.setItem(
          "Totalprice",
          JSON.stringify(state.CartTotalAmount)
        )
      }
    },

    APPLY_PROMO_CODE(state, action) {
      state.promodiscount = action.payload
      const array = []
      state.cartItems.map((item) => {
        const { Price, CartTotalQuantity } = item

        const cartItemAmount = Price * CartTotalQuantity
        return array.push(cartItemAmount)
      })

      if (state.promodiscount > 0) {
        const totalAmountbefore = array.reduce((a, b) => {
          return a + b
        }, 0)
        const totalAmount =
          totalAmountbefore - (totalAmountbefore * state.promodiscount) / 100
        state.CartTotalAmountdiscount = totalAmount
      }
      CartSlice.caseReducers.CALCULATE_TOTAL(state)
      localStorage.setItem("promodiscount", state.promodiscount)
    },

    UPDATE_CART(state, action) {
      //  action.payload contains the updated cart items array
      state.cartItems = action.payload

      //check if product is already in the cart
      const productIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      )
      if (productIndex >= 0) {
        // Item already exists in the cart
        // Increase the cartQuantity
        state.cartItems[productIndex].CartTotalQuantity += 1
      } else {
        // Item doesn't exists in the cart
        // Add item to the cart
        const tempProduct = { ...action.payload, CartTotalQuantity: 1 }
        state.cartItems.push(tempProduct)
      }
      localStorage.setItem("Cartproducts", JSON.stringify(state.cartItems))
    },

    PLACE_ORDER(state, action) {
      state.promodiscount = 0
    },
  },
})
export const {
  ADD_TO_CART,
  DECREASE_CART_QUANTITY,
  REMOVE_FROM_CART,
  CLEAR_CART,
  CALCULATE_TOTAL,
  APPLY_PROMO_CODE,
  UPDATE_CART,
  PLACE_ORDER,
} = CartSlice.actions
export const selectCartitems = (state) => state.cart.cartItems
export const selectTotalQuantity = (state) => state.cart.CartTotalQuantity
export const selectCartTotalAmount = (state) => state.cart.CartTotalAmount
export const selectCartAmountDiscounted = (state) =>
  state.cart.CartTotalAmountdiscount

export default CartSlice.reducer
