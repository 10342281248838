import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { SidemenuComponent } from "../../Components/Admin/Sidemenu"
import Adminmenucontainer from "../../Components/Admin/Adminmenucontainer"
const AdminDeleteorEdit = () => {
  return (
    <Container fluid>
      <Row>
        <Col sm="2" xs="2" className="px-0">
          <SidemenuComponent />
        </Col>
        <Col sm="10" xs="10" className="px-0">
          <Adminmenucontainer />
        </Col>
      </Row>
    </Container>
  )
}
export default AdminDeleteorEdit
