import React from "react"
import { Container } from "react-bootstrap"
import { Oneorderdetails } from "../../Components/Admin/Allorderdetails"
const AdminoneorderdetailsPage = () => {
  return (
    <Container fluid>
      <Oneorderdetails />
    </Container>
  )
}
export default AdminoneorderdetailsPage
