import React from "react"
import Ordersummary from "../../Components/Checkout/Accordian"
import Placeorder from "../../Components/Checkout/Placeorder"
import "../../Styles/Checkoutstyle.css"
export const CheckoutPage = () => {
  return (
    <div className=" payment-container">
      <Ordersummary />
      <Placeorder />
    </div>
  )
}
