import React from "react"
import { Container, Col } from "react-bootstrap"
import "../../Styles/Adminreviews.css"
import { AllDeliveryOrdersStaff } from "../../Components/Admin/AllordersDeliveryStaff"
import StaffComponent from "../../Components/Admin/StaffPage"
const DeliveryOrdersStaff = () => {
  return (
    <Container fluid className="px-0">
      <Col sm="12" xs="12" className="px-0">
        <StaffComponent />
      </Col>
      <Col sm="12" xs="12" className="px-0">
        <AllDeliveryOrdersStaff />
      </Col>
    </Container>
  )
}
export default DeliveryOrdersStaff
