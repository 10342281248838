import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { Addcoupon } from "../../Components/Admin/Addcoupon"
import { SidemenuComponent } from "../../Components/Admin/Sidemenu"
const Adminaddcouponpage = () => {
  return (
    <Container fluid>
      <Row>
        <Col sm="2" xs="2" className="px-0">
          <SidemenuComponent />
        </Col>

        <Col sm="10" xs="12" className="px-0">
          <Addcoupon />
        </Col>
      </Row>
    </Container>
  )
}
export default Adminaddcouponpage
