import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import QrScanner from "react-qr-scanner"
import Notify from "../Componentslogic/Usenotifications"

const QrScan = () => {
  useEffect(() => {
    localStorage.removeItem("firstTime")
  }, [])

  const navigate = useNavigate() // React Router navigation hook
  const [error, setError] = useState(null) // State to store errors

  const handleScan = (data) => {
    if (data) {
      try {
        const url = new URL(data.text)
        if (url.hostname.includes("katalyst-dining.com")) {
          const params = new URLSearchParams(url.search)
          const table = params.get("table")
          if (table === "50") {
            localStorage.setItem("isQRScanned", "true")
            localStorage.setItem("orderType", "DineIn")
            navigate("/Menu")
          } else {
            setError("Invalid QR code. Please try again.")

            setTimeout(() => {
              window.location.reload(false)
            }, 3000)
          }
        }
      } catch (error) {
        Notify("Invalid QR code", "error")
      }
    }
  }

  const handleError = (err) => {
    console.error("QR Scan Error:", err)
    Notify("Error Scanning QR code ", "error")
  }

  const previewStyle = {
    height: 240,
    width: 320,
    margin: "0 auto",
  }

    const videoConstraints = {
    facingMode: { exact: "environment" }, 
  }

  return (
    <div className="d-flex justify-content-center align-items-center flex-column review-container">
      <h1 className="text mb-4">Scan QR Code on the Table</h1>
      <QrScanner
        delay={300}
        style={previewStyle}
        onError={handleError}
        onScan={handleScan}
 constraints={{ video: videoConstraints }} 

      />
      {error && Notify("Invalid Qr code", "error")}
    </div>
  )
}

export default QrScan
