import React from "react"
import { Container } from "react-bootstrap"
import { OnePickuporderdetails } from "../../Components/Admin/onePickuporderdetails"
const AdminonePickuporderdetailsPage = () => {
  return (
    <Container fluid>
      <OnePickuporderdetails />
    </Container>
  )
}
export default AdminonePickuporderdetailsPage
