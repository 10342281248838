import React from "react"
import { Navigate, Outlet } from "react-router-dom"
export const ProtectedDriverpage = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  const driverInfo = JSON.parse(localStorage.getItem("driverInfo"))
  if (
    driverInfo !== null ||
    user?.email === process.env.REACT_APP_ADMIN_EMAIL
  ) {
    if (
      driverInfo?.userRole === "Driver" ||
      user?.email === process.env.REACT_APP_ADMIN_EMAIL
    ) {
      let authuser = true
      return authuser ? <Outlet /> : null
    } else {
      return <Navigate to="/LoginDrivers" />
    }
  } else {
    return <Navigate to="/LoginDrivers" />
  }
}
