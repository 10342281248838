import React, { useState, useEffect } from "react"
import { AgCharts } from "ag-charts-react"
import UsefetchCollectionNonRealtime from "../Componentslogic/UsefetchcollectionNonrealtime"
import "../../Styles/Overviewstyling.css"
import { collection, addDoc, Timestamp, doc, getDoc } from "firebase/firestore"
import { db } from "../../Firebase/config"
const isToday = (timestamp) => {
  const today = new Date()
  const orderDate = new Date(timestamp.seconds * 1000)

  return (
    orderDate.getDate() === today.getDate() &&
    orderDate.getMonth() === today.getMonth() &&
    orderDate.getFullYear() === today.getFullYear()
  )
}

const isThisWeek = (timestamp) => {
  const today = new Date()
  const orderDate = new Date(timestamp.seconds * 1000)

  const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()))
  startOfWeek.setHours(0, 0, 0, 0)

  return orderDate >= startOfWeek
}

const isThisMonth = (timestamp) => {
  const today = new Date()
  const orderDate = new Date(timestamp.seconds * 1000)

  return (
    orderDate.getMonth() === today.getMonth() &&
    orderDate.getFullYear() === today.getFullYear()
  )
}

export const ChartExample = () => {
  const {
    data: orders,
    loading: loadingOrders,
    error: errorOrders,
  } = UsefetchCollectionNonRealtime("Orders")

  const {
    data: Pickuporders,
    loading: loadingPickupOrders,
    error: errorPickupOrders,
  } = UsefetchCollectionNonRealtime("PickupOrders")
  const [Counterusers, setCounterusers] = useState([])
  const [Counternonusers, setCounternonusers] = useState([])
  useEffect(() => {
    const fetchInteractionCounts = async () => {
      const counterRef = doc(db, "Counter", "Interactions") // Reference to the document

      try {
        const docSnap = await getDoc(counterRef)

        if (docSnap.exists()) {
          const data = docSnap.data()
          setCounterusers(data.numberOfUserInteractions || 0)
          setCounternonusers(data.numberOfNonUserInteractions || 0)
        } else {
          console.log("No such document!")
        }
      } catch (error) {
        console.error("Error fetching interactions:", error)
      }
    }
    fetchInteractionCounts()
  }, [])

  const [filter, setFilter] = useState("today")
  const [totalEarnings, setTotalEarnings] = useState(0)
  const [chartOptions, setChartOptions] = useState({
    data: [],
    series: [{ type: "bar", xKey: "OrderType", yKey: "Allorders" }],
  })

  const applyFilter = () => {
    switch (filter) {
      case "today":
        return {
          orders: orders.filter((order) => isToday(order.createdAt)),
          pickupOrders: Pickuporders.filter((order) =>
            isToday(order.createdAt)
          ),
        }
      case "week":
        return {
          orders: orders.filter((order) => isThisWeek(order.createdAt)),
          pickupOrders: Pickuporders.filter((order) =>
            isThisWeek(order.createdAt)
          ),
        }
      case "month":
        return {
          orders: orders.filter((order) => isThisMonth(order.createdAt)),
          pickupOrders: Pickuporders.filter((order) =>
            isThisMonth(order.createdAt)
          ),
        }
      default:
        return { orders: [], pickupOrders: [] }
    }
  }

  useEffect(() => {
    if (orders && Pickuporders) {
      const { orders: filteredOrders, pickupOrders: filteredPickupOrders } =
        applyFilter()

      const dineInEarnings = filteredOrders.reduce(
        (total, order) => total + (order.orderAmount || 0),
        0
      )
      const pickupEarnings = filteredPickupOrders.reduce(
        (total, order) => total + (order.orderAmount || 0),
        0
      )
      const totalEarnings = dineInEarnings + pickupEarnings

      setChartOptions({
        data: [
          {
            OrderType: "Dine-in",
            Allorders: filteredOrders.length,
          },
          {
            OrderType: "Pick-up",
            Allorders: filteredPickupOrders.length,
          },
        ],
        series: [{ type: "bar", xKey: "OrderType", yKey: "Allorders" }],
      })

      setTotalEarnings(totalEarnings)
    }
  }, [orders, Pickuporders, filter])

  const handleFilterChange = (event) => {
    setFilter(event.target.value)
  }

  if (!orders || !Pickuporders) {
    return <p>Loading...</p>
  }

  return (
    <div className="cont-overview">
      <div className="overview-container">
        {/* Overview cards */}
        <div className="stats-overview mt-5">
          <div className="stat-card">
            <p>Users Interactions</p>
            <p className="stat-value">{Counterusers}</p>
          </div>
          <div className="stat-card">
            <p>New Users Interactions</p>
            <p className="stat-value ">{Counternonusers}</p>
          </div>
        </div>
        <div className="stats-overview mt-3">
          <div className="stat-card">
            <h3>Earnings</h3>
            <p className="stat-value">{totalEarnings} EGP</p>
          </div>

          <div className="stat-card">
            <h3>Total Orders</h3>
            <p className="stat-value">{Pickuporders.length + orders.length}</p>
          </div>
        </div>

        <div className="radio_cont">
          <div class="radio-inputs">
            <label class="radio">
              <input
                type="radio"
                value="today"
                checked={filter === "today"}
                onChange={handleFilterChange}
              />

              <span class="name">Today</span>
            </label>

            <label class="radio">
              <input
                type="radio"
                value="week"
                checked={filter === "week"}
                onChange={handleFilterChange}
              />
              <span class="name">Week</span>
            </label>

            <label class="radio">
              <input
                type="radio"
                value="month"
                checked={filter === "month"}
                onChange={handleFilterChange}
              />

              <span class="name"> Month</span>
            </label>
          </div>
        </div>

        <div className="chart-section">
          <h3>Statistics</h3>
          <AgCharts options={chartOptions} />
        </div>
      </div>
    </div>
  )
}
