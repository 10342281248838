import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { ADD_TO_CART } from "../../../redux/actions/CartSlice"
import usefetchdocument from "../Usefetchdocument"
import Notify from "../Usenotifications"
import { doc, updateDoc, getDoc } from "firebase/firestore"
import { db } from "../../../Firebase/config"
export const AddproductsHook = () => {
  const [document, setOrderdata] = useState("")
  const orderID = localStorage.getItem("orderID")
  const { document: fetchedDocument } = orderID
    ? usefetchdocument("Orders", orderID)
    : { document: null }
  const [NotesUpdated, setNotesupdate] = useState("")

  const handlechangeNotesupdated = (e) => {
    setNotesupdate(e.target.value)
  }

  const UPDATE_CART = async (products) => {
    try {
      const orderDoc = await getDoc(doc(db, "Orders", orderID))
      if (!orderDoc.exists()) throw new Error("Order does not exist")

      let updatedCartItems = [...(orderDoc.data().cartItems || [])]

      const newProduct = {
        ...products,
        CartTotalQuantity: 1,
        updatedItem: true,
        updatedNotes: NotesUpdated,
      }
      updatedCartItems.push(newProduct)

      if (localStorage.getItem("promodiscount")) {
        const totalAmountbefore = updatedCartItems.reduce((acc, item) => {
          return acc + item.Price * item.CartTotalQuantity
        }, 0)
        const totalAmount =
          totalAmountbefore -
          (totalAmountbefore * localStorage.getItem("promodiscount")) / 100

        await updateDoc(doc(db, "Orders", document.id), {
          orderAmount: totalAmount,
          cartItems: updatedCartItems,
          updated: true,
        })
        localStorage.setItem("discounted_price", totalAmount)
        localStorage.setItem("Totalprice", totalAmountbefore)
      } else {
        const totalAmount = updatedCartItems.reduce(
          (acc, item) => acc + item.Price * item.CartTotalQuantity,
          0
        )
        await updateDoc(doc(db, "Orders", document.id), {
          orderAmount: totalAmount,
          cartItems: updatedCartItems,
          updated: true,
        })
        localStorage.setItem("Totalprice", JSON.stringify(totalAmount))
      }

      // Update the Firestore document with the new cart and total amount

      localStorage.setItem("Cartproducts", JSON.stringify(updatedCartItems))
    } catch (error) {
      console.error("Error updating the cart:", error)
    }
  }

  // Update state when document changes
  useEffect(() => {
    if (fetchedDocument) {
      setOrderdata(fetchedDocument)
    }
  }, [fetchedDocument])

  const [loading, setloading] = useState(true)
  const [pressed, setPressed] = useState()
  const user = JSON.parse(localStorage.getItem("user"))
  const dispatch = useDispatch()

  const handleaddtoCart = (products) => {
    if (localStorage.getItem("orderID") === null) {
      setPressed(true)
      try {
        dispatch(ADD_TO_CART(products))
        setloading(false)
        setPressed(false)
        Notify("Added to cart", "success")
      } catch (error) {
        console.log(error)
      }
    } else {
      UPDATE_CART(products)
      Notify(`Added to Order!`, "success")
      setNotesupdate("")
    }
  }

  return [handleaddtoCart, pressed, NotesUpdated, handlechangeNotesupdated]
}
