import React, { useState } from "react"
import * as XLSX from "xlsx"
import { saveAs } from "file-saver"
import Notify from "../Componentslogic/Usenotifications"
import UsefetchCollectionAdmin from "../Componentslogic/Usefetchcollection"
import Spinner from "react-bootstrap/Spinner"
import Tick from "../images/Tick.svg"
import { doc, updateDoc } from "firebase/firestore"
import { db } from "../../Firebase/config"
const AllUsers = () => {
  const {
    data: users,
    loading,
    currentPage,
    nextPage,
    previousPage,
    hasMore,
  } = UsefetchCollectionAdmin("users")

  const [UserNote, setUserNote] = useState("")
  const handleUserNote = (e, userId) => {
    const { value } = e.target
    setUserNote((prevNotes) => ({
      ...prevNotes,
      [userId]: value,
    }))
  }
  const updateAdminNote = async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId)
      await updateDoc(userDocRef, {
        AdminNote: UserNote[userId] || "",
      })
      Notify("Note updated successfully!", "success")
      setUserNote("")
    } catch (error) {
      Notify("Error updating note: ", "error")
      console.log(error)
    }
  }

  const handleExtract = async () => {
    if (users.length > 0) {
      const usersData = users.map((user, index) => ({
        id: index + 1,
        "Client-Name": user.name,
        "Client-Email": user.email,
        "DineIn-orders": user.numberOfDineinorders,
        "Pickup-orders": user.numberOfPickuporders,
        "Delivery-orders": user.numberOfDeliveries,
        Reservations: user.numberOfReservations || 0,
        "User-Interactions": user.numberofinteractions || 0,
        "Total-user-Orders":
          user.numberOfDineinorders +
          user.numberOfPickuporders +
          user.numberOfDeliveries,
      }))

      // Create a worksheet and workbook
      const worksheet = XLSX.utils.json_to_sheet(usersData)
      worksheet["!cols"] = [
        { wch: 10 }, // Width for the 'id' column
        { wch: 40 }, // Width for the 'clientName' column
        { wch: 40 }, // Width for the 'clientEmail' column
        { wch: 15 }, // Width for the 'userDineInorders' column
        { wch: 15 }, // Width for the 'userPickuporders' column
        { wch: 15 }, // Width for the 'userDeliveryorders' column
        { wch: 15 }, // Width for the 'userReservations' column
        { wch: 15 }, // Width for the 'userInteractions' column
        { wch: 15 }, // Width for the 'TotaluserOrders' column
      ]

      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "Users")

      // Convert the workbook to a binary string and create a Blob object
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      })
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" })

      // Use file-saver to save the file
      saveAs(blob, "Users.xlsx")
      Notify("Extracted", "success")
    } else {
      Notify("error occured", "error")
    }
  }

  return (
    <div>
      {users.length > 0 ? (
        <div>
          {" "}
          <div className="header-section mb-5">
            <h2 className=" title ">Total Users: {users.length}</h2>
            <div onClick={() => handleExtract()}>
              <p className="extract">
                {" "}
                <b>Extract to Excel </b>{" "}
              </p>
            </div>
          </div>
          <div className="Review-Container_orders">
            {users.map((user) => (
              <div className=" Review-Card" key={user.id}>
                <div className="circle-name ">
                  <p>{user.name || "No display name"} </p>
                </div>
                <div className="rating-box">
                  <p>{user.email} </p>
                </div>
                <div className="description-box">
                  <p>- Dine-in orders: {user.numberOfDineinorders || 0} </p>
                  <p>- Pickup orders: {user.numberOfPickuporders || 0} </p>
                  <p>- Delivery orders: {user.numberOfDeliveries || 0} </p>
                  <p>
                    <b>
                      Total:{" "}
                      {(user.numberOfDineinorders || 0) +
                        (user.numberOfPickuporders || 0) +
                        (user.numberOfDeliveries || 0)}
                    </b>
                  </p>
                  <p>
                    - Reservations Confirmed: {user.numberOfReservations || 0}
                  </p>
                  <p>
                    - Featured Interactions: {user.numberofinteractions || 0}
                  </p>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ gap: "5%" }}
                >
                  <input
                    placeholder="Attach Note to User?"
                    className="input-users"
                    name="text"
                    type="text"
                    value={UserNote[user.id]}
                    onChange={(e) => handleUserNote(e, user.id)}
                  />
                  <div onClick={() => updateAdminNote(user.id)}>
                    <img src={Tick} className="icon" alt="tick" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <h2> no users yet </h2>
      )}

      {loading && (
        <Spinner animation="grow" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      {hasMore && !loading && (
        <div className="d-flex justify-content-center align-items-center">
          <button class="btn-Load" onClick={() => nextPage()}>
            Load More
          </button>{" "}
        </div>
      )}
    </div>
  )
}

export default AllUsers
