import Accordion from "react-bootstrap/Accordion"
import { motion as m } from "framer-motion"
import { Link } from "react-router-dom"
import backArrow from "../images/back.svg"
import { useDispatch, useSelector } from "react-redux"
import { selectCartitems } from "../../redux/actions/CartSlice"
function Ordersummary() {
  const isDelivery = localStorage.getItem("orderType")
  const cart = useSelector(selectCartitems)
  return (
    <div>
      {localStorage.getItem("orderType") === "Reservation" ? (
        <div className="d-flex align-items-center ">
          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              className="icon-TIT  mt-3 mx-3"
              src={backArrow}
              alt="go-back"
            ></img>
          </Link>
        </div>
      ) : (
        <>
          <m.div
            className={`Head mt-2 checkout-container pad ${
              isDelivery === "Delivery" ? "mb-3" : "mb-5"
            }`}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <div className="d-flex align-items-center ">
              <Link to="/Orderreview" style={{ textDecoration: "none" }}>
                <img className="icon-TIT " src={backArrow} alt="go-back"></img>
              </Link>
            </div>
            <div className="TIT-check">Checkout</div>
          </m.div>
          <m.div
            className="z-3 accordion-wrapper mb-2"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.7 }}
          >
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Order summary</Accordion.Header>
                {cart
                  ? cart.map((i, index) => {
                      return (
                        <Accordion.Body key={index}>
                          <div className="d-flex justify-content-between">
                            <p>
                              {cart[index].name} x{" "}
                              {cart[index].CartTotalQuantity}
                            </p>
                          </div>
                        </Accordion.Body>
                      )
                    })
                  : null}
              </Accordion.Item>
            </Accordion>
          </m.div>
        </>
      )}
    </div>
  )
}

export default Ordersummary
