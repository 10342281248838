import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  collection,
  addDoc,
  Timestamp,
  updateDoc,
  doc,
} from "firebase/firestore"
import { db } from "../../../Firebase/config"

import Notify from "../Usenotifications"
export const SignupHook = () => {
  const [name, setName] = useState("")
  const [phone, setPhoneNumber] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setconfirmPassword] = useState("")
  // update
  const [pressed, setpressed] = useState(false)
  const [loading, setLoading] = useState(false)

  const onchangeName = (e) => {
    setName(e.target.value)
  }
  const onchangePassword = (e) => {
    setPassword(e.target.value)
  }
  const onchangePhonenumber = (e) => {
    setPhoneNumber(e.target.value)
  }
  const onchangeconfirmPassword = (e) => {
    setconfirmPassword(e.target.value)
  }
  const validation = () => {
    if (name === null) {
      Notify("please enter your name", "error")
      return false
    }
    if (password != confirmPassword) {
      Notify("passwords doesn't match", "error")
      return false
    }
    return true
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    if (!validation()) {
      return
    }
    setLoading(true)
    setpressed(true)
    try {
      // Add user data to Firestore
      const docRef = await addDoc(collection(db, "Drivers"), {
        name: name,
        phone: phone,
        password: password,
        totalDeliveries: 0,
        userRole: "Driver",
        createdAt: Timestamp.now().toDate(),
      })
      const DriverID = docRef.id
      await updateDoc(doc(db, "Drivers", DriverID), { DriverID })
      Notify(`Account is Created`, "success")
      setName("")
      setPhoneNumber("")
      setPassword("")
      setconfirmPassword("")
    } catch (error) {
      Notify(`Error adding document: ${error}`, "error")
    } finally {
      setLoading(false)
      setpressed(false)
    }
  }
  return [
    name,
    phone,
    password,
    confirmPassword,
    onchangeName,
    onchangePhonenumber,
    onchangePassword,
    onchangeconfirmPassword,
    onSubmit,
    pressed,
  ]
}
