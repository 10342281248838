import React, { useState, useRef } from "react"
import StaffPickupOrderListener from "../Pages/StaffPagerealtime"
import StaffDineINListener from "../Pages/StaffpageDinein"
import StaffDeliveryListener from "../Pages/StaffpageDelivery"
import Sound from "../Components/images/Sound.mp3"

const StaffLayout = ({ children }) => {
  const [isSoundEnabled, setIsSoundEnabled] = useState(false)
  const audioRef = useRef(new Audio("/notification.wav"))

  // Function to toggle sound
  const handleToggleSound = () => {
    setIsSoundEnabled(!isSoundEnabled)

    audioRef.current.play().catch((error) => {
      console.error("Audio playback failed:", error)
    })
  }
  // Function to play sound
  const playSound = () => {
    if (isSoundEnabled) {
      audioRef.current.play().catch((error) => {
        console.error("Audio playback failed:", error)
      })
    }
  }

  return (
    <div>
      {isSoundEnabled === false ? (
        <div className="d-flex justify-content-center">
          <label className="switchSound">
            <input
              type="checkbox"
              checked={isSoundEnabled}
              onChange={handleToggleSound}
            />
            <span className="sliderSound"></span>
          </label>
        </div>
      ) : null}

      <StaffPickupOrderListener
        audioRef={audioRef}
        isSoundEnabled={isSoundEnabled}
      />
      <StaffDineINListener
        audioRef={audioRef}
        isSoundEnabled={isSoundEnabled}
      />
      <StaffDeliveryListener
        audioRef={audioRef}
        isSoundEnabled={isSoundEnabled}
      />

      {children}
    </div>
  )
}

export default StaffLayout
