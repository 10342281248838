import React from "react"
import { Button, Card } from "react-bootstrap"
import { CouponHook } from "../Componentslogic/coupons/AddcouponHook"
import UsefetchCollectionNonRealtime from "../Componentslogic/UsefetchcollectionNonrealtime"
export const Addcoupon = () => {
  const { data: couponsRes } = UsefetchCollectionNonRealtime("Promocodes")
  const [
    name,
    handleNamechange,
    expire,
    handleExpirechange,
    discount,
    handleDisountchange,

    handleSubmit,
    handleDelete,
  ] = CouponHook()

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="wrapper mb-3  "
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <div>
            <label>
              <div className="flip-card__inner">
                <div className="title mb-3 mt-5">Add Promocode</div>
                <form className="flip-card__form" action="">
                  <input
                    onChange={handleNamechange}
                    value={name}
                    className="input-Cat mt-3 px-2"
                    name="Name"
                    placeholder="Add Coupon name"
                    type="text"
                  />

                  <input
                    className="input-Cat mt-3 px-2"
                    name="Name"
                    placeholder="Add discount percentage"
                    type="number"
                    onChange={handleDisountchange}
                    value={discount}
                  />
                  <input
                    className="input-Cat mt-3 px-2"
                    onChange={handleExpirechange}
                    value={expire}
                    type="date"
                    placeholder="Add your date here"
                  />

                  <div className="register">
                    <button
                      className="btns-Admin"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className="Promocodes-container ">
        {Array.isArray(couponsRes) ? (
          couponsRes.map((coupon, index) => {
            return (
              <Card key={index} className="promoCard mx-2 my-3">
                <Card.Body>
                  <Card.Title>Coupon: {coupon.name}</Card.Title>
                  <Card.Text>
                    <div>
                      <h6>expire Date: {coupon.expire}</h6>
                      <h6>Discount percentage: {coupon.discount}%</h6>
                    </div>
                  </Card.Text>
                </Card.Body>
                <Button
                  onClick={(e) => handleDelete(e, coupon.id)}
                  className="btn-danger"
                >
                  Delete
                </Button>
              </Card>
            )
          })
        ) : (
          <h2>No copouns</h2>
        )}
      </div>
    </div>
  )
}
