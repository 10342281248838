import { Col, Row, Modal } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import { useParams, useLocation } from "react-router-dom"
import { CreateorderHook } from "../Componentslogic/ordersLogic/CreateorderHook"
import { motion as m } from "framer-motion"
import { useDispatch, useSelector } from "react-redux"
import { selectEmail, selectUserName } from "../../redux/actions/authActions"
import users from "../images/Logout.svg"
import Select from "react-select"
import dayjs from "dayjs"
import {
  collection,
  addDoc,
  Timestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore"
import { db, auth } from "../../Firebase/config"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import Notify from "../Componentslogic/Usenotifications"
import {
  selectCartAmountDiscounted,
  selectCartTotalAmount,
} from "../../redux/actions/CartSlice"
function Placeorder() {
  const times = [
    { value: "12:00", label: "12-1 PM" },
    { value: "13:00", label: "1-2 PM" },
    { value: "14:00", label: "2-3 PM" },
    { value: "15:00", label: "3-4 PM" },
    { value: "16:00", label: "4-5 PM" },
    { value: "17:00", label: "5-6 PM" },
    { value: "18:00", label: "6-7 PM" },
    { value: "19:00", label: "7-8 PM" },
    { value: "20:00", label: "8-9 PM" },
    { value: "21:00", label: "9-10 PM" },
    { value: "22:00", label: "10-11 PM" },
    { value: "23:00", label: "11-12 PM" },
  ]

  const [
    handlePlaceorder,
    tablenumber,
    handleChangetable,
    Notes,
    handlechangeNotes,
    pressedPlace,
    handleTimeChange,
    reservationTime,
    handleDateChange,
    selectedDate,
    handleChangePeople,
    handleChangePhone,
    phoneNumber,
    //Delivery
    handlechangeBuildingName,
    buildingName,
    handlechangeAptNo,
    aptNo,
    handlechangeFloor,
    floor,
    handlechangeStreet,
    street,
    handlechangeadditonalDirections,
    directions,
    handleDeliveryPhonenumber,
    DeliveryphoneNumber,
    selectedAddress,
    setSelectedAddress,
    handleSelectAddress,
  ] = CreateorderHook()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [savedAddresses, setSavedAddresses] = useState([])

  const onchangeName = (e) => {
    setName(e.target.value)
  }
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const onchangeEmail = (e) => {
    setEmail(e.target.value)
  }
  const Total = useSelector(selectCartTotalAmount)
  const Totaldiscounted = useSelector(selectCartAmountDiscounted)
  const user = JSON.parse(localStorage.getItem("user"))

  const [dates, setDates] = useState([])

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    control: (provided) => ({
      ...provided,
      width: 250,
      touchAction: "manipulation",
    }),
  }

  const customStylesTable = {
    singleValue: (provided) => ({
      ...provided,
      fontWeight: "bold", // Make selected option bold
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#e0e0e0", // Grey background for disabled select
      cursor: "not-allowed", // Non-interactive cursor
    }),
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (email !== "" && name !== "") {
      if (email && !validateEmail(email)) {
        Notify("Please enter a valid email address.", "error")
        return
      }
      try {
        // Query Firestore to check if the user exists
        const q = query(
          collection(db, "users"),
          where("email", "==", email.toLowerCase())
        )
        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
          // If user exists
          const existingUser = querySnapshot.docs[0].data() // Get the first matching user
          const userData = {
            name: existingUser.name,
            email: existingUser.email,
            userID: querySnapshot.docs[0].id, // Use the document ID as userID
          }

          // Save to localStorage
          localStorage.setItem("user", JSON.stringify(userData))
          Notify(`Welcome back ${existingUser.name}!`, "success")
          setTimeout(() => {
            window.location.reload(false)
          }, 1500)
        } else {
          const docRef = await addDoc(collection(db, "users"), {
            name: name,
            email: email.toLowerCase(),
            numberOfPickuporders: 0,
            numberOfDineinorders: 0,
            numberOfReservations: 0,
            numberOfDeliveries: 0,
            numberofinteractions: 0,
            createdAt: Timestamp.now().toDate(),
          })
          const userID = docRef.id
          const userData = {
            name: name,
            email: email,
            userID: userID,
          }
          localStorage.setItem("user", JSON.stringify(userData))
          Notify(`Accont created! Thank you ${name}`, "success")
          setTimeout(() => {
            window.location.reload(false)
          }, 1500)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      Notify("Please fill all necessary details", "error")
    }
  }

  const handleLogout = () => {
    localStorage.removeItem("user")
    window.location.reload(false)
  }
  const truncateEmail = (email, maxLength = 30) => {
    if (email.length > maxLength) {
      return email.slice(0, maxLength - 3) + "..."
    }
    return email
  }

  useEffect(() => {
    // Generate dates starting from tomorrow for 7 days
    const generateDates = () => {
      let nextWeekDates = []
      for (let i = 1; i <= 7; i++) {
        nextWeekDates.push({
          value: dayjs().add(i, "day").format("YYYY-MM-DD"), // Format as desired
          label: dayjs().add(i, "day").format("dddd, MMM D"), // e.g., "Tuesday, Oct 10"
        })
      }
      setDates(nextWeekDates)
    }

    generateDates()
  }, [])

  const allTables = Array.from({ length: 51 }, (_, index) => ({
    value: index + 1,
    label: `Table ${index + 1}`,
  }))

  const fetchSavedAddresses = async () => {
    if (user) {
      try {
        // Fetch all addresses for the user
        const querySnapshot = await getDocs(collection(db, "UserAddresses"))

        const addresses = []

        querySnapshot.forEach((doc) => {
          const addressData = doc.data()

          // Check if the address belongs to the current user
          if (addressData.userID === user.userID) {
            const addressString = `BuildingName:${addressData.buildingName.trim()}, Floor:${addressData.floor.trim()}, Apt:${addressData.aptNo.trim()}, Street:${addressData.street.trim()}, Directions:${addressData.directions.trim()}, phoneNumber:${addressData.DeliveryphoneNumber.trim()}`
            addresses.push(addressString)
          }
        })

        setSavedAddresses(addresses)
      } catch (error) {
        console.error("Error fetching saved addresses:", error)
      }
    }
  }

  useEffect(() => {
    if (localStorage.getItem("orderType") === "Delivery") {
      fetchSavedAddresses()
    }
  }, [user])

  const handleAddNewAddress = () => {
    setSelectedAddress(null)
    setShowAddressForm(true)
  }

  // const setupRecaptcha = () => {
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     "recaptcha-container",
  //     {
  //       size: "invisible",
  //       callback: () => {
  //         // reCAPTCHA solved, allow send verification code
  //         sendVerificationCode()
  //       },
  //     },
  //     auth
  //   )
  // }

  // const sendVerificationCode = async () => {
  //   try {
  //     setupRecaptcha()
  //     const appVerifier = window.recaptchaVerifier

  //     const formattedPhoneNumber = `+20${DeliveryphoneNumber}`
  //     const confirmationResult = await signInWithPhoneNumber(
  //       auth,
  //       formattedPhoneNumber,
  //       appVerifier
  //     )
  //     setVerificationId(confirmationResult.verificationId)
  //     Notify("Verification code sent!", "success")
  //   } catch (error) {
  //     console.error(
  //       "Error during reCAPTCHA setup or sending verification code:",
  //       error
  //     )
  //     alert("Failed to send verification code. Please try again.")
  //   }
  // }

  return (
    <Form className="content-below-accordion ">
      <div className="pad">
        <m.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.9 }}
        >
          {localStorage.getItem("orderType") === "Reservation" ||
          localStorage.getItem("orderType") === "Delivery" ||
          !user ? null : (
            <div className="table-input">
              <label for="number " className="title-Card mb-1">
                Add notes to order
              </label>
              <textarea
                className="p-2"
                onChange={handlechangeNotes}
                value={Notes}
                rows="2"
              />
            </div>
          )}
        </m.div>
        <m.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.9 }}
        >
          <Row>
            <div>
              {user ? null : (
                <div className="sign-upuser mt-5 ">
                  <h5 className="text"> Name : </h5>
                  <input
                    style={{ touchAction: "manipulation" }}
                    value={name}
                    onChange={onchangeName}
                    className="flip-card__input mb-2 "
                    name="name"
                    placeholder="Name"
                    type="text"
                  />

                  <h5 className="text"> Email : </h5>
                  <input
                    style={{ touchAction: "manipulation" }}
                    value={email}
                    onChange={onchangeEmail}
                    className="flip-card__input mb-4 "
                    name="email"
                    placeholder="Email"
                    type="email"
                  />
                  <button
                    onClick={(e) => onSubmit(e)}
                    className="flip-card__btn "
                    initial={{ opacity: 0, x: 15 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, delay: 0.7 }}
                  >
                    <span className="bodytext">SignUp</span>
                  </button>
                </div>
              )}
            </div>
          </Row>
        </m.div>
        <m.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={
            localStorage.getItem("orderType") === "Reservation"
              ? { duration: 0.5, delay: 0.3 }
              : { duration: 0.5, delay: 1.1 }
          }
        >
          {localStorage.getItem("orderType") === "DineIn" && user && (
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className=" Table_no">
                <label for="number" className="title-Card">
                  Table Number
                </label>
                <Select
                  id="tableNumber"
                  value={tablenumber}
                  options={allTables}
                  onChange={handleChangetable}
                  styles={customStyles}
                  isSearchable={false}
                />
              </div>
            </Form.Group>
          )}

          {localStorage.getItem("orderType") === "Delivery" && user && (
            <>
              {!showAddressForm && savedAddresses.length > 0 ? (
                <div>
                  <h5 className="address-header">Choose a Delivery Address:</h5>
                  <button
                    className="add-new-address-btn"
                    onClick={handleAddNewAddress}
                  >
                    + Add New Address
                  </button>

                  <div
                    className="address-container  "
                    style={{ marginBottom: "20%" }}
                  >
                    {savedAddresses.map((address, index) => (
                      <div
                        key={index}
                        className={`address-card ${
                          selectedAddress === address ? "selectedAddress" : ""
                        }`}
                        onClick={() => handleSelectAddress(address)}
                      >
                        <p>{address}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="delivery-form">
                    <div className="form-group">
                      <label>Building Name:</label>
                      <input
                        type="text"
                        value={buildingName}
                        onChange={(e) => handlechangeBuildingName(e)}
                        required
                      />
                    </div>

                    <div className="form-row">
                      <div className="form-group">
                        <label>Apt No:</label>
                        <input
                          type="text"
                          value={aptNo}
                          onChange={(e) => handlechangeAptNo(e)}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Floor:</label>
                        <input
                          type="text"
                          value={floor}
                          onChange={(e) => handlechangeFloor(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Street:</label>
                      <input
                        type="text"
                        value={street}
                        onChange={(e) => handlechangeStreet(e)}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label>Additional Directions:</label>
                      <input
                        type="text"
                        value={directions}
                        onChange={(e) => handlechangeadditonalDirections(e)}
                      />
                    </div>

                    <div className="form-group phone-group">
                      <label>Phone Number:</label>
                      <div className="phone-input">
                        <span>+20</span>
                        <input
                          type="tel"
                          value={DeliveryphoneNumber}
                          onChange={(e) => handleDeliveryPhonenumber(e)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </Form.Group>
              )}
            </>
          )}

          {localStorage.getItem("orderType") === "Pickup" && user && (
            <div>
              <div className="d-flex justify-content-between">
                <Col xs="4">
                  <div>
                    <p className="title-Card ">Name</p>

                    <p> {user.name}</p>
                  </div>
                </Col>

                <Col xs="8">
                  <div className="email-text">
                    <p className="title-Card  "> Email</p>

                    <p> {truncateEmail(user.email)}</p>
                  </div>
                </Col>
              </div>
              <div
                onClick={() => handleLogout()}
                className="d-flex align-items-center justify-content-center "
              >
                <img className="icon mb-3" src={users} alt="Manage" />{" "}
              </div>{" "}
            </div>
          )}

          {localStorage.getItem("orderType") === "Reservation" && user && (
            <div>
              <Form.Group controlId="reservationDate ">
                <div className="Table_no mb-3">
                  <label htmlFor="reservationDate" className="title-Card">
                    Select Reservation Date
                  </label>
                  <Select
                    id="reservationDate"
                    styles={customStyles}
                    options={dates}
                    placeholder="Select reservation date"
                    onChange={handleDateChange}
                    required
                    menuPlacement="top"
                  />
                </div>
              </Form.Group>

              <Form.Group controlId="reservationDate">
                <div className=" Table_no mb-3">
                  <label htmlFor="reservationTime" className="title-Card">
                    Reservation Time
                  </label>
                  <Select
                    id="reservationTime"
                    value={reservationTime}
                    styles={customStyles}
                    onChange={handleTimeChange}
                    options={times} // Use predefined times
                    placeholder="Select time"
                    required
                    menuPlacement="top"
                  />
                </div>
              </Form.Group>

              {selectedDate && (
                <m.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <div className=" Table_no mb-3">
                      <label for="number">Number of People</label>
                      <input
                        style={{ width: "30%" }}
                        id="tableNumber"
                        value={tablenumber}
                        onChange={(e) => handleChangePeople(e)}
                        type="number"
                        required
                      ></input>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <div className=" Table_no mb-3">
                      <label for="number">Phone Number</label>
                      <input
                        style={{ width: "60%" }}
                        id="tableNumber"
                        value={phoneNumber}
                        onChange={(e) => handleChangePhone(e)}
                        type="number"
                        required
                      ></input>
                    </div>
                  </Form.Group>
                </m.div>
              )}
            </div>
          )}
        </m.div>
      </div>

      <div id="recaptcha-container"></div>

      {user && (
        <div className="Sticky">
          {pressedPlace ? (
            <div className="btns-checkout">
              <div className="loader">
                <li className="ball"></li>
                <li className="ball"></li>
                <li className="ball"></li>
              </div>
            </div>
          ) : (
            <button
              onClick={(e) => {
                handlePlaceorder(e)
              }}
              className="btns-checkout"
            >
              {localStorage.getItem("orderType") === "Delivery"
                ? `Place order for ${Totaldiscounted || Total} EGP`
                : localStorage.getItem("orderType") === "Reservation"
                ? "Reserve"
                : `Place order for ${Totaldiscounted || Total} EGP`}
            </button>
          )}
        </div>
      )}
    </Form>
  )
}
export default Placeorder
