import { useState, useEffect } from "react"
import { collection, getDocs, query, orderBy } from "firebase/firestore"
import { db } from "../../Firebase/config"

const UsefetchCollectionNonRealtime = (collection_name) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true) // For loading state
  const [error, setError] = useState(null) // For error state

  const getCollection = async () => {
    try {
      setLoading(true)
      const docRef = collection(db, collection_name)
      const q = query(docRef, orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(q)
      const alldata = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      setData(alldata)
      setLoading(false)
    } catch (err) {
      console.error("Error fetching collection:", err)
      setError("Failed to load data")
      setLoading(false)
    }
  }

  useEffect(() => {
    getCollection()
  }, [])

  return { data, loading, error }
}

export default UsefetchCollectionNonRealtime
