import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  newDineInOrders: 0,
  newPickupOrders: 0,
  newDeliveryOrders: 0,
}

const dineInOrdersSlice = createSlice({
  name: "dineInOrders", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    SET_NEW_DINE_IN_ORDERS(state, action) {
      state.newDineInOrders = action.payload // Set the new Dine-In orders count
    },
    CLEAR_DINE_IN_ORDERS(state) {
      state.newDineInOrders = 0 // Clear the Dine-In orders count
    },
    SET_NEW_DELIVERY_COUNT(state, action) {
      state.newDeliveryOrders = action.payload // Set the new Dine-In orders count
    },
    CLEAR_NEW_DELIVERY_COUNT(state) {
      state.newDeliveryOrders = 0 // Clear the Dine-In orders count
    },
    SET_NEW_PICKUP_ORDERS(state, action) {
      state.newPickupOrders = action.payload // Set the new Pickup orders count
    },
    CLEAR_NEW_PICKUP_ORDERS(state) {
      state.newPickupOrders = 0 // Clear the Pickup orders count
    },
  },
})

// Export the actions
export const {
  SET_NEW_DINE_IN_ORDERS,
  CLEAR_DINE_IN_ORDERS,
  SET_NEW_PICKUP_ORDERS,
  CLEAR_NEW_PICKUP_ORDERS,
  SET_NEW_DELIVERY_COUNT,
  CLEAR_NEW_DELIVERY_COUNT,
} = dineInOrdersSlice.actions

// Selector to get the current number of new Dine-In orders
export const selectNewDineInOrders = (state) =>
  state.dineInOrders.newDineInOrders

export const selectNewPickupOrders = (state) =>
  state.dineInOrders.newPickupOrders

export const selectNewDeliveryOrders = (state) =>
  state.dineInOrders.newDeliveryOrders

// Export the reducer to add to the store
export default dineInOrdersSlice.reducer
