import React from "react"
import { Container } from "react-bootstrap"
import { DineinHomeComponent } from "../../Components/Home/DineInHome"
const HomepageDineIn = () => {
  return (
    <Container fluid>
      <DineinHomeComponent />
    </Container>
  )
}
export default HomepageDineIn
