import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { db } from "../../../Firebase/config"
import {
  collection,
  addDoc,
  Timestamp,
  deleteDoc,
  doc,
} from "firebase/firestore"
import Notify from "../Usenotifications"

export const CouponHook = () => {
  const [name, setName] = useState("")
  const [expire, setExpire] = useState("")
  const [discount, setDiscount] = useState("")
  const [loading, setLoading] = useState("")
  const dispatch = useDispatch()
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await addDoc(collection(db, "Promocodes"), {
        name: name,
        expire: expire,
        discount: Number(discount),
        createdAt: Timestamp.now().toDate(),
      })
      Notify("Promocode added succesfully", "success")
      setName("")
      setDiscount("")
      setExpire("")
    } catch {
      Notify("unable to Add Promocode", "error")
    }
  }
  const handleDelete = async (e, id) => {
    e.preventDefault()
    await deleteDoc(doc(db, "Promocodes", id))
    Notify("Promocode deleted", "success")
  }
  const handleNamechange = (e) => {
    setName(e.target.value)
  }
  const handleExpirechange = (e) => {
    setExpire(e.target.value)
  }
  const handleDisountchange = (e) => {
    setDiscount(e.target.value)
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (loading === false) {
      setName("")
      setDiscount("")
      setExpire("")
      setTimeout(() => {
        window.location.reload(false)
      }, 1000)
    }
    setLoading(true)
  }, [loading, dispatch])
  return [
    name,
    handleNamechange,
    expire,
    handleExpirechange,
    discount,
    handleDisountchange,
    handleSubmit,
    handleDelete,
  ]
}
