import React, { useState } from "react"
import { Menucard } from "./Menucard"
import "../../Styles/Menustyle.css"
import { Link } from "react-router-dom"
import Search from "../images/Search.svg"
import Cart from "../images/Cart_I.svg"
import { motion as m } from "framer-motion"
import { SelectFilteredproducts } from "../../redux/actions/FilterProducts"
import { useSelector } from "react-redux"
import { selectCartitems } from "../../redux/actions/CartSlice"
import UsefetchCollectionNonRealtime from "../Componentslogic/UsefetchcollectionNonrealtime"
export const Menuproducts = () => {
  const filteredProducts = useSelector(SelectFilteredproducts)
  const cartData = useSelector(selectCartitems)

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <m.div
      className="Products-container "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {filteredProducts.length > 0
        ? filteredProducts.map((product, index) => (
            <div key={product.id}>
              <m.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.3 }}
              >
                <Menucard products={product} />
              </m.div>
            </div>
          ))
        : null}

      <m.div
        className="Cart-search"
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: -15 }}
        transition={{ duration: 0.5, delay: 1 }}
      >
        <div className="Cart-searchbg">
          {localStorage.getItem("orderID") === null ? (
            <Link
              to="/Orderreview"
              style={{
                textDecoration: "none",
              }}
            >
              <div className="position-relative">
                <img src={Cart} alt="Cart-icon "></img>

                <span className="red-circle-Cart">
                  <p className="p-0 m-0 text ">
                    <b>{cartData.length}</b>
                  </p>
                </span>
              </div>
            </Link>
          ) : null}

          <img src={Search} alt="Search-icon" onClick={scrollToTop}></img>
        </div>
      </m.div>
    </m.div>
  )
}
