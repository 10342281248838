import React, { useState } from "react"
import "../../Styles/StaffLayout.css"
import { NavLink } from "react-router-dom"
import {
  selectNewDeliveryOrders,
  selectNewDineInOrders,
  selectNewPickupOrders,
} from "../../redux/actions/OrdersStaffslice"
import { useDispatch, useSelector } from "react-redux"

const StaffComponent = () => {
  const [selectedOption, setSelectedOption] = useState("dine-in")
  const activeLink = ({ isActive }) => (isActive ? "selectedItem" : "")
  const newDineInOrders = useSelector(selectNewDineInOrders)
  const newPickupOrders = useSelector(selectNewPickupOrders)
  const newDeliveryOrders = useSelector(selectNewDeliveryOrders)

  return (
    <div className="layout-container">
      <div className="order-options">
        <div></div>
        <NavLink
          to="/StaffPage/DineIn"
          style={{ textDecoration: "none", color: "black" }}
          className={activeLink}
        >
          <p
            className="order-option"
            onClick={() => setSelectedOption("dine-in")}
          >
            DINE-IN
            {newDineInOrders > 0 && (
              <span className="red-circle">{newDineInOrders}</span>
            )}
          </p>
        </NavLink>
        <span className="divider">|</span>
        <NavLink
          to="/StaffPage/PickUp"
          style={{ textDecoration: "none", color: "black" }}
          className={activeLink}
        >
          <p
            className="order-option"
            onClick={() => setSelectedOption("pick-up")}
          >
            PICK-UP
            {newPickupOrders > 0 && (
              <span className="red-circle">{newPickupOrders}</span>
            )}
          </p>
        </NavLink>

        <span className="divider">|</span>

        <NavLink
          to="/StaffPage/Delivery"
          style={{ textDecoration: "none", color: "black" }}
          className={activeLink}
        >
          <p
            className="order-option"
            onClick={() => setSelectedOption("Delivery")}
          >
            Delivery
            {newDeliveryOrders > 0 && (
              <span className="red-circle">{newDeliveryOrders}</span>
            )}
          </p>
        </NavLink>

        <span className="divider">|</span>
        <NavLink
          to="/StaffPage/StaffReservationPage"
          style={{ textDecoration: "none", color: "black" }}
          className={activeLink}
        >
          <p
            className="order-option"
            onClick={() => setSelectedOption("Reservation")}
          >
            Reservations
          </p>
        </NavLink>
      </div>
    </div>
  )
}

export default StaffComponent
