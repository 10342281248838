import React from "react"
import { Container, Col } from "react-bootstrap"
import { Allorders } from "../../Components/Admin/Allorders"
import "../../Styles/Adminreviews.css"
import { SidemenuComponent } from "../../Components/Admin/Sidemenu"
import DriverSignupform from "../../Components/Auth/DriverSignupform"
const Signuppage = () => {
  return (
    <Container fluid className="px-0">
      <Col sm="2" xs="2" className="px-0">
        <SidemenuComponent />
      </Col>
      <Col sm="12" xs="12" className="px-0">
        <DriverSignupform />
      </Col>
    </Container>
  )
}
export default Signuppage
