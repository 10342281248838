import CreatecategoryHook from "../Componentslogic/categoriesLogic/CreatecategoryHook"
import { motion as m } from "framer-motion"
import Spinner from "react-bootstrap/Spinner"
function Addcategory() {
  const [name, , , handlesubmit, inputName, img, onImageChange, loading] =
    CreatecategoryHook()

  return (
    <div className="holder">
      <div className="form-holder-admin">
        <m.div
          className="wrapper"
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <div>
            <label>
              <div className="flip-card__inner">
                <div className="flip-card__front">
                  <div
                    className="title mb-3"
                    initial={{ opacity: 0, x: 15 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, delay: 0.3 }}
                  >
                    Add category
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                    <div className="input-div">
                      {loading ? (
                        <div>
                          {" "}
                          <Spinner animation="grow" variant="primary">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>{" "}
                        </div>
                      ) : (
                        <div className="input-div">
                          {" "}
                          <img className="icon" src={img} alt="add-img" />
                          <input
                            onChange={onImageChange}
                            type="file"
                            id="upload-photo"
                            name="photo"
                            className="input-type"
                          />{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <form
                    className="flip-card__form"
                    action=""
                    initial={{ opacity: 0, x: 15 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, delay: 0.5 }}
                  >
                    <div>
                      <p className="text">
                        <strong> Category name:</strong>
                      </p>
                      <input
                        onChange={inputName}
                        value={name}
                        className="input-Cat "
                        placeholder="Add category name"
                        type="text"
                      />
                    </div>

                    <div
                      className="register"
                      initial={{ opacity: 0, x: 15 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 1, delay: 0.7 }}
                    >
                      <button
                        onClick={(e) => handlesubmit(e)}
                        className="btns-Admin"
                        initial={{ opacity: 0, x: 15 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1, delay: 0.7 }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </label>
          </div>
        </m.div>
      </div>
    </div>
  )
}

export default Addcategory
