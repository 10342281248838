import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { setDoc, Timestamp, doc, getDoc } from "firebase/firestore"
import { db } from "../../../Firebase/config"
import Notify from "../Usenotifications"
const EditproductHook = (id) => {
  const [document, setDocument] = useState(null)
  useEffect(() => {
    const getDocument = async () => {
      const docRef = doc(db, "Products", id)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        const obj = {
          id: id,
          ...docSnap.data(),
        }
        setDocument(obj)
      } else {
        Notify("error", "error")
      }
    }
    getDocument()
  }, [id])

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [priceBefore, setPricebefore] = useState("")
  const [priceAfter, setPriceafter] = useState("")
  const [loading, setloading] = useState("")
  const [catId, setCategoryId] = useState("")
  const [catName, setCategoryName] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    if (document !== null) {
      setName(document.name)
      setDescription(document.description)
      setPricebefore(document.Price)
      setCategoryId(document.CategoryId)
      setCategoryName(document.CategoryName)
    }
  }, [document])
  const OnchangeName = (e) => {
    setName(e.target.value)
  }
  const OnchangeDescription = (e) => {
    setDescription(e.target.value)
  }
  const OnchangeCatid = async (e) => {
    const [catId, catName] = e.target.value.split("|")
    setCategoryName(catName)
    setCategoryId(catId)
  }
  const Onchangepricebefore = (e) => {
    setPricebefore(e.target.value)
  }
  const Onchangepriceafter = (e) => {
    setPriceafter(e.target.value)
  }
  const handleSubmitproduct = async (e) => {
    e.preventDefault()

    await setDoc(doc(db, "Products", id), {
      name: name,
      description: description,
      Price: Number(priceBefore),
      CategoryId: catId,
      CategoryName: catName,
      createdAt: document.createdAt,
      updatedAt: Timestamp.now().toDate(),
    })
    Notify("Updated successfully", "success")

    setName("")
    setDescription("")
    setCategoryId("")
    setCategoryName("")
    setPricebefore("")
    setPriceafter("")
    setloading(false)
  }
  useEffect(() => {
    if (loading === false) {
      setTimeout(() => {
        navigate("/adminmanagement")
      }, 1500)
    }
  })
  return [
    name,
    description,

    priceBefore,
    priceAfter,
    handleSubmitproduct,
    OnchangeName,
    OnchangeDescription,
    OnchangeCatid,
    Onchangepricebefore,
    Onchangepriceafter,
    catId,
    catName,
  ]
}
export default EditproductHook
