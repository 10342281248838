import { doc, getDoc } from "firebase/firestore"
import { useEffect, useState, useCallback } from "react"
import { db } from "../../Firebase/config"

const useFetchDocument = (collectionName, documentID) => {
  const [document, setDocument] = useState(null)
  const getDocument = useCallback(async () => {
    try {
      const docRef = doc(db, collectionName, documentID)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        const obj = {
          id: documentID,
          ...docSnap.data(),
        }
        setDocument(obj)
      } else {
        console.error("No such document exists!")
        setDocument(null)
      }
    } catch (error) {
      console.error("Error fetching document:", error)
    }
  }, [collectionName, documentID])

  useEffect(() => {
    getDocument()
  }, [getDocument])

  return { document }
}

export default useFetchDocument
